// React
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumericFormat } from "react-number-format";

// MUI
import {
    Box,
    TextField,
    Grid,
    Divider,
    Card,
    CardHeader,
    CardContent,
    Button,
    FormControl,
    FormControlLabel,
    Checkbox,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Select,
    MenuItem,
    Switch,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** MUI icons */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InfoIcon from "@mui/icons-material/Info";
import DialogAlerts from "../../../../DialogAlerts";

const PREFIX = "Xero";

const classes = {
    btnRoot: `${PREFIX}-btnRoot`,
    buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.btnRoot}`]: {
        border: "1px solid #ff5968",
        borderRadius: 40,
        "&:hover": {
            backgroundColor: "#2f3190",
            border: "1px solid #2f3190",
        },
    },
    [`& .${classes.buttonContainer}`]: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 15,
    },
}));

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={2}
            valueIsNumericString
        />
    );
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const defaultState = {
    subscrip: false,
    not_billable: false,
    fee: 0,
    projectTotal: 0,
    discount: 0,
    additionalsDiscount: 0,
    version: "",
    sinceDate: null,
    finishDate: null,
    endProject: false,
    // Addons
    checkedExp: false,
    tothExp: 0,
    startDateExp: null,
    finishDateExp: null,
    checkedPro: false,
    tothPro: 0,
    startDatePro: null,
    finishDatePro: null,
    checkedPlus: false,
    startDatePlus: null,
    finishDatePlus: null,
};

export const Xero = (props) => {
    // Properties
    const { setTexto, setalertType, setShow, companyID, setBackDrop, data, reloadProjects, serviceID } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [xero, setXero] = useState(defaultState);
    const [isError, setIsError] = useState(false);
    const [open, setOpen] = useState(false);
    const [infoAdd, setInfoAdd] = useState({});
    const [checkRemind, setCheckRemind] = useState(false);

    // Suma de tarifa de adicionales
    const sumTotal =
        (xero.finishDateExp?.isBefore(moment()) ? 0 : parseFloat(xero.tothExp) * 4) +
        (xero.finishDatePro?.isBefore(moment())
            ? 0
            : parseFloat(xero.tothPro) !== 0
            ? parseFloat(xero.tothPro - 1) * 5 + 7
            : 0) +
        (xero.finishDatePlus?.isBefore(moment()) ? 0 : xero.checkedPlus ? 7 : 0);
    // Suma de tarifa total de adicionales
    const sumTotalAdd =
        ((xero.finishDateExp?.isBefore(moment()) ? 0 : parseFloat(xero.tothExp) * 4) +
            (xero.finishDatePro?.isBefore(moment())
                ? 0
                : parseFloat(xero.tothPro) !== 0
                ? parseFloat(xero.tothPro - 1) * 5 + 7
                : 0) +
            (xero.finishDatePlus?.isBefore(moment()) ? 0 : xero.checkedPlus ? 7 : 0)) *
        (1 - (xero.additionalsDiscount ?? 0) / 100);
    // Suma de tarifa total general
    const sumTotalFinal =
        ((xero.finishDateExp?.isBefore(moment()) ? 0 : parseFloat(xero.tothExp) * 4) +
            (xero.finishDatePro?.isBefore(moment())
                ? 0
                : parseFloat(xero.tothPro) !== 0
                ? parseFloat(xero.tothPro - 1) * 5 + 7
                : 0) +
            (xero.finishDatePlus?.isBefore(moment()) ? 0 : xero.checkedPlus ? 7 : 0)) *
            (1 - xero.additionalsDiscount / 100) +
        xero.fee * (1 - xero.discount / 100);

    const handleCloseDialog = () => {
        setInfoAdd({});
        setCheckRemind(false);
        setOpen(false);
    };

    const showConfirm = (value) => {
        setInfoAdd(value);
        setOpen(true);
    };

    const handleChangeCheck = (event) => {
        setCheckRemind(event.target.checked);
    };

    const removeCheck = () => {
        setXero({
            ...xero,
            [infoAdd.check]: false,
            [infoAdd.toth]: 0,
            [infoAdd.startDate]: null,
            [infoAdd.finishDate]: null,
        });
        if (checkRemind) {
            dispatch({
                type: "addRemind",
                item: true,
            });
        }
        handleCloseDialog();
    };

    const bodyMess = (
        <>
            <Typography variant="h1" color="primary" align="center" sx={{ fontSize: 18, pt: 3, pb: 2 }}>
                {t("miscellaneous.confRemoveCheckDet")}
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        name="checkedConf"
                        checked={checkRemind}
                        onChange={handleChangeCheck}
                        inputProps={{
                            "aria-label": "controlled",
                        }}
                    />
                }
                label={t("miscellaneous.dontShowAgain")}
            />
        </>
    );

    /** Component functions */
    useEffect(() => {
        let ogFee = 0;

        if (data.version === "Ledger") {
            ogFee = 5;
        }
        if (data.version === "Cashbook") {
            ogFee = 13;
        }
        if (data.version === "Growing US") {
            ogFee = 42;
        }
        if (data.version === "Established US") {
            ogFee = 78;
        }
        if (data.version === "Standard GB") {
            ogFee = 46;
        }
        if (data.version === "Premium GB") {
            ogFee = 62;
        }
        if (data.version === "Early") {
            ogFee = 15;
        }

        let expModuleAddData = {};
        let proModuleAddData = {};
        let anaModuleAddData = {};

        if (data.additionals && data.additionals.length !== 0) {
            data.additionals.forEach((element) => {
                if (element.additional_name === "Módulo de Expenses") {
                    expModuleAddData = {
                        since_date: element.since_date,
                        end_date: element.end_date,
                        hours: element.hours,
                    };
                }
                if (element.additional_name === "Módulo de Projects") {
                    proModuleAddData = {
                        since_date: element.since_date,
                        end_date: element.end_date,
                        hours: element.hours,
                    };
                }
                if (element.additional_name === "Analytics Plus") {
                    anaModuleAddData = {
                        since_date: element.since_date,
                        end_date: element.end_date,
                        hours: element.hours,
                    };
                }
            });
        }

        setXero({
            subscrip: data.subscription ?? false,
            not_billable: data.not_billable ?? false,
            fee: ogFee,
            projectTotal: ogFee * (1 - (data.discount ?? 0) / 100),
            discount: data.discount ?? 0,
            additionalsDiscount: data.additional_discount ?? 0,
            version: data.version ?? "",
            sinceDate: data.since_date ? moment(data.since_date).utc() : null,
            finishDate: data.end_date ? moment(data.end_date).utc() : null,
            endProject: data.finished_service ?? false,
            // Addons
            checkedExp: Object.keys(expModuleAddData).length !== 0,
            checkedExpOG: Object.keys(expModuleAddData).length !== 0,
            tothExp: expModuleAddData.hours ?? 0,
            startDateExp: !expModuleAddData.since_date ? null : moment(expModuleAddData.since_date).utc(),
            finishDateExp: !expModuleAddData.end_date ? null : moment(expModuleAddData.end_date).utc(),
            finishDateExpOG: !expModuleAddData.end_date ? null : moment(expModuleAddData.end_date).utc(),
            checkedPro: Object.keys(proModuleAddData).length !== 0,
            checkedProOG: Object.keys(proModuleAddData).length !== 0,
            tothPro: proModuleAddData.hours ?? 0,
            startDatePro: !proModuleAddData.since_date ? null : moment(proModuleAddData.since_date).utc(),
            finishDatePro: !proModuleAddData.end_date ? null : moment(proModuleAddData.end_date).utc(),
            finishDateProOG: !proModuleAddData.end_date ? null : moment(proModuleAddData.end_date).utc(),
            checkedPlus: Object.keys(anaModuleAddData).length !== 0,
            checkedPlusOG: Object.keys(anaModuleAddData).length !== 0,
            startDatePlus: !anaModuleAddData.since_date ? null : moment(anaModuleAddData.since_date).utc(),
            finishDatePlus: !anaModuleAddData.end_date ? null : moment(anaModuleAddData.end_date).utc(),
            finishDatePlusOG: !anaModuleAddData.end_date ? null : moment(anaModuleAddData.end_date).utc(),
        });
    }, [data]);

    const handleChangeDiscount = (event) => {
        let discountAux = 0;

        if (event.target.name === "discount") {
            discountAux = event.target.value >= 0 && event.target.value <= 100 ? event.target.value : xero.discount;

            setXero({
                ...xero,
                discount: discountAux,
                projectTotal: xero.fee * (1 - discountAux / 100),
            });
        } else {
            discountAux =
                event.target.value >= 0 && event.target.value <= 100 ? event.target.value : xero.additionalsDiscount;
            setXero({
                ...xero,
                additionalsDiscount: discountAux,
            });
        }
    };

    const updateProject = async () => {
        setBackDrop(true);
        let requiredData = false;
        let requiredHours = false;
        let additionalsF = [];

        if (xero.sinceDate && xero.version) {
            if (xero.endProject && !xero.finishDate) {
                requiredData = true;
            }

            if (xero.checkedExp) {
                if (xero.endProject && !xero.finishDateExp) {
                    requiredData = true;
                }

                if (xero.startDateExp) {
                    if (xero.tothExp > 0) {
                        additionalsF.push({
                            additional_name: "Módulo de Expenses",
                            hours: parseFloat(xero.tothExp),
                            since_date: moment(xero.startDateExp).startOf("day").format(),
                            end_date: xero.finishDateExp ? moment(xero.finishDateExp).startOf("day").format() : null,
                        });
                    } else {
                        requiredHours = true;
                    }
                } else {
                    requiredData = true;
                }
            }
            if (xero.checkedPro) {
                if (xero.endProject && !xero.finishDatePro) {
                    requiredData = true;
                }

                if (xero.startDatePro) {
                    if (xero.tothPro > 0) {
                        additionalsF.push({
                            additional_name: "Módulo de Projects",
                            hours: parseFloat(xero.tothPro),
                            since_date: moment(xero.startDatePro).startOf("day").format(),
                            end_date: xero.finishDatePro ? moment(xero.finishDatePro).startOf("day").format() : null,
                        });
                    } else {
                        requiredHours = true;
                    }
                } else {
                    requiredData = true;
                }
            }
            if (xero.checkedPlus) {
                if (xero.endProject && !xero.finishDatePlus) {
                    requiredData = true;
                }

                if (xero.startDatePlus) {
                    additionalsF.push({
                        additional_name: "Analytics Plus",
                        hours: 7,
                        since_date: moment(xero.startDatePlus).startOf("day").format(),
                        end_date: xero.finishDatePlus ? moment(xero.finishDatePlus).startOf("day").format() : null,
                    });
                } else {
                    requiredData = true;
                }
            }
        } else {
            requiredData = true;
        }

        if (requiredData || requiredHours) {
            requiredData ? setTexto(t("miscellaneous.requiredData")) : setTexto(t("services.hoursValidationError"));
            setalertType("warning");
            setShow(true);
            setIsError(true);
        } else {
            let params = {
                company_id: companyID,
                service_id: serviceID,
                project_id: data.project_id,
                project_company_id: data.project_company_id,
                subscription: xero.subscrip,
                not_billable: xero.not_billable,
                since_date: moment(xero.sinceDate).startOf("day").format(),
                end_date: xero.finishDate ? moment(xero.finishDate).startOf("day").format() : null,
                discount: xero.discount ? parseFloat(xero.discount) : 0,
                additional_discount: xero.additionalsDiscount ? parseFloat(xero.additionalsDiscount) : 0,
                fee: sumTotalFinal,
                version: xero.version,
                finished_service: xero.endProject,
                additionals: additionalsF,
            };

            await fetch(`/addServicesAndProjects`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(params),
            })
                .then((response) => {
                    if (response.ok) {
                        setTexto(t("miscellaneous.successfulUpdate"));
                        setalertType("success");
                        setShow(true);
                        reloadProjects(true, xero.endProject);
                    } else {
                        setTexto(t("miscellaneous.updatingError"));
                        setalertType("error");
                        setShow(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        setBackDrop(false);
    };

    const handleChangeSinceDate = (date) => {
        setXero({
            ...xero,
            sinceDate: date,
            startDateExp: xero.checkedExp ? date : xero.startDateExp,
            startDatePro: xero.checkedPro ? date : xero.startDatePro,
            startDatePlus: xero.checkedPlus ? date : xero.startDatePlus,
        });
    };

    const handleChangeVersion = (event) => {
        let newFee = 0;
        switch (event.target.value) {
            case "Ledger":
                newFee = 5;
                break;
            case "Cashbook":
                newFee = 13;
                break;
            case "Growing US":
                newFee = 42;
                break;
            case "Established US":
                newFee = 78;
                break;
            case "Standard GB":
                newFee = 46;
                break;
            case "Early":
                newFee = 15;
                break;
            default:
                newFee = 62;
                break;
        }
        setXero({
            ...xero,
            version: event.target.value,
            fee: newFee,
            projectTotal: newFee * (1 - (xero.discount ?? 0) / 100),
            checkedExp: false,
            tothExp: 0,
            startDateExp: null,
            finishDateExp: null,
            checkedPro: false,
            tothPro: 0,
            startDatePro: null,
            finishDatePro: null,
            checkedPlus: false,
            startDatePlus: null,
            finishDatePlus: null,
        });
    };

    // const handleChangeCheck = (event) => {
    //     setXero({
    //         ...xero,
    //         subscrip: event.target.checked,
    //     });
    // };

    const handleChangeCheckAdd = (event, value) => {
        switch (value) {
            case 0:
                if (xero.checkedExpOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedExp",
                        toth: "tothExp",
                        startDate: "startDateExp",
                        finishDate: "finishDateExp",
                    });
                } else {
                    setXero({
                        ...xero,
                        checkedExp: event.target.checked,
                        tothExp: event.target.checked ? 1 : 0,
                        startDateExp: event.target.checked ? xero.sinceDate : null,
                        finishDateExp: event.target.checked ? xero.finishDate : null,
                    });
                }
                break;
            case 1:
                if (xero.checkedProOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedPro",
                        toth: "tothPro",
                        startDate: "startDatePro",
                        finishDate: "finishDatePro",
                    });
                } else {
                    setXero({
                        ...xero,
                        checkedPro: event.target.checked,
                        tothPro: event.target.checked ? 1 : 0,
                        startDatePro: event.target.checked ? xero.sinceDate : null,
                        finishDatePro: event.target.checked ? xero.finishDate : null,
                    });
                }
                break;
            default:
                if (xero.checkedPlusOG && !event.target.checked && !todos.remind) {
                    showConfirm({
                        check: "checkedPlus",
                        toth: "tothPlus",
                        startDate: "startDatePlus",
                        finishDate: "finishDatePlus",
                    });
                } else {
                    setXero({
                        ...xero,
                        checkedPlus: event.target.checked,
                        startDatePlus: event.target.checked ? xero.sinceDate : null,
                        finishDatePlus: event.target.checked ? xero.finishDate : null,
                    });
                }
                break;
        }
    };

    const handleChangeCheckNotBillable = (event) => {
        setXero({
            ...xero,
            not_billable: event.target.checked,
        });
    };

    const handleChange = (event) => {
        setXero({
            ...xero,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeStartDateAdd = (date, val) => {
        // Si es la misma fecha de la Desde, se debe tomar en cuenta ese dia y no al empezar el mes
        let startDate = moment(date).startOf("month");
        let auxSinceDate = xero.sinceDate.clone();
        if (auxSinceDate && auxSinceDate.startOf("month").isSame(startDate)) {
            startDate = xero.sinceDate.clone();
        }

        switch (val) {
            case 0:
                if (!date || !xero.finishDateExp) {
                    setXero({
                        ...xero,
                        startDateExp: startDate ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(xero.finishDateExp))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            startDateExp: startDate,
                        });
                    }
                }
                break;
            case 1:
                if (!date || !xero.finishDatePro) {
                    setXero({
                        ...xero,
                        startDatePro: startDate ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(xero.finishDatePro))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            startDatePro: startDate,
                        });
                    }
                }
                break;
            default:
                if (!date || !xero.finishDatePlus) {
                    setXero({
                        ...xero,
                        startDatePlus: startDate ? startDate : date,
                    });
                } else {
                    if (startDate.isAfter(moment(xero.finishDatePlus))) {
                        setTexto(t("reports.initDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            startDatePlus: startDate,
                        });
                    }
                }
                break;
        }
    };

    const handleChangeEndDateAdd = (date, val) => {
        let endDate = moment(date).endOf("month").startOf("day");
        switch (val) {
            case 0:
                if (!date || !xero.startDateExp) {
                    setXero({
                        ...xero,
                        finishDateExp: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(xero.startDateExp))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            finishDateExp: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            case 1:
                if (!date || !xero.startDatePro) {
                    setXero({
                        ...xero,
                        finishDatePro: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(xero.startDatePro))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            finishDatePro: moment(date).endOf("month"),
                        });
                    }
                }
                break;
            default:
                if (!date || !xero.startDatePlus) {
                    setXero({
                        ...xero,
                        finishDatePlus: date ? moment(date).endOf("month") : date,
                    });
                } else {
                    if (endDate.isBefore(moment(xero.startDatePlus))) {
                        setTexto(t("reports.endDateMess"));
                        setalertType("warning");
                        setShow(true);
                    } else {
                        setXero({
                            ...xero,
                            finishDatePlus: moment(date).endOf("month"),
                        });
                    }
                }
                break;
        }
    };

    const handleChangeSwitch = (event) => {
        if (xero.finishDate) {
            setXero({
                ...xero,
                endProject: event.target.checked,
                finishDateExp: xero.finishDate.isSame(xero.finishDateExp) ? null : xero.finishDateExp,
                finishDatePro: xero.finishDate.isSame(xero.finishDatePro) ? null : xero.finishDatePro,
                finishDatePlus: xero.finishDate.isSame(xero.finishDatePlus) ? null : xero.finishDatePlus,
                finishDate: null,
            });
        } else {
            setXero({
                ...xero,
                endProject: event.target.checked,
            });
        }
    };

    const handleChangeEndDate = (date) => {
        let endDate = moment(date).endOf("month").startOf("day");

        if (!date) {
            setXero({
                ...xero,
                finishDate: date,
            });
        } else if (xero.sinceDate) {
            if (endDate.isBefore(moment(xero.sinceDate))) {
                setXero({
                    ...xero,
                    finishDate: null,
                });
                setTexto(t("reports.endDateMess"));
                setalertType("warning");
                setShow(true);
            } else {
                setXero({
                    ...xero,
                    finishDate: endDate,
                    finishDateExp: xero.checkedExp ? xero.finishDateExpOG ?? endDate : null,
                    finishDatePro: xero.checkedPro ? xero.finishDateProOG ?? endDate : null,
                    finishDatePlus: xero.checkedPlus ? xero.finishDatePlusOG ?? endDate : null,
                });
            }
        } else {
            setTexto(t("reports.selectStartDate"));
            setalertType("warning");
            setShow(true);
        }
    };

    return (
        <StyledContainer>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={t("language.locale")}
                localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
            >
                <Card elevation={0}>
                    <CardHeader
                        title="Xero subscription"
                        action={
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={xero.endProject}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                        color="secondary"
                                        onChange={handleChangeSwitch}
                                    />
                                }
                                label={
                                    <Typography variant="body1" color="textPrimary">
                                        {t("services.finishProject")}
                                    </Typography>
                                }
                            />
                        }
                    />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth required error={isError && xero.version === ""}>
                                    <InputLabel id="ver-simple-select-label">{t("services.version")}</InputLabel>
                                    <Select
                                        name="version"
                                        labelId="ver-simple-select-label"
                                        IconComponent={KeyboardArrowDownIcon}
                                        value={xero.version}
                                        label={t("services.version")}
                                        onChange={handleChangeVersion}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={"Ledger"}>Ledger</MenuItem>
                                        <MenuItem value={"Cashbook"}>Cashbook</MenuItem>
                                        <MenuItem value={"Growing US"}>Growing - US</MenuItem>
                                        <MenuItem value={"Established US"}>Established - US</MenuItem>
                                        <MenuItem value={"Standard GB"}>Standard - Global</MenuItem>
                                        <MenuItem value={"Premium GB"}>Premium - Global</MenuItem>
                                        <MenuItem value={"Early"}>Early</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-fee">{t("services.projectFee")}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-fee"
                                        value={xero.fee}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectFee")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.discount")}
                                    value={xero.discount}
                                    name="discount"
                                    onChange={handleChangeDiscount}
                                    onBlur={() =>
                                        setXero({
                                            ...xero,
                                            discount: parseFloat(xero.discount === "" ? 0 : xero.discount).toFixed(2),
                                        })
                                    }
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100,
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-projectTotal">
                                        {t("services.projectTot")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-projectTotal"
                                        value={xero.projectTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.projectTot")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}></Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-additionalsFee">
                                        {t("services.hourlyRateAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-additionalsFee"
                                        value={sumTotal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("services.hourlyRateAdd")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <TextField
                                    fullWidth
                                    label={t("miscellaneous.discount")}
                                    value={xero.additionalsDiscount}
                                    name="additionalsDiscount"
                                    onChange={handleChangeDiscount}
                                    onBlur={() =>
                                        setXero({
                                            ...xero,
                                            additionalsDiscount: parseFloat(
                                                xero.additionalsDiscount === "" ? 0 : xero.additionalsDiscount
                                            ).toFixed(2),
                                        })
                                    }
                                    variant="outlined"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0,
                                            max: 100,
                                        },
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-additionalsTotal">
                                        {t("clientsTable.totalAdd")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-additionalsTotal"
                                        value={sumTotalAdd}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("clientsTable.totalAdd")}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={9}></Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel htmlFor="outlined-adornment-totalFee">
                                        {t("proposals.feeTotal")}
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-totalFee"
                                        value={sumTotalFinal}
                                        inputComponent={NumberFormatCustom}
                                        startAdornment={
                                            <InputAdornment disableTypography position="start">
                                                $
                                            </InputAdornment>
                                        }
                                        label={t("proposals.feeTotal")}
                                    />
                                </FormControl>
                            </Grid>
                            {/* <Grid item lg={3} sm={6} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="subscrip"
                                            onChange={handleChangeCheck}
                                            checked={xero.subscrip}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label={t("services.integratedSubs")}
                                />
                            </Grid> */}
                            {xero.version === "Ledger" || xero.version === "Cashbook" ? (
                                <Grid item lg={3} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={xero.not_billable}
                                                onChange={handleChangeCheckNotBillable}
                                            />
                                        }
                                        label={t("services.notBillable")}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.serviceResponsibility")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    value={xero.sinceDate}
                                    onChange={(newValue) => handleChangeSinceDate(newValue)}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField fullWidth required {...params} error={isError && !xero.sinceDate} />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month", "day"]}
                                />
                            </Grid>
                            {xero.endProject && (
                                <Grid item lg={3} sm={6} xs={12}>
                                    <DatePicker
                                        componentsProps={{
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        value={xero.finishDate}
                                        onChange={(newValue) => handleChangeEndDate(newValue)}
                                        inputFormat={todos.dateFormat}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                required
                                                {...params}
                                                error={isError && !xero.finishDate}
                                            />
                                        )}
                                        views={["year", "month"]}
                                        inputProps={{ readOnly: true }}
                                        label={t("reports.endDate")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
                <Card elevation={0}>
                    <CardHeader title={t("services.additionals")} sx={{ color: "#ff5968" }} />
                    <Divider />
                    <CardContent>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedExp"
                                            checked={xero.checkedExp}
                                            onChange={(value) => handleChangeCheckAdd(value, 0)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={
                                                (xero.version !== "Standard GB" && xero.version !== "Premium GB") ||
                                                !xero.sinceDate
                                            }
                                        />
                                    }
                                    label="Expenses claims"
                                />
                                <LightTooltip title={t("services.reclaimTool")}>
                                    <InfoIcon
                                        fontSize="small"
                                        color={!xero.sinceDate ? "disabled" : "primary"}
                                        sx={{ position: "relative", top: 6 }}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!xero.checkedExp}
                                    label={t("proposals.users")}
                                    name="tothExp"
                                    onChange={handleChange}
                                    value={xero.tothExp}
                                    variant="outlined"
                                    type="number"
                                    required={xero.checkedExp}
                                    inputProps={{
                                        min: 1,
                                        step: 1,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={xero.checkedExp && isError && xero.tothExp === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!xero.checkedExp}
                                    value={xero.startDateExp}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 0)}
                                    minDate={moment(xero.sinceDate).startOf("month")}
                                    maxDate={moment(xero.finishDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={xero.checkedExp}
                                            {...params}
                                            error={xero.checkedExp && isError && !xero.startDateExp}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!xero.checkedExp}
                                    value={xero.finishDateExp}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 0)}
                                    minDate={moment(xero.sinceDate).startOf("month")}
                                    maxDate={moment(xero.finishDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={xero.checkedExp && xero.endProject}
                                            {...params}
                                            error={xero.checkedExp && isError && !xero.finishDateExp && xero.endProject}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedPro"
                                            checked={xero.checkedPro}
                                            onChange={(value) => handleChangeCheckAdd(value, 1)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={
                                                (xero.version !== "Standard GB" && xero.version !== "Premium GB") ||
                                                !xero.sinceDate
                                            }
                                        />
                                    }
                                    label="Projects"
                                />
                                <LightTooltip title={t("services.workTool")}>
                                    <InfoIcon
                                        fontSize="small"
                                        color={!xero.sinceDate ? "disabled" : "primary"}
                                        sx={{ position: "relative", top: 6 }}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled={!xero.checkedPro}
                                    label={t("proposals.users")}
                                    name="tothPro"
                                    onChange={handleChange}
                                    value={xero.tothPro}
                                    variant="outlined"
                                    type="number"
                                    required={xero.checkedPro}
                                    inputProps={{
                                        min: 1,
                                        step: 1,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={xero.checkedPro && isError && xero.tothPro === 0}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!xero.checkedPro}
                                    value={xero.startDatePro}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 1)}
                                    minDate={moment(xero.sinceDate).startOf("month")}
                                    maxDate={moment(xero.finishDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={xero.checkedPro}
                                            {...params}
                                            error={xero.checkedPro && isError && !xero.startDatePro}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!xero.checkedPro}
                                    value={xero.finishDatePro}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 1)}
                                    minDate={moment(xero.sinceDate).startOf("month")}
                                    maxDate={moment(xero.finishDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={xero.checkedPro && xero.endProject}
                                            {...params}
                                            error={xero.checkedPro && isError && !xero.finishDatePro && xero.endProject}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedPlus"
                                            checked={xero.checkedPlus}
                                            onChange={(value) => handleChangeCheckAdd(value, 2)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                            disabled={
                                                (xero.version !== "Standard GB" && xero.version !== "Premium GB") ||
                                                !xero.sinceDate
                                            }
                                        />
                                    }
                                    label="Analytics Plus"
                                />
                                <LightTooltip title={t("services.customTool")}>
                                    <InfoIcon
                                        fontSize="small"
                                        color={!xero.sinceDate ? "disabled" : "primary"}
                                        sx={{ position: "relative", top: 6 }}
                                    />
                                </LightTooltip>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    disabled
                                    label={t("proposals.users")}
                                    name="tothPlus"
                                    value="N/A"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!xero.checkedPlus}
                                    value={xero.startDatePlus}
                                    onChange={(newValue) => handleChangeStartDateAdd(newValue, 2)}
                                    minDate={moment(xero.sinceDate).startOf("month")}
                                    maxDate={moment(xero.finishDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={xero.checkedPlus}
                                            {...params}
                                            error={xero.checkedPlus && isError && !xero.startDatePlus}
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.initDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    componentsProps={{
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    disabled={!xero.checkedPlus}
                                    value={xero.finishDatePlus}
                                    onChange={(newValue) => handleChangeEndDateAdd(newValue, 2)}
                                    minDate={moment(xero.sinceDate).startOf("month")}
                                    maxDate={moment(xero.finishDate).endOf("month")}
                                    inputFormat={todos.dateFormat}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            required={xero.checkedPlus && xero.endProject}
                                            {...params}
                                            error={
                                                xero.checkedPlus && isError && !xero.finishDatePlus && xero.endProject
                                            }
                                        />
                                    )}
                                    inputProps={{ readOnly: true }}
                                    label={t("reports.endDate")}
                                    views={["year", "month"]}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disableElevation
                            classes={{ root: classes.btnRoot }}
                            onClick={updateProject}
                        >
                            {t("miscellaneous.save")}
                        </Button>
                    </Box>
                </Card>
            </LocalizationProvider>
            <DialogAlerts
                open={open}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("miscellaneous.delete")}
                disagreeBtnLabel="No"
                type={"info"}
                title={t("miscellaneous.confRemoveCheck")}
                message={bodyMess}
                agreeAction={removeCheck}
            />
        </StyledContainer>
    );
};
