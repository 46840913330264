/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

/** Images */

/** MUI icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/** MUI imports */
import { Box, Typography, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { PricingAcc } from "../AccountantProposal";
import { PricingBook } from "../BookkeeperProposal";
import { PricingCont } from "../ControllerProposal";
import { PricingCFO } from "../CFOProposal";
import { PricingSoft } from "../SoftwareProposal";
import { Footer } from "./";

/** Global variables */
const PREFIX = "Pricing";

const classes = {
    body2: `${PREFIX}-body2`,
    serviceName: `${PREFIX}-serviceName`,
    subName: `${PREFIX}-subName`,
    serviceBody: `${PREFIX}-serviceBody`,
    subInfo: `${PREFIX}-subInfo`,
    roundBack: `${PREFIX}-roundBack`,
    roundBack2: `${PREFIX}-roundBack2`,
    roundBack3: `${PREFIX}-roundBack3`,
    months: `${PREFIX}-months`,
    monthsText1: `${PREFIX}-monthsText1`,
    monthsText2: `${PREFIX}-monthsText2`,
    body3: `${PREFIX}-body3`,
    textFoot: `${PREFIX}-textFoot`,
    nameSMB: `${PREFIX}-nameSMB`,
    roundBackMain: `${PREFIX}-roundBackMain`,
    roundBackMain2: `${PREFIX}-roundBackMain2`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.nameSMB}`]: {
        fontSize: 10,
    },

    [`& .${classes.roundBackMain}`]: {
        backgroundColor: "#6D70AD",
        fontSize: 10,
        width: 200,
        paddingLeft: 50,
        paddingRight: 35,
        marginRight: "-25px",
        [theme.breakpoints.down("md")]: {
            width: 150,
            paddingLeft: 30,
            paddingRight: 20,
            marginRight: 15,
        },
    },
    [`& .${classes.roundBackMain2}`]: {
        backgroundColor: "#2D348B",
        fontSize: 10,
        width: 180,
        paddingRight: 25,
        paddingLeft: 60,
        marginRight: 15,
        [theme.breakpoints.down("md")]: {
            width: 150,
        },
    },

    [`& .${classes.months}`]: {
        fontSize: 16,
    },

    [`& .${classes.serviceName}`]: {
        fontSize: 22,
        marginBottom: 10,
    },

    [`& .${classes.subName}`]: {
        fontSize: 12,
        marginBottom: 10,
    },

    [`& .${classes.body2}`]: {
        backgroundColor: "white",
        marginTop: 15,
        padding: 30,
        minHeight: 850,
    },

    [`& .${classes.body3}`]: {
        padding: 30,
    },

    [`& .${classes.textFoot}`]: {
        padding: "15px 30px",
    },

    [`& .${classes.serviceBody}`]: {
        backgroundColor: "#F8F9F8",
        marginTop: 30,
        padding: 30,
        borderRadius: 30,
        overflow: "auto",
    },

    [`& .${classes.subInfo}`]: {
        marginTop: 30,
        fontSize: 12,
    },

    [`& .${classes.roundBack}`]: {
        backgroundColor: "#6D70AD",
        minWidth: "125px",
        marginRight: "-30px",
        justifyContent: "center",
        display: "flex",
        [theme.breakpoints.down("md")]: {
            marginRight: 0,
        },
    },

    [`& .${classes.roundBack2}`]: {
        backgroundColor: "#2D348B",
        minWidth: "130px",
        justifyContent: "center",
        display: "flex",
    },
    [`& .${classes.roundBack3}`]: {
        color: "#031851",
        minWidth: "115px",
        marginRight: "-25px",
        justifyContent: "center",
        display: "flex",
    },

    [`& .${classes.monthsText1}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
    },
    [`& .${classes.monthsText2}`]: {
        fontSize: 16,
        justifyContent: "center",
        display: "flex",
        marginRight: "-27px",
        [theme.breakpoints.down("md")]: {
            marginRight: 0,
        },
    },
}));

const CustomBox = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    color: "#fff",
    height: 35,
}));

const CustomBox2 = styled(Box)(({ theme }) => ({
    borderRadius: "24px",
    color: "#fff",
    height: 16,
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
}));

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
    backgroundColor: "#F8F9F8",
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    padding: 0,
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 16px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

const defaultState = {
    totalAmount: 0,
    totalMonth: 0,
    isMonthly: false,
    isBook: false,
    isCont: false,
    isSubs: false,
    expenses: 0,
};

export const Pricing = (props) => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const { proposalDetails, mode, tariff, tariffAdditionals, preferentialFee } = props;

    // console.log(proposalDetails[0].prospect.company_legal_name)
    const [info, setInfo] = useState([]);
    const [servicesDetails, setServicesDetails] = useState(defaultState);
    const [localTariff, setLocalTariff] = useState(0);
    const [localTariffAdd, setLocalTariffAdd] = useState(0);
    const [localPreferentialFee, setLocalPreferentialFee] = useState(false);

    useEffect(() => {
        let values = [];
        if (mode === "preview") {
            values = proposalDetails;

            setLocalTariff(tariff);
            setLocalTariffAdd(tariffAdditionals);
            setLocalPreferentialFee(preferentialFee);

            let totalServices = 0;
            let isMonthly = false;
            let isBook = false;
            let isCont = false;
            let isSubs = false;
            let addOnly = false;
            let totalMonth = 0;
            let expenses = 0;

            proposalDetails.forEach((item) => {
                expenses = item.expenses;
                totalServices += item.totalAll;

                if (item.name === "Accountant" || item.name === "Controller" || item.name === "CFO") {
                    if (item.recurring.isActive && !item.recurring.payAnnually) {
                        isMonthly = true;
                        totalMonth +=
                            item.recurring.total +
                            item.recurring.recuHours * item.recurring.addFee * (1 - item.recurring.discount / 100);
                    }
                    if (item.name === "Accountant") {
                        let keys = Object.keys(item);
                        keys.forEach((key) => {
                            if (item[key]?.additionals?.length > 0) {
                                item[key].additionals.forEach((add) => {
                                    if (add.additional_name === "Accountant") {
                                        addOnly = true;
                                    }
                                });
                            }
                        });
                    }
                }
                if (item.name === "Controller" || item.name === "CFO") {
                    isCont = true;
                }
                if (item.name === "Bookkeeper") {
                    isBook = true;
                    if (item.recurring.isActive) {
                        isMonthly = true;
                        totalMonth +=
                            item.recurring.total +
                            item.recurring.recuHours * item.recurring.addFee * (1 - item.recurring.discount / 100);
                    }
                }
                if (item.name === "Software subscription") {
                    isSubs = true;
                    isMonthly = true;
                    totalMonth += item.totalAll;
                }

                if (item.name === "Software setup") {
                    isSubs = true;
                }
            });

            setServicesDetails({
                totalAmount: totalServices,
                totalMonth: totalMonth,
                isMonthly: isMonthly,
                isBook: isBook,
                isCont: isCont,
                isSubs: isSubs,
                expenses: expenses,
                addOnly: addOnly,
            });
        } else {
            // Recorrer arreglo de servicios y crear nueva estructura
            let newObj = {
                name: "",
                onboarding: {
                    isActive: false,
                },
                cleanUp: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                },
                recurring: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
                totalAll: 0,
                expenses: proposalDetails.expenses,
            };
            let newObjCont = {
                name: "",
                onboarding: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                },
                recurring: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
                totalAll: 0,
                expenses: proposalDetails.expenses,
            };
            let newObjCFO = {
                name: "",
                onboarding: {
                    isActive: false,
                },
                catchUp: {
                    isActive: false,
                },
                recurring: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
                totalAll: 0,
                expenses: proposalDetails.expenses,
            };
            // Recorrer arreglo de servicios y crear nueva estructura
            let newObjSetup = {
                name: "",
                xero: {
                    isActive: false,
                },
                banking: {
                    isActive: false,
                },
                taxes: {
                    isActive: false,
                },
                reporting: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
                other: {
                    isActive: false,
                },
                support: {
                    isActive: false,
                },
                totalAll: 0,
                expenses: proposalDetails.expenses,
            };
            let newObjSubs = {
                name: "",
                xero: {
                    isActive: false,
                },
                banking: {
                    isActive: false,
                },
                taxes: {
                    isActive: false,
                },
                reporting: {
                    isActive: false,
                },
                consulting: {
                    isActive: false,
                },
                other: {
                    isActive: false,
                },
                support: {
                    isActive: false,
                },
                totalAll: 0,
                expenses: proposalDetails.expenses,
            };

            // Sumar totales de servicios
            let totalServices = 0;
            let isMonthly = false;
            let totalMonth = 0;
            let isBook = false;
            let isCont = false;
            let isSubs = false;
            let addOnly = false;

            proposalDetails.services.forEach((item) => {
                switch (item.service_name) {
                    case "Accountant":
                        newObj.name = "Accountant";
                        newObj.prospect = proposalDetails?.prospect;
                        
                        let consultingArray = [];
                        let accHours = 0;

                        item.projects.forEach((value) => {
                            let addAux = [];
                            let contHours = 0;
                            let qtyMonths = 0;

                            if (
                                value.project_name === "Accountant cleanup" ||
                                value.project_name === "Accountant catchup"
                            ) {
                                qtyMonths = Math.ceil(
                                    moment(value.end_date, "YYYY-MM-DD").diff(
                                        moment(value.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }

                            value.additionals.forEach((addItem) => {
                                addAux.push({
                                    additional_name: addItem.additional_name,
                                    other_name: addItem.other_name,
                                    hours: addItem.hours,
                                });
                                if (addItem.additional_name === "Accountant") {
                                    accHours = addItem.hours;
                                    addOnly = true;
                                } else {
                                    contHours += addItem.hours;
                                }
                            });

                            if (value.project_name === "Accountant onboarding") {
                                newObj.onboarding.isActive = true;
                                newObj.onboarding.discount = value.discount;
                                newObj.onboarding.fee = value.monthly_Fee;
                                newObj.totalAll += value.monthly_Fee * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Accountant cleanup") {
                                newObj.cleanUp.isActive = true;
                                newObj.cleanUp.additionals = addAux;
                                newObj.cleanUp.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObj.cleanUp.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObj.cleanUp.untilDate = moment(value.end_date, "YYYY-MM-DD");
                                newObj.cleanUp.fee = value.additionals_only ? value.additional_fee : value.monthly_Fee;
                                newObj.cleanUp.discount = value.discount;
                                newObj.cleanUp.addFee = value.additional_fee;
                                newObj.cleanUp.totalHours = contHours;
                                newObj.cleanUp.monthQty = value.additionals_only
                                    ? value.fixed_hours
                                        ? accHours
                                        : accHours * qtyMonths
                                    : qtyMonths;
                                newObj.totalAll += value.additionals_only
                                    ? value.fixed_hours
                                        ? (value.additional_fee * accHours + contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                        : (value.additional_fee * accHours * qtyMonths +
                                              contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                    : (value.monthly_Fee * qtyMonths + contHours * value.additional_fee) *
                                      (1 - value.discount / 100);
                            }
                            if (value.project_name === "Accountant catchup") {
                                newObj.catchUp.isActive = true;
                                newObj.catchUp.additionals = addAux;
                                newObj.catchUp.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObj.catchUp.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObj.catchUp.untilDate = moment(value.end_date, "YYYY-MM-DD");
                                newObj.catchUp.fee = value.additionals_only ? value.additional_fee : value.monthly_Fee;
                                newObj.catchUp.discount = value.discount;
                                newObj.catchUp.addFee = value.additional_fee;
                                newObj.catchUp.totalHours = contHours;
                                newObj.catchUp.monthQty = value.additionals_only
                                    ? value.fixed_hours
                                        ? accHours
                                        : accHours * qtyMonths
                                    : qtyMonths;
                                newObj.totalAll += value.additionals_only
                                    ? value.fixed_hours
                                        ? (value.additional_fee * accHours + contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                        : (value.additional_fee * accHours * qtyMonths +
                                              contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                    : (value.monthly_Fee * qtyMonths + contHours * value.additional_fee) *
                                      (1 - value.discount / 100);
                            }
                            if (value.project_name === "Accountant recurring") {
                                let total =
                                    value.payment_type === 1
                                        ? (value.monthly_Fee * 12 + contHours * value.additional_fee) * 0.85
                                        : value.additionals_only
                                        ? (value.additional_fee * accHours + contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                        : (value.monthly_Fee + contHours * value.additional_fee) *
                                          (1 - value.discount / 100);
                                isMonthly = value.payment_type !== 1;

                                newObj.recurring.isActive = true;
                                newObj.recurring.payAnnually = value.payment_type === 1;
                                newObj.recurring.fee = value.additionals_only
                                    ? value.additional_fee
                                    : value.monthly_Fee;
                                newObj.recurring.qty = value.additionals_only ? accHours : 1;
                                newObj.recurring.total =
                                    value.payment_type === 1
                                        ? value.monthly_Fee * 12 * 0.85
                                        : value.additionals_only
                                        ? value.additional_fee * accHours * (1 - value.discount / 100)
                                        : value.monthly_Fee * (1 - value.discount / 100);
                                newObj.recurring.addFee = value.additional_fee;
                                newObj.recurring.discount = value.discount;
                                newObj.recurring.additionals = addAux;
                                newObj.recurring.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObj.recurring.recuHours = contHours;
                                newObj.recurring.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObj.totalAll += total;
                                totalMonth += total;
                            }
                            if (value.project_name === "Accountant consulting") {
                                newObj.consulting.isActive = true;
                                newObj.consulting.fee = value.monthly_Fee;
                                var consulting_hours = value.consulting_hours ?? 0;
                                consultingArray.push({
                                    hours: consulting_hours,
                                    discount: value.discount,
                                    description: value.description,
                                });
                                newObj.totalAll += value.monthly_Fee * consulting_hours * (1 - value.discount / 100);
                            }
                        });
                        newObj.consulting.array = consultingArray;
                        totalServices += newObj.totalAll;
                        values.push(newObj);
                        break;
                    case "Bookkeeper":
                        isBook = true;
                        setLocalTariff(item.tariff);
                        setLocalTariffAdd(item.tariff_additionals);
                        setLocalPreferentialFee(item.preferential_fee);

                        const hasToolBox =
                            proposalDetails?.include_toolbox === undefined || proposalDetails?.include_toolbox === null
                                ? true
                                : proposalDetails?.include_toolbox;
                        const hasOnboarding =
                            proposalDetails?.include_onboarding === undefined ||
                            proposalDetails?.include_onboarding === null
                                ? true
                                : proposalDetails?.include_onboarding;

                        newObj.name = "Bookkeeper";
                        newObj.prospect = proposalDetails?.prospect;

                        let consultingArrayBook = [];
                        let bookHours = 0;

                        item.projects.forEach((value) => {
                            let addAux = [];
                            let contHours = 0;
                            let qtyMonths = 0;

                            if (
                                value.project_name === "Bookkeeper cleanup" ||
                                value.project_name === "Bookkeeper catchup"
                            ) {
                                qtyMonths = Math.ceil(
                                    moment(value.end_date, "YYYY-MM-DD").diff(
                                        moment(value.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }
                            value.additionals.forEach((addItem) => {
                                addAux.push({
                                    additional_name: addItem.additional_name,
                                    other_name: addItem.other_name,
                                    hours: addItem.hours,
                                });
                                if (addItem.additional_name === "Bookkeeper") {
                                    bookHours = addItem.hours;
                                } else {
                                    contHours += addItem.hours;
                                }
                            });

                            if (value.project_name === "SMBs' onboarding") {
                                newObj.onboarding.isActive = true;
                                newObj.onboarding.discount = value.discount;
                                newObj.onboarding.fee = Math.ceil(proposalDetails.expenses / 10000) * item.tariff;
                                newObj.totalAll +=
                                    Math.ceil(proposalDetails.expenses / 10000) *
                                    item.tariff *
                                    (1 - value.discount / 100);
                            }
                            if (value.project_name === "Bookkeeper cleanup") {
                                newObj.cleanUp.isActive = true;
                                newObj.cleanUp.additionals = addAux;
                                newObj.cleanUp.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObj.cleanUp.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObj.cleanUp.untilDate = moment(value.end_date, "YYYY-MM-DD");
                                newObj.cleanUp.fee = value.additionals_only
                                    ? item.tariff_additionals
                                    : Math.ceil(proposalDetails.expenses / 10000) * item.tariff;
                                newObj.cleanUp.discount = value.discount;
                                newObj.cleanUp.addFee = item.tariff_additionals;
                                newObj.cleanUp.totalHours = contHours;
                                newObj.cleanUp.monthQty = value.additionals_only
                                    ? value.fixed_hours
                                        ? bookHours
                                        : bookHours * qtyMonths
                                    : qtyMonths;
                                newObj.totalAll += value.additionals_only
                                    ? value.fixed_hours
                                        ? (item.tariff_additionals * bookHours + contHours * item.tariff_additionals) *
                                          (1 - value.discount / 100)
                                        : (item.tariff_additionals * bookHours * qtyMonths +
                                              contHours * item.tariff_additionals) *
                                          (1 - value.discount / 100)
                                    : (Math.ceil(proposalDetails.expenses / 10000) * item.tariff * qtyMonths +
                                          contHours * item.tariff_additionals) *
                                      (1 - value.discount / 100);
                            }
                            if (value.project_name === "Bookkeeper catchup") {
                                newObj.catchUp.isActive = true;
                                newObj.catchUp.additionals = addAux;
                                newObj.catchUp.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObj.catchUp.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObj.catchUp.untilDate = moment(value.end_date, "YYYY-MM-DD");
                                newObj.catchUp.fee = value.additionals_only
                                    ? item.tariff_additionals
                                    : Math.ceil(proposalDetails.expenses / 10000) * item.tariff;
                                newObj.catchUp.discount = value.discount;
                                newObj.catchUp.addFee = item.tariff_additionals;
                                newObj.catchUp.totalHours = contHours;
                                newObj.catchUp.monthQty = value.additionals_only
                                    ? value.fixed_hours
                                        ? bookHours
                                        : bookHours * qtyMonths
                                    : qtyMonths;
                                newObj.totalAll += value.additionals_only
                                    ? value.fixed_hours
                                        ? (item.tariff_additionals * bookHours + contHours * item.tariff_additionals) *
                                          (1 - value.discount / 100)
                                        : (item.tariff_additionals * bookHours * qtyMonths +
                                              contHours * item.tariff_additionals) *
                                          (1 - value.discount / 100)
                                    : (Math.ceil(proposalDetails.expenses / 10000) * item.tariff * qtyMonths +
                                          contHours * item.tariff_additionals) *
                                      (1 - value.discount / 100);
                            }
                            if (value.project_name === "Bookkeeper recurring") {
                                newObj.recurring.isActive = true;
                                newObj.recurring.fee = value.additionals_only
                                    ? item.tariff_additionals
                                    : Math.ceil(proposalDetails.expenses / 10000) * item.tariff;
                                newObj.recurring.qty = value.additionals_only ? bookHours : 1;
                                newObj.recurring.total = value.additionals_only
                                    ? item.tariff_additionals * bookHours * (1 - value.discount / 100)
                                    : Math.ceil(proposalDetails.expenses / 10000) *
                                      item.tariff *
                                      (1 - value.discount / 100);
                                newObj.recurring.addFee = item.tariff_additionals;
                                newObj.recurring.discount = value.discount;
                                newObj.recurring.additionals = addAux;
                                newObj.recurring.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObj.recurring.addOnly = value.additionals_only;
                                newObj.recurring.recuHours = contHours;
                                newObj.recurring.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObj.totalAll += value.additionals_only
                                    ? (item.tariff_additionals * bookHours + contHours * item.tariff_additionals) *
                                      (1 - value.discount / 100)
                                    : (Math.ceil(proposalDetails.expenses / 10000) * item.tariff +
                                          contHours * item.tariff_additionals) *
                                      (1 - value.discount / 100);
                                isMonthly = true;
                                totalMonth += value.additionals_only
                                    ? (item.tariff_additionals * bookHours + contHours * item.tariff_additionals) *
                                      (1 - value.discount / 100)
                                    : (Math.ceil(proposalDetails.expenses / 10000) * item.tariff +
                                          contHours * item.tariff_additionals) *
                                      (1 - value.discount / 100);
                            }
                            if (value.project_name === "Bookkeeper consulting") {
                                newObj.consulting.isActive = true;
                                newObj.consulting.fee = value.monthly_Fee;
                                var consulting_hours = value.consulting_hours ?? 0;
                                consultingArrayBook.push({
                                    hours: consulting_hours,
                                    discount: value.discount,
                                    description: value.description,
                                });
                                newObj.totalAll += value.monthly_Fee * consulting_hours * (1 - value.discount / 100);
                            }
                        });
                        newObj.consulting.array = consultingArrayBook;
                        newObj.totalAll +=
                            (hasToolBox
                                ? proposalDetails[0]?.prospect.company_legal_name === "Nava School of Business" ||
                                  proposalDetails?.prospect?.company_legal_name === "Nava School of Business"
                                    ? 199
                                    : 99
                                : 0) + (hasOnboarding ? 499 : 0);
                        totalServices += newObj.totalAll;
                        values.push(newObj);
                        break;
                    case "Controller":
                        isCont = true;
                        newObjCont.name = "Controller";
                        let consultingArrayCont = [];
                        let addHours = 0;

                        item.projects.forEach((value) => {
                            let addAux = [];
                            let contHours = 0;
                            let qtyMonths = 0;

                            if (value.project_name === "Controller catchup") {
                                qtyMonths = Math.ceil(
                                    moment(value.end_date, "YYYY-MM-DD").diff(
                                        moment(value.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }
                            value.additionals.forEach((addItem) => {
                                addAux.push({
                                    additional_name: addItem.additional_name,
                                    other_name: addItem.other_name,
                                    hours: addItem.hours,
                                });
                                if (addItem.additional_name === "Controller") {
                                    addHours = addItem.hours;
                                } else {
                                    contHours += addItem.hours;
                                }
                            });

                            if (value.project_name === "Controller onboarding") {
                                newObjCont.onboarding.isActive = true;
                                newObjCont.onboarding.discount = value.discount;
                                newObjCont.onboarding.fee = value.monthly_Fee;
                                newObjCont.totalAll += value.monthly_Fee * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Controller catchup") {
                                newObjCont.catchUp.isActive = true;
                                newObjCont.catchUp.additionals = addAux;
                                newObjCont.catchUp.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObjCont.catchUp.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObjCont.catchUp.untilDate = moment(value.end_date, "YYYY-MM-DD");
                                newObjCont.catchUp.fee = value.additionals_only
                                    ? value.additional_fee
                                    : value.monthly_Fee;
                                newObjCont.catchUp.discount = value.discount;
                                newObjCont.catchUp.addFee = value.additional_fee;
                                newObjCont.catchUp.totalHours = contHours;
                                newObjCont.catchUp.monthQty = value.additionals_only ? addHours : qtyMonths;
                                newObjCont.totalAll += value.additionals_only
                                    ? (value.additional_fee * addHours + contHours * value.additional_fee) *
                                      (1 - value.discount / 100)
                                    : (value.monthly_Fee * qtyMonths + contHours * value.additional_fee) *
                                      (1 - value.discount / 100);
                            }
                            if (value.project_name === "Controller recurring") {
                                let total =
                                    value.payment_type === 1
                                        ? (value.monthly_Fee * 12 + contHours * value.additional_fee) * 0.85
                                        : value.additionals_only
                                        ? (value.additional_fee * addHours + contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                        : (value.monthly_Fee + contHours * value.additional_fee) *
                                          (1 - value.discount / 100);

                                isMonthly = value.payment_type !== 1;
                                newObjCont.recurring.isActive = true;
                                newObjCont.recurring.payAnnually = value.payment_type === 1;
                                newObjCont.recurring.fee = value.additionals_only
                                    ? value.additional_fee
                                    : value.monthly_Fee;
                                newObjCont.recurring.qty = value.additionals_only ? addHours : 1;
                                newObjCont.recurring.total =
                                    value.payment_type === 1
                                        ? value.monthly_Fee * 12 * 0.85
                                        : value.additionals_only
                                        ? value.additional_fee * addHours * (1 - value.discount / 100)
                                        : value.monthly_Fee * (1 - value.discount / 100);
                                newObjCont.recurring.addFee = value.additional_fee;
                                newObjCont.recurring.discount = value.discount;
                                newObjCont.recurring.additionals = addAux;
                                newObjCont.recurring.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObjCont.recurring.recuHours = contHours;
                                newObjCont.recurring.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObjCont.totalAll +=
                                    value.payment_type === 1
                                        ? (value.monthly_Fee * 12 + contHours * value.additional_fee) * 0.85
                                        : value.additionals_only
                                        ? (value.additional_fee * addHours + contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                        : (value.monthly_Fee + contHours * value.additional_fee) *
                                          (1 - value.discount / 100);

                                totalMonth += total;
                            }
                            if (value.project_name === "Controller consulting") {
                                newObjCont.consulting.isActive = true;
                                newObjCont.consulting.fee = value.monthly_Fee;
                                var consulting_hours = value.consulting_hours ?? 0;
                                consultingArrayCont.push({
                                    hours: consulting_hours,
                                    discount: value.discount,
                                    description: value.description,
                                });
                                newObjCont.totalAll +=
                                    value.monthly_Fee * consulting_hours * (1 - value.discount / 100);
                            }
                        });
                        newObjCont.consulting.array = consultingArrayCont;
                        totalServices += newObjCont.totalAll;
                        values.push(newObjCont);
                        break;
                    case "CFO":
                        isCont = true;
                        newObjCFO.name = "CFO";
                        let consultingArrayCFO = [];
                        let cfoHours = 0;

                        item.projects.forEach((value) => {
                            let addAux = [];
                            let contHours = 0;
                            let qtyMonths = 0;

                            if (value.project_name === "CFO catchup") {
                                qtyMonths = Math.ceil(
                                    moment(value.end_date, "YYYY-MM-DD").diff(
                                        moment(value.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }
                            value.additionals.forEach((addItem) => {
                                addAux.push({
                                    additional_name: addItem.additional_name,
                                    other_name: addItem.other_name,
                                    hours: addItem.hours,
                                });
                                if (addItem.additional_name === "CFO") {
                                    cfoHours = addItem.hours;
                                } else {
                                    contHours += addItem.hours;
                                }
                            });

                            if (value.project_name === "CFO onboarding") {
                                newObjCFO.onboarding.isActive = true;
                                newObjCFO.onboarding.discount = value.discount;
                                newObjCFO.onboarding.fee = value.monthly_Fee;
                                newObjCFO.totalAll += value.monthly_Fee * (1 - value.discount / 100);
                            }
                            if (value.project_name === "CFO catchup") {
                                newObjCFO.catchUp.isActive = true;
                                newObjCFO.catchUp.additionals = addAux;
                                newObjCFO.catchUp.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObjCFO.catchUp.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObjCFO.catchUp.untilDate = moment(value.end_date, "YYYY-MM-DD");
                                newObjCFO.catchUp.fee = value.additionals_only
                                    ? value.additional_fee
                                    : value.monthly_Fee;
                                newObjCFO.catchUp.discount = value.discount;
                                newObjCFO.catchUp.addFee = value.additional_fee;
                                newObjCFO.catchUp.totalHours = contHours;
                                newObjCFO.catchUp.monthQty = value.additionals_only ? cfoHours : qtyMonths;
                                newObjCFO.totalAll += value.additionals_only
                                    ? (value.additional_fee * cfoHours + contHours * value.additional_fee) *
                                      (1 - value.discount / 100)
                                    : (value.monthly_Fee * qtyMonths + contHours * value.additional_fee) *
                                      (1 - value.discount / 100);
                            }
                            if (value.project_name === "CFO recurring") {
                                let total =
                                    value.payment_type === 1
                                        ? (value.monthly_Fee * 12 + contHours * value.additional_fee) * 0.85
                                        : value.additionals_only
                                        ? (value.additional_fee * cfoHours + contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                        : (value.monthly_Fee + contHours * value.additional_fee) *
                                          (1 - value.discount / 100);

                                isMonthly = value.payment_type !== 1;
                                newObjCFO.recurring.isActive = true;
                                newObjCFO.recurring.payAnnually = value.payment_type === 1;
                                newObjCFO.recurring.fee = value.additionals_only
                                    ? value.additional_fee
                                    : value.monthly_Fee;
                                newObjCFO.recurring.qty = value.additionals_only ? cfoHours : 1;
                                newObjCFO.recurring.total =
                                    value.payment_type === 1
                                        ? value.monthly_Fee * 12 * 0.85
                                        : value.additionals_only
                                        ? value.additional_fee * cfoHours * (1 - value.discount / 100)
                                        : value.monthly_Fee * (1 - value.discount / 100);
                                newObjCFO.recurring.addFee = value.additional_fee;
                                newObjCFO.recurring.discount = value.discount;
                                newObjCFO.recurring.additionals = addAux;
                                newObjCFO.recurring.onlyAdd = addAux.length === 1 && value.additionals_only;
                                newObjCFO.recurring.recuHours = contHours;
                                newObjCFO.recurring.initDate = moment(value.since_date, "YYYY-MM-DD");
                                newObjCFO.totalAll +=
                                    value.payment_type === 1
                                        ? (value.monthly_Fee * 12 + contHours * value.additional_fee) * 0.85
                                        : value.additionals_only
                                        ? (value.additional_fee * cfoHours + contHours * value.additional_fee) *
                                          (1 - value.discount / 100)
                                        : (value.monthly_Fee + contHours * value.additional_fee) *
                                          (1 - value.discount / 100);

                                totalMonth += total;
                            }
                            if (value.project_name === "CFO consulting") {
                                newObjCFO.consulting.isActive = true;
                                newObjCFO.consulting.fee = value.monthly_Fee;
                                var consulting_hours = value.consulting_hours ?? 0;
                                consultingArrayCFO.push({
                                    hours: consulting_hours,
                                    discount: value.discount,
                                    description: value.description,
                                });
                                newObjCFO.totalAll += value.monthly_Fee * consulting_hours * (1 - value.discount / 100);
                            }
                        });
                        newObjCFO.consulting.array = consultingArrayCFO;
                        totalServices += newObjCFO.totalAll;
                        values.push(newObjCFO);
                        break;
                    case "Software subscription":
                        isMonthly = true;
                        isSubs = true;
                        newObjSubs.name = item.service_name;

                        item.projects.forEach((value) => {
                            let addAux = [];
                            let contHours = 0;
                            let totalFee = 0;

                            if (value.project_name === "Xero subscription") {
                                value.additionals.forEach((addItem) => {
                                    if (addItem.additional_name === "Módulo de Expenses") {
                                        totalFee += addItem.hours * 4;
                                    } else if (addItem.additional_name === "Módulo de Projects") {
                                        totalFee += (addItem.hours - 1) * 5 + 7;
                                    } else if (addItem.additional_name === "Analytics Plus") {
                                        totalFee += 7;
                                    }
                                    addAux.push({
                                        additional_name: addItem.additional_name,
                                        hours: addItem.hours,
                                    });
                                    contHours += addItem.hours;
                                });

                                let newFee = 0;
                                switch (value.version) {
                                    case "Ledger":
                                        newFee = 5;
                                        break;
                                    case "Cashbook":
                                        newFee = 13;
                                        break;
                                    case "Growing US":
                                        newFee = 42;
                                        break;
                                    case "Established US":
                                        newFee = 78;
                                        break;
                                    case "Standard GB":
                                        newFee = 46;
                                        break;
                                    case "Early":
                                        newFee = 15;
                                        break;
                                    default:
                                        newFee = 62;
                                        break;
                                }

                                newObjSubs.xero.isActive = true;
                                newObjSubs.xero.discount = value.discount;
                                newObjSubs.xero.fee = newFee;
                                newObjSubs.xero.totalUsers = contHours;
                                newObjSubs.xero.additionals = addAux;
                                newObjSubs.xero.total = (newFee + totalFee) * (1 - value.discount / 100);
                                newObjSubs.xero.totalAdds = totalFee * (1 - value.discount / 100);
                                newObjSubs.xero.totalAddNoDiscount = totalFee;
                                newObjSubs.totalAll += (newFee + totalFee) * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Kiiper Banking subscription") {
                                let newFeeBanking = 0;

                                switch (value.version) {
                                    case "Plan 5":
                                        newFeeBanking = 7.75;
                                        break;
                                    case "Plan 15":
                                        newFeeBanking = 19.75;
                                        break;
                                    case "Plan 30":
                                        newFeeBanking = 35.75;
                                        break;
                                    case "Plan 50":
                                        newFeeBanking = 55.75;
                                        break;
                                    case "Plan 200":
                                        newFeeBanking = 189.75;
                                        break;
                                    case "Plan 500":
                                        newFeeBanking = 425.75;
                                        break;
                                    default:
                                        break;
                                }

                                newObjSubs.banking.isActive = true;
                                newObjSubs.banking.discount = value.discount;
                                newObjSubs.banking.fee = newFeeBanking;
                                newObjSubs.banking.total = newFeeBanking * (1 - value.discount / 100);
                                newObjSubs.totalAll += newFeeBanking * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Kiiper Taxes subscription") {
                                let newFeeTax = 0;

                                switch (value.version) {
                                    case "Basic":
                                        newFeeTax = 20;
                                        break;
                                    default:
                                        newFeeTax = 35;
                                        break;
                                }

                                newObjSubs.taxes.isActive = true;
                                newObjSubs.taxes.discount = value.discount;
                                newObjSubs.taxes.fee = newFeeTax;
                                newObjSubs.taxes.total = newFeeTax * (1 - value.discount / 100);
                                newObjSubs.totalAll += newFeeTax * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Kiiper Reporting subscription") {
                                newObjSubs.reporting.isActive = true;
                                newObjSubs.reporting.discount = value.discount;
                                newObjSubs.reporting.fee = 10;
                                newObjSubs.reporting.total = 10 * (1 - value.discount / 100);
                                newObjSubs.totalAll += 10 * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Customer support") {
                                let feeSupport = proposalDetails.reference.includes("VE")
                                    ? 25
                                    : proposalDetails.reference.includes("LA")
                                    ? 30
                                    : 35;

                                newObjSubs.support.isActive = true;
                                newObjSubs.support.discount = value.discount;
                                newObjSubs.support.fee = feeSupport;
                                newObjSubs.support.hours = value.consulting_hours;
                                newObjSubs.support.total =
                                    feeSupport * value.consulting_hours * (1 - value.discount / 100);
                                newObjSubs.totalAll += feeSupport * value.consulting_hours * (1 - value.discount / 100);
                            }
                        });
                        totalMonth += newObjSubs.totalAll;
                        totalServices += newObjSubs.totalAll;
                        values.push(newObjSubs);
                        break;
                    case "Software setup":
                        newObjSetup.name = item.service_name;
                        isSubs = true;
                        let consultingArraySetup = [];

                        let fee = proposalDetails.reference.includes("VE")
                            ? 36
                            : proposalDetails.reference.includes("LA")
                            ? 42
                            : 60;

                        item.projects.forEach((value) => {
                            if (value.project_name === "Xero setup") {
                                newObjSetup.xero.isActive = true;
                                newObjSetup.xero.fee = fee;
                                newObjSetup.xero.package = value.package;
                                newObjSetup.xero.hours = value.consulting_hours;
                                newObjSetup.xero.discount = value.discount;
                                newObjSetup.xero.additionals = [];
                                newObjSetup.xero.total = fee * value.consulting_hours * (1 - value.discount / 100);
                                newObjSetup.totalAll += fee * value.consulting_hours * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Kiiper Banking setup") {
                                newObjSetup.banking.isActive = true;
                                newObjSetup.banking.fee = fee;
                                newObjSetup.banking.package = value.package;
                                newObjSetup.banking.hours = value.consulting_hours;
                                newObjSetup.banking.discount = value.discount;
                                newObjSetup.banking.total = fee * value.consulting_hours * (1 - value.discount / 100);
                                newObjSetup.totalAll += fee * value.consulting_hours * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Kiiper Taxes setup") {
                                newObjSetup.taxes.isActive = true;
                                newObjSetup.taxes.fee = fee;
                                newObjSetup.taxes.package = value.package;
                                newObjSetup.taxes.hours = value.consulting_hours;
                                newObjSetup.taxes.discount = value.discount;
                                newObjSetup.taxes.total = fee * value.consulting_hours * (1 - value.discount / 100);
                                newObjSetup.totalAll += fee * value.consulting_hours * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Kiiper Reporting setup") {
                                newObjSetup.reporting.isActive = true;
                                newObjSetup.reporting.fee = fee;
                                newObjSetup.reporting.package = value.package;
                                newObjSetup.reporting.hours = value.consulting_hours;
                                newObjSetup.reporting.discount = value.discount;
                                newObjSetup.reporting.total = fee * value.consulting_hours * (1 - value.discount / 100);
                                newObjSetup.totalAll += fee * value.consulting_hours * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Xero consulting") {
                                newObjSetup.consulting.isActive = true;
                                newObjSetup.consulting.fee = fee;
                                var consulting_hours = value.consulting_hours ?? 0;
                                consultingArraySetup.push({
                                    hours: consulting_hours,
                                    discount: value.discount,
                                    description: value.description,
                                });
                                newObjSetup.totalAll += fee * consulting_hours * (1 - value.discount / 100);
                            }
                            if (value.project_name === "Other setup") {
                                newObjSetup.other.isActive = true;
                                newObjSetup.other.discount = value.discount;
                                newObjSetup.other.fee = fee;
                                newObjSetup.other.hours = value.consulting_hours;
                                newObjSetup.other.total = fee * value.consulting_hours * (1 - value.discount / 100);
                                newObjSetup.totalAll += fee * value.consulting_hours * (1 - value.discount / 100);
                                newObjSetup.other.software = value.version;
                            }
                        });
                        newObjSetup.consulting.array = consultingArraySetup;
                        totalServices += newObjSetup.totalAll;
                        values.push(newObjSetup);
                        break;
                    default:
                        break;
                }
            });

            setServicesDetails({
                totalAmount: totalServices,
                totalMonth: totalMonth,
                isMonthly: isMonthly,
                isBook: isBook,
                isCont: isCont,
                isSubs: isSubs,
                expenses: proposalDetails.expenses,
                addOnly: addOnly,
            });
        }

        setInfo(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, proposalDetails]);

    return (
        <StyledContainer>
            <Box className={classes.body2}>
                <Box className={classes.body3}>
                    {servicesDetails.isBook ? (
                        <>
                            <Typography variant="h5" className={classes.serviceName}>
                                {t("proposals.feesB")}
                            </Typography>
                            <Typography variant="h5" className={classes.subName}>
                                {t("proposals.feesSMBB")}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="h5" className={classes.serviceName}>
                                {servicesDetails.isSubs ? t("proposals.proFees") : t("proposals.pricing")}
                            </Typography>
                            {servicesDetails.expenses > 0 && (
                                <Typography variant="h5" className={classes.subName}>
                                    {!servicesDetails.addOnly
                                        ? `${t("miscellaneous.feeCalcCont")}${parseFloat(
                                              servicesDetails.expenses
                                          ).toLocaleString(todos.amountFormat, {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 2,
                                          })}/${t("clientsTable.month")}.`
                                        : (proposalDetails[0]?.prospect.prospect_id === "66bcba001a7779f7a5c17170" ||
                                        proposalDetails?.prospect?.prospect_id === "66bcba001a7779f7a5c17170" ? t("proposals.pricingSub2") : t("proposals.pricingSub"))}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
                {servicesDetails.isBook && (
                    <Grid container justifyContent="center" spacing={2} sx={{ width: "auto", ml: 0, pb: 4 }}>
                        <Grid item xs={12} md={1}>
                            <Grid container alignItems="center" spacing={2} sx={{ pt: "2px" }}>
                                <Grid item xs={12} sx={{ pl: "1px !important" }}>
                                    <Typography variant="h5" className={classes.nameSMB}>
                                        {t("miscellaneous.levelB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ pb: 4, pl: "1px !important" }}>
                                    <Typography variant="h5" className={classes.nameSMB}>
                                        {t("proposals.freqB")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ pb: 1, borderBottom: "2px solid #031851" }}>
                                    <Typography variant="h5" className={classes.nameSMB}>
                                        No.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.nameSMB}>
                                        1-25
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.nameSMB}>
                                        26-50
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.nameSMB}>
                                        51-100
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.nameSMB}>
                                        101-200
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.nameSMB}>
                                        +200
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {(props.includeOnboarding || props.includeToolbox) && (
                            <>
                                <Grid item xs={12} md={3} sx={{ backgroundColor: "#EDEDED", borderRadius: 10 }}>
                                    <Grid container alignItems="center" spacing={2} sx={{ pb: 2 }}>
                                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                                            <Typography variant="h5" sx={{ fontSize: 12 }}>
                                                {t("proposals.practiceB")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ pb: 4 }}>
                                            <Grid
                                                container
                                                justifyContent={
                                                    props.includeOnboarding && props.includeToolbox
                                                        ? "space-between"
                                                        : "center"
                                                }
                                                sx={{ pr: 2 }}
                                            >
                                                {props.includeOnboarding && (
                                                    <Grid item>
                                                        <Typography variant="h5" className={classes.nameSMB}>
                                                            {t("proposals.oneTime2B")}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.includeToolbox && (
                                                    <Grid item>
                                                        <Typography variant="h5" className={classes.nameSMB}>
                                                            {t("miscellaneous.monthlyB")}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{ pb: 1, borderBottom: "2px solid #031851" }}>
                                            <Grid
                                                container
                                                justifyContent={
                                                    props.includeOnboarding && props.includeToolbox
                                                        ? "space-between"
                                                        : "center"
                                                }
                                                sx={{ pr: 2 }}
                                            >
                                                {props.includeOnboarding && (
                                                    <Grid item>
                                                        <Typography variant="h5" className={classes.nameSMB}>
                                                            {t("proposals.onboardB")}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.includeToolbox && (
                                                    <Grid item>
                                                        <Typography variant="h5" className={classes.nameSMB}>
                                                            Toolbox
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {props.includeToolbox && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        textAlign:
                                                            props.includeOnboarding && props.includeToolbox
                                                                ? "right"
                                                                : "center",
                                                        pr: 2,
                                                    }}
                                                >
                                                    <Typography variant="body1" className={classes.nameSMB}>
                                                        $99
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        textAlign:
                                                            props.includeOnboarding && props.includeToolbox
                                                                ? "right"
                                                                : "center",
                                                        pr: 2,
                                                    }}
                                                >
                                                    <Typography variant="body1" className={classes.nameSMB}>
                                                        $199
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                justifyContent={
                                                    props.includeOnboarding && props.includeToolbox
                                                        ? "space-between"
                                                        : "center"
                                                }
                                                sx={{ pr: 2 }}
                                            >
                                                {props.includeOnboarding && (
                                                    <Grid item>
                                                        <Typography variant="body1" className={classes.nameSMB}>
                                                            $499
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.includeToolbox && (
                                                    <Grid item>
                                                        <Typography variant="body1" className={classes.nameSMB}>
                                                            $399
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        {props.includeToolbox && (
                                            <>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        textAlign:
                                                            props.includeOnboarding && props.includeToolbox
                                                                ? "right"
                                                                : "center",
                                                        pr: 2,
                                                    }}
                                                >
                                                    <Typography variant="body1" className={classes.nameSMB}>
                                                        $749
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        textAlign:
                                                            props.includeOnboarding && props.includeToolbox
                                                                ? "right"
                                                                : "center",
                                                        pr: 2,
                                                    }}
                                                >
                                                    <Typography variant="body1" className={classes.nameSMB}>
                                                        $999
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={12} sx={{ ml: "13px", mt: { xs: "15px", md: "74px" } }}>
                                            <Typography variant="h5">+</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ borderBottom: "2px solid #031851" }}></Grid>
                                        <Grid item xs={12} sx={{ pl: "8px !important" }}>
                                            <Typography variant="body1" className={classes.nameSMB}>
                                                {t("proposals.sumFeesDepB")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} md={7} sx={{ backgroundColor: "#EDEDED", borderRadius: 10 }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: "center" }}>
                                    <Typography variant="h5" sx={{ fontSize: 12 }}>
                                        SMBs
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography variant="h5" className={classes.nameSMB}>
                                                {t("proposals.oneTime2B")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={10} sx={{ pl: 8, textAlign: "center" }}>
                                            <Typography variant="h5" className={classes.nameSMB}>
                                                {t("miscellaneous.monthlyB")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <CustomBox2 className={classes.roundBackMain}>
                                                <Typography variant="span">{t("miscellaneous.fullServB")}*</Typography>
                                            </CustomBox2>
                                        </Grid>
                                        <Grid item>
                                            <CustomBox2 className={classes.roundBackMain2}>
                                                <Typography variant="span">Back-office**</Typography>
                                            </CustomBox2>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ pb: 1, borderBottom: "2px solid #031851" }}>
                                    <Grid container justifyContent="space-between" sx={{ pr: 2 }}>
                                        <Grid item>
                                            <Typography variant="h5" className={classes.nameSMB}>
                                                Onboarding
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" className={classes.nameSMB}>
                                                {t("proposals.coreServB")}***
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" className={classes.nameSMB}>
                                                {t("proposals.addonsB")}****
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" className={classes.nameSMB}>
                                                {t("proposals.coreServB")}***
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" className={classes.nameSMB}>
                                                {t("proposals.addonsB")}****
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ pr: 2, height: { md: 140, xs: 300 } }}
                                    >
                                        <Grid item xs={12} md={2}>
                                            <Typography variant="body1" className={classes.nameSMB}>
                                                {t("proposals.firstMonthCoreB")}
                                            </Typography>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem></Divider>
                                        <Grid item xs={12} md={4}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                {localPreferentialFee ? (
                                                    <>
                                                        <Grid item>
                                                            <Typography variant="h5">$ 17</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h5">+</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body1" className={classes.nameSMB}>
                                                                ${localTariffAdd} {t("proposals.perHourB")}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item xs={3}>
                                                            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 30"
                                                                            : "$ 25"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 28"
                                                                            : "$ 23"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 27"
                                                                            : "$ 22"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 23"
                                                                            : "$ 18"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 22"
                                                                            : "$ 17"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h5">+</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="h5">${localTariffAdd}</Typography>
                                                            <Typography variant="body1" className={classes.nameSMB}>
                                                                {t("proposals.perHourB")}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Divider orientation="vertical" flexItem></Divider>
                                        <Grid item xs={12} md={4}>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                {localPreferentialFee ? (
                                                    <>
                                                        <Grid item>
                                                            <Typography variant="h5">$ {localTariff}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h5">+</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body1" className={classes.nameSMB}>
                                                                ${localTariffAdd} {t("proposals.perHourB")}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item xs={3}>
                                                            <Grid container spacing={2} sx={{ textAlign: "center" }}>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 20"
                                                                            : "$ 15"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 19"
                                                                            : "$ 14"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 18"
                                                                            : "$ 13"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 16"
                                                                            : "$ 11"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        className={classes.nameSMB}
                                                                    >
                                                                        {proposalDetails[0]?.prospect
                                                                            .company_legal_name ===
                                                                            "Nava School of Business" ||
                                                                        proposalDetails?.prospect
                                                                            ?.company_legal_name ===
                                                                            "Nava School of Business"
                                                                            ? "$ 15"
                                                                            : "$ 10"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h5">+</Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="h5">${localTariffAdd}</Typography>
                                                            <Typography variant="body1" className={classes.nameSMB}>
                                                                {t("proposals.perHourB")}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.nameSMB}>
                                * {t("miscellaneous.fullServDetB")}
                            </Typography>
                            <Typography variant="body1" className={classes.nameSMB}>
                                ** {t("miscellaneous.backOfficeDetB")}
                            </Typography>
                            <Typography variant="body1" className={classes.nameSMB}>
                                *** {t("proposals.everyAverageB")}
                            </Typography>
                            <Typography variant="body1" className={classes.nameSMB}>
                                **** {t("proposals.addOnFeesB")}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {servicesDetails.isBook && (
                    <Grid container alignItems="center" sx={{ pt: 2, pl: 4 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.subName}>
                                {proposalDetails[0]?.prospect.company_legal_name === "Nava School of Business" ||
                                proposalDetails?.prospect?.company_legal_name === "Nava School of Business"
                                    ? // t("proposals.practice2SMBB") +
                                      //     ` $${(servicesDetails.expenses / 1000).toLocaleString(todos.amountFormat)}k ` +
                                      //     t("proposals.averageDetB") + ""
                                      "Fees example based on 1 Small or Medium-sized Business (SMB) under the Back-office model, with an average of $" +
                                      (servicesDetails.expenses / 1000).toLocaleString(todos.amountFormat) +
                                      "k in monthly cash-outs, for a practice with 26 to 50 SMBs with recurring service."
                                    : t("proposals.practice2SMBB") +
                                      ` $${(servicesDetails.expenses / 1000).toLocaleString(todos.amountFormat)}k ` +
                                      t("proposals.averageDetB")}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid container alignItems="center" justifyContent="flex-end">
                    <Grid item>
                        <Grid item className={classes.months}>
                            <Typography variant="body1">&nbsp;</Typography>
                        </Grid>
                        <CustomBox className={classes.roundBack3}>
                            <Typography variant="h5" sx={{ display: "flex", alignItems: "center" }}>
                                Total
                            </Typography>
                        </CustomBox>
                    </Grid>
                    <Grid item>
                        <Grid item className={classes.monthsText2}>
                            <Typography variant="body1">
                                {t(servicesDetails.isBook ? "proposals.firstMonthB" : "proposals.firstMonth") +
                                    (servicesDetails.isBook ? "" : "*")}
                            </Typography>
                        </Grid>
                        <CustomBox className={classes.roundBack}>
                            <Typography variant="span" sx={{ display: "flex", alignItems: "center" }}>
                                ${" "}
                                {proposalDetails[0]?.prospect.company_legal_name === "Nava School of Business" ||
                                proposalDetails?.prospect?.company_legal_name === "Nava School of Business"
                                    ? parseFloat(888).toLocaleString(todos.amountFormat, {
                                          maximumFractionDigits: 2,
                                      })
                                    : (proposalDetails[0]?.prospect.prospect_id === "66bcba001a7779f7a5c17170" ||
                                        proposalDetails?.prospect?.prospect_id === "66bcba001a7779f7a5c17170"
                                        ? parseFloat(4500).toLocaleString(todos.amountFormat, {
                                            maximumFractionDigits: 2,
                                        })
                                        :                                        
                                        parseFloat(servicesDetails.totalAmount).toLocaleString(todos.amountFormat, {
                                          maximumFractionDigits: 2,
                                      }))}
                            </Typography>
                            {servicesDetails.isMonthly && (
                                <Typography variant="span" sx={{ position: "relative", top: "20%" }}>
                                    &nbsp;&nbsp;
                                </Typography>
                            )}
                            &nbsp;&nbsp;
                        </CustomBox>
                    </Grid>

                    {servicesDetails.isBook && servicesDetails.isMonthly ? (
                        <Grid item>
                            <Grid item className={classes.monthsText1}>
                                <Typography variant="body1">{t("miscellaneous.monthlyB")}</Typography>
                            </Grid>
                            <CustomBox className={classes.roundBack2}>
                                <Typography variant="span" sx={{ display: "flex", alignItems: "center" }}>
                                    ${" "}
                                    {proposalDetails[0]?.prospect.company_legal_name === "Nava School of Business" ||
                                    proposalDetails?.prospect?.company_legal_name === "Nava School of Business"
                                        ? parseFloat(294).toLocaleString(todos.amountFormat, {
                                              maximumFractionDigits: 2,
                                          })
                                        : (
                                              parseFloat(servicesDetails.totalMonth) +
                                              (props.includeToolbox
                                                  ? proposalDetails[0]?.prospect.company_legal_name ===
                                                        "Nava School of Business" ||
                                                    proposalDetails?.prospect?.company_legal_name ===
                                                        "Nava School of Business"
                                                      ? 199
                                                      : 99
                                                  : 0)
                                          ).toLocaleString(todos.amountFormat, {
                                              maximumFractionDigits: 2,
                                          })}
                                </Typography>
                            </CustomBox>
                        </Grid>
                    ) : servicesDetails.isMonthly ? (
                        <Grid item>
                            <Grid item className={classes.monthsText1}>
                                <Typography variant="body1">
                                    {servicesDetails.isCont
                                        ? t("miscellaneous.monthly")
                                        : t("proposals.secondMonth") + "+"}
                                </Typography>
                            </Grid>
                            <CustomBox className={classes.roundBack2}>
                                <Typography variant="span" sx={{ display: "flex", alignItems: "center" }}>
                                    ${" "}
                                    {parseFloat(servicesDetails.totalMonth).toLocaleString(todos.amountFormat, {
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </CustomBox>
                        </Grid>
                    ) : (
                        <div style={{ paddingRight: 30 }}></div>
                    )}
                </Grid>
                <Box className={classes.serviceBody}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2} sx={{ width: 780 }}>
                        <Grid item xs={3}>
                            <Typography variant="h4">
                                {servicesDetails.isBook ? t("proposals.bookDetB") : t("proposals.serviceDet")}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "center" }}>
                            <Typography variant="h4">
                                {servicesDetails.isBook ? t("proposals.freqB") : t("proposals.feeFreq")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="h4">
                                {t(servicesDetails.isBook ? "proposals.qtyB" : "proposals.qty")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                            <Typography variant="h4">
                                {servicesDetails.isBook ? t("proposals.feePricingBookB") : t("proposals.price")}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="h4">Subtotal (US{servicesDetails.isBook ? "$" : "D"})</Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                            <Typography variant="h4">
                                {t(servicesDetails.isBook ? "proposals.discountB" : "proposals.discount")}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                            <Typography variant="h4">Total (US{servicesDetails.isBook ? "$" : "D"})</Typography>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{
                            mt: 1,
                            backgroundColor: "#031851",
                            height: 2,
                            width: 780,
                        }}
                    />
                    {servicesDetails.isBook && (props.includeOnboarding || props.includeToolbox) && (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                                width: 780,
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item xs={5}>
                                                <Typography variant="h4">Practice</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0 15px" }}>
                                {props.includeOnboarding && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                                    >
                                        <Grid item xs={3}>
                                            <Typography variant="h4">Onboarding</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                                {t("proposals.oneTime2B")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">1</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(499).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(499).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">0%</Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                $
                                                {parseFloat(499).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {props.includeOnboarding && props.includeToolbox && (
                                    <Divider
                                        sx={{
                                            backgroundColor: "#031851",
                                        }}
                                    />
                                )}
                                {props.includeToolbox && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="space-between"
                                        spacing={2}
                                        sx={{ paddingBottom: 1.5, paddingTop: 1.5 }}
                                    >
                                        <Grid item xs={3}>
                                            <Typography variant="h4">Toolbox</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1" sx={{ textAlign: "center" }}>
                                                {t("miscellaneous.monthlyB")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">1</Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                {"$" +
                                                    (proposalDetails[0]?.prospect.company_legal_name ===
                                                        "Nava School of Business" ||
                                                    proposalDetails?.prospect?.company_legal_name ===
                                                        "Nava School of Business"
                                                        ? parseFloat(199).toLocaleString(todos.amountFormat, {
                                                              maximumFractionDigits: 2,
                                                          })
                                                        : parseFloat(99).toLocaleString(todos.amountFormat, {
                                                              maximumFractionDigits: 2,
                                                          }))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                {"$" +
                                                    (proposalDetails[0]?.prospect.company_legal_name ===
                                                        "Nava School of Business" ||
                                                    proposalDetails?.prospect?.company_legal_name ===
                                                        "Nava School of Business"
                                                        ? parseFloat(199).toLocaleString(todos.amountFormat, {
                                                              maximumFractionDigits: 2,
                                                          })
                                                        : parseFloat(99).toLocaleString(todos.amountFormat, {
                                                              maximumFractionDigits: 2,
                                                          }))}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sx={{ textAlign: "center" }}>
                                            <Typography variant="body1">0%</Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{ textAlign: "right" }}>
                                            <Typography variant="body1">
                                                {"$" +
                                                    (proposalDetails[0]?.prospect.company_legal_name ===
                                                        "Nava School of Business" ||
                                                    proposalDetails?.prospect?.company_legal_name ===
                                                        "Nava School of Business"
                                                        ? parseFloat(199).toLocaleString(todos.amountFormat, {
                                                              maximumFractionDigits: 2,
                                                          })
                                                        : parseFloat(99).toLocaleString(todos.amountFormat, {
                                                              maximumFractionDigits: 2,
                                                          }))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )}
                    {info.map((item) => (
                        <Accordion
                            defaultExpanded
                            TransitionProps={{ unmountOnExit: true }}
                            sx={{
                                borderBottom: "3px solid #ff5968",
                                width: 780,
                            }}
                        >
                            <AccordionSummary aria-controls="panelAuxbh-content" id="panelAuxbh-header">
                                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Grid item xs={6}>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item xs={5}>
                                                <Typography variant="h4">
                                                    {servicesDetails.isBook ? "SMB" : item.name}
                                                </Typography>
                                            </Grid>
                                            {item.name !== "Bookkeeper" && (
                                                <Grid item xs={7}>
                                                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                                                        {item.name === "Software setup"
                                                            ? t(
                                                                  servicesDetails.isBook
                                                                      ? `proposals.oneTime2B`
                                                                      : `proposals.oneTime2`
                                                              )
                                                            : item.name === "Software subscription"
                                                            ? t(
                                                                  servicesDetails.isBook
                                                                      ? "miscellaneous.monthlyB"
                                                                      : "miscellaneous.monthly"
                                                              )
                                                            : t(
                                                                  servicesDetails.isBook
                                                                      ? "proposals.oneTimeMontB"
                                                                      : "proposals.oneTimeMont"
                                                              )}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {item.name !== "Bookkeeper" && (
                                        <Grid item>
                                            <Typography variant="h4">
                                                $
                                                {parseFloat(item.totalAll).toLocaleString(todos.amountFormat, {
                                                    maximumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: "0 15px" }}>
                                {item.name === "Accountant" && <PricingAcc pricingAcc={item} />}
                                {item.name === "Bookkeeper" && (
                                    <PricingBook pricingBook={item} localTariff={localTariff} />
                                )}
                                {item.name === "Controller" && <PricingCont pricingCont={item} />}
                                {item.name === "CFO" && <PricingCFO pricingCFO={item} />}
                                {item.name.includes("Software") && (
                                    <PricingSoft pricingSoft={item} isBook={servicesDetails.isBook} />
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
                {(info.length > 1 || (info.length === 1 && !servicesDetails.isSubs && !servicesDetails.isBook)) && (
                    <Box className={classes.textFoot}>
                        <Typography variant="body1" className={classes.subInfo}>
                            {servicesDetails.isBook ? t("proposals.TariffOneTimeB") : t("proposals.TariffOneTime")}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Footer />
        </StyledContainer>
    );
};
