/** Imports ---------------------------------------------------------------- */
import introJs from "intro.js";
import "intro.js/introjs.css";

/** React imports */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import moment from "moment";

/** MUI icons */
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CommentIcon from "@mui/icons-material/Comment";
import HelpIcon from "@mui/icons-material/Help";
import AddCircleIcon from "@mui/icons-material/AddCircle";

/** MUI */
import { styled } from "@mui/material/styles";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Slider,
    Stack,
    TextField,
    Typography,
    FormControlLabel,
    Switch,
    Badge,
    ButtonGroup,
    Grow,
    ClickAwayListener,
    MenuList,
    Popper,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

/** Components imports */
import Backdrop from "../Backdrop";
import SnackBar from "../SnackBar";
import ModAddProspect from "./ModAddProspect";
import ModProspectDirectory from "./ModProspectDirectory";
import {
    AccountantSummary,
    BookkeeperSummary,
    ControllerSummary,
    CFOSummary,
    SetupSummary,
    SubsSummary,
    PropAccountantDetails,
    PropBookkeeperDetails,
    PropControllerDetails,
    PropCFODetails,
    PropSetupDetails,
    PropSubsDetails,
} from "./Services";
import ProposalPreview from "./ProposalPreview";
import CommDialProp from "../CommentDialProposal";
import Dialog from "../Dialog";
/** Component styles */
const PREFIX = "CreateProposal";

const classes = {
    cardContentRoot: `${PREFIX}-cardContentRoot`,
    summaryLabels: `${PREFIX}-summaryLabels`,
    summaryDetails: `${PREFIX}-summaryDetails`,
    switch: `${PREFIX}-switch`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.cardContentRoot}`]: {
        padding: "30px !important",
    },

    [`& .${classes.summaryLabels}`]: {
        display: "flex",
        justifyContent: "space-between",
        padding: "24px 0px 8px",
        borderBottom: "1px solid #cccc",
    },

    [`& .${classes.summaryDetails}`]: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "16px",
    },

    [`& .${classes.switch}`]: {
        "& .MuiSwitch-switchBase .MuiSwitch-thumb": {
            color: "#FFF",
        },
        "& .MuiSwitch-switchBase + .MuiSwitch-track": {
            backgroundColor: "#031851",
        },
        "& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb": {
            color: "#FF5968",
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#FFA8B1",
        },
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)({
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#031851" }} />} {...props} />
))({
    "& .MuiAccordionSummary-content": {
        margin: "12px 0",
    },
});

const AccordionDetails = styled(MuiAccordionDetails)({
    padding: "0px 0px 20px 0px",
    "& .MuiCard-root": {
        boxShadow: "none",
    },
});

const marks = [
    {
        value: 0,
        label: "$0K",
    },
    {
        value: 14,
        label: "$5K",
    },
    {
        value: 28,
        label: "$10K",
    },
    {
        value: 42,
        label: "$25K",
    },
    {
        value: 56,
        label: "$50K",
    },
    {
        value: 70,
        label: "$75K",
    },
    {
        value: 84,
        label: "$100K",
    },
    {
        value: 100,
        label: "+$100K",
    },
];

const marksBook = [
    {
        value: 0,
        label: "$0K",
    },
    {
        value: 9,
        label: "$10K",
    },
    {
        value: 18,
        label: "$20K",
    },
    {
        value: 27,
        label: "$30K",
    },
    {
        value: 36,
        label: "$40K",
    },
    {
        value: 45,
        label: "$50K",
    },
    {
        value: 54,
        label: "$60K",
    },
    {
        value: 63,
        label: "$70K",
    },
    {
        value: 72,
        label: "$80K",
    },
    {
        value: 81,
        label: "$90K",
    },
    {
        value: 90,
        label: "$100K",
    },
    {
        value: 100,
        label: "+$100K",
    },
];

const latam = [
    "Argentina",
    "Bolivia",
    "Brasil",
    "Chile",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Ecuador",
    "El Salvador",
    "Guatemala",
    "Haití",
    "Honduras",
    "México",
    "Nicaragua",
    "Panamá",
    "Paraguay",
    "Perú",
    "República Dominicana",
    "Uruguay",
];

const filter = createFilterOptions();

const PrettoSlider = styled(Slider)({
    color: "#d9dce5",
    height: 10,
    "& .MuiSlider-thumb": {
        height: 24,
        width: 24,
        backgroundColor: "#031851",
        border: "2px solid #031851",
        "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "inherit",
        },
    },
    "& .MuiSlider-markLabel": {
        fontSize: 16,
        fontWeight: 700,
        color: "#d9dce5",
        top: 50,
    },
    "& .MuiSlider-markLabelActive": {
        fontSize: 16,
        fontWeight: 700,
        color: "#031851",
        top: 50,
    },
    "& .MuiSlider-track": {
        border: "none",
    },
    "& .MuiSlider-mark": {
        display: "none",
    },
    "&.Mui-disabled": {
        "& .MuiSlider-thumb": {
            backgroundColor: "#CCCCCC",
            border: "none",
        },
        "& .MuiSlider-markLabelActive": {
            color: "#d9dce5",
        },
    },
});

const NumberFormatCustom = (props) => {
    const todos = useSelector((state) => state.todos);

    const { id, inputRef, value, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value}
            getInputRef={inputRef}
            onValueChange={({ value: v }) => onChange({ target: { value: v } })}
            thousandSeparator={todos.amountFormat === "de-DE" ? "." : ","}
            decimalSeparator={todos.amountFormat === "de-DE" ? "," : "."}
            fixedDecimalScale={true}
            decimalScale={id !== "outlined-adornment-monthly-avg" ? 2 : 0}
            valueIsNumericString
        />
    );
};

const defaultStateServices = [
    {
        name: "accountant",
        isActive: false,
        onboarding: false,
    },
    {
        name: "bookkeeper",
        isActive: false,
        onboarding: false,
    },
    {
        name: "cfo",
        isActive: false,
        onboarding: false,
    },
    {
        name: "controller",
        isActive: false,
        onboarding: false,
    },
    {
        name: "softSetup",
        isActive: false,
    },
    {
        name: "softSubs",
        isActive: false,
    },
    // {
    //     name: "taxExpert",
    //     isActive: false,
    // },
    // {
    //     name: "compilation",
    //     isActive: false,
    //     onboarding: false,
    // },
    // {
    //     name: "review",
    //     isActive: false,
    //     onboarding: false,
    // },
];

const defaultRecuVal = {
    chip: false,
    recuOriginalFee: 0,
    recuDiscount: 0,
    recuProjectTotal: 0,
    payAnnually: false,
    recuAdditionalsOnly: false,
    additionals: [],
    isError: false,
};

const defaultOnboardVal = {
    chip: false,
    onboardOriginalFee: 0,
    onboardDiscount: 0,
    onboardProjectTotal: 0,
    isError: false,
};

const defaultCleanUpVal = {
    chip: false,
    cleanUpOriginalFee: 0,
    discount: 20,
    projectTotal: 0,
    months: 1,
    additionalsOnly: false,
    isError: false,
    fixedHours: false,
};

const defaultCatchUpVal = {
    chip: false,
    catchUpOriginalFee: 0,
    discount: 10,
    projectTotal: 0,
    months: 1,
    additionalsOnly: false,
    additionals: [],
    isError: false,
    fixedHours: false,
};

const defaultConsultVal = {
    chip: false,
    consulOriginalFee: 0,
    consultingArray: [
        {
            hours: 0,
            discount: 0,
            description: "",
            total: 0,
        },
    ],
    isError: false,
};

const defaultSetUpVal = {
    chip: false,
    originalFee: 0,
    hours: 1,
    discount: 0,
    package: "",
    version: "",
    projectTotal: 0,
    isCustom: true,
    isError: false,
    commitmentDateProposal: null,
};

const defaultSubsVal = {
    chip: false,
    originalFee: 0,
    discount: 0,
    version: "",
    hours: 1,
    projectTotal: 0,
    isError: false,
    sinceDateProposal: null,
};

const genAddDefault = {
    ar: false,
    ap: false,
    accrual: false,
    classes: false,
    multi: false,
    person: false,
    checks: false,
    sales: false,
    ordinary: false,
    special: false,
    muni: false,
    other: false,
};
const genAddContDefault = {
    exe: false,
    cons: false,
    rep: false,
    other: false,
};
const genAddCFODefault = {
    gob: false,
    pres: false,
    repInv: false,
    other: false,
};

const CreateProposal = () => {
    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** React router */
    const navigate = useNavigate();

    /** React redux */
    const todos = useSelector((state) => state.todos);
    const dispatch = useDispatch();

    /** Component states */
    const [showBackdrop, setBackDrop] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAutoAlertType] = useState("");
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isErrorMails, setIsErrorMails] = useState(false);
    const [finalMails, setFinalMails] = useState([]);
    const [isErrorFocus, setIsErrorFocus] = useState(false);
    const [prospect, setProspect] = useState({});
    const [prospectsData, setProspectsData] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [average, setAverage] = useState(0);
    const [monthlyAverage, setMonthlyAverage] = useState(0);
    const [more100, setMore100] = useState(true);
    const [pricingModel, setPricingModel] = useState([]);
    const [tariff, setTariff] = useState(15);
    const [tariffAdditionals, setTariffAdditionals] = useState(25);
    const [preferentialFee, setPreferentialFee] = useState(false);
    const [services, setServices] = useState(defaultStateServices);
    const [isEdit, setIsEdit] = useState(false);
    const [showtutorial, setShowtutorial] = useState(false);
    const [emails, setEmails] = useState("");
    const [randomNum, setRandomNum] = useState(0);
    // Accountant states
    const [valuesAccOnboard, setValuesAccOnboard] = useState(defaultOnboardVal);
    const [valuesAccCleanUp, setValuesAccCleanUp] = useState(defaultCleanUpVal);
    const [valuesAccCatchUp, setValuesAccCatchUp] = useState(defaultCatchUpVal);
    const [valuesAccRecu, setValuesAccRecu] = useState(defaultRecuVal);
    const [valuesAccConsul, setValuesAccConsul] = useState(defaultConsultVal);
    // Bookkeeper states
    const [valuesBookOnboard, setValuesBookOnboard] = useState(defaultOnboardVal);
    const [valuesBookCleanUp, setValuesBookCleanUp] = useState(defaultCleanUpVal);
    const [valuesBookCatchUp, setValuesBookCatchUp] = useState(defaultCatchUpVal);
    const [valuesBookRecu, setValuesBookRecu] = useState(defaultRecuVal);
    const [valuesBookConsul, setValuesBookConsul] = useState(defaultConsultVal);
    const [includeToolbox, setIncludeToolbox] = useState(true);
    const [includeOnboarding, setIncludeOnboarding] = useState(true);
    // Controller states
    const [valuesContOnboard, setValuesContOnboard] = useState(defaultOnboardVal);
    const [valuesContCatchUp, setValuesContCatchUp] = useState(defaultCatchUpVal);
    const [valuesContRecu, setValuesContRecu] = useState(defaultRecuVal);
    const [valuesContConsul, setValuesContConsul] = useState(defaultConsultVal);
    // CFO states
    const [valuesCFOOnboard, setValuesCFOOnboard] = useState(defaultOnboardVal);
    const [valuesCFOCatchUp, setValuesCFOCatchUp] = useState(defaultCatchUpVal);
    const [valuesCFORecu, setValuesCFORecu] = useState(defaultRecuVal);
    const [valuesCFOConsul, setValuesCFOConsul] = useState(defaultConsultVal);
    // Software setup
    const [valuesSetupXero, setValuesSetupXero] = useState(defaultSetUpVal);
    const [valuesSetupBanking, setValuesSetupBanking] = useState(defaultSetUpVal);
    const [valuesSetupTaxes, setValuesSetupTaxes] = useState(defaultSetUpVal);
    const [valuesSetupReport, setValuesSetupReport] = useState(defaultSetUpVal);
    const [valuesSetupOther, setValuesSetupOther] = useState(defaultSetUpVal);
    const [valuesSetupCon, setValuesSetupCon] = useState(defaultConsultVal);
    const [includeAccModuleSet, setIncludeAccModuleSet] = useState(true);
    const [includeAdmModuleSet, setIncludesAdmModuleSet] = useState(true);
    // Software subscription
    const [valuesSubsXero, setValuesSubsXero] = useState(defaultSubsVal);
    const [valuesSubsBanking, setValuesSubsBanking] = useState(defaultSubsVal);
    const [valuesSubsTaxes, setValuesSubsTaxes] = useState(defaultSubsVal);
    const [valuesSubsReport, setValuesSubsReport] = useState(defaultSubsVal);
    const [valuesSubsCustomer, setValuesSubsCustomer] = useState(defaultSubsVal);
    const [includeAccModuleSub, setIncludeAccModuleSub] = useState(true);
    const [includeAdmModuleSub, setIncludeAdmModuleSub] = useState(true);
    // Global
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [proposalDetails, setProposalDetails] = useState([]);
    const [proposalDetailsOG, setProposalDetailsOG] = useState([]);
    const [proposalID, setProposalID] = useState("");
    const [comments, setComments] = useState([]);
    const [dialogComment, setDialogComment] = useState(false);
    const [commentsNum, setCommentsNum] = useState(0);
    const [proposalStatus, setProposalStatus] = useState(1);
    const [previewEdit, setPreviewEdit] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const optionsGroupSendInitialState = [
        { data: t("miscellaneous.send"), disable: false, id: 0, text: t("proposals.sendProposal") },
        { data: t("miscellaneous.copy2"), disable: false, id: 1, text: t("proposals.duplicateProposal") },
        { data: t("miscellaneous.sendCopy"), disable: false, id: 2, text: t("proposals.sendProposalAndDuplicate") },
    ];
    const [optionsGroupSend, setOptionsGroupSend] = useState([...optionsGroupSendInitialState]);

    /** Global variables */
    const noProjectsAcc =
        valuesAccOnboard.chip ||
        valuesAccCleanUp.chip ||
        valuesAccCatchUp.chip ||
        valuesAccRecu.chip ||
        valuesAccConsul.chip;
    const noProjectsBook =
        valuesBookOnboard.chip ||
        valuesBookCleanUp.chip ||
        valuesBookCatchUp.chip ||
        valuesBookRecu.chip ||
        valuesBookConsul.chip;
    const noProjectsCont =
        valuesContOnboard.chip || valuesContCatchUp.chip || valuesContRecu.chip || valuesContConsul.chip;
    const noProjectsCFO = valuesCFOOnboard.chip || valuesCFOCatchUp.chip || valuesCFORecu.chip || valuesCFOConsul.chip;
    const noProjectsSetup =
        valuesSetupXero.chip ||
        valuesSetupBanking.chip ||
        valuesSetupTaxes.chip ||
        valuesSetupReport.chip ||
        valuesSetupCon.chip ||
        valuesSetupOther.chip;
    const noProjectsSubs =
        valuesSubsXero.chip ||
        valuesSubsBanking.chip ||
        valuesSubsReport.chip ||
        valuesSubsTaxes.chip ||
        valuesSubsCustomer.chip;

    const accountantTotal =
        (!valuesAccOnboard.chip ? 0 : valuesAccOnboard.onboardProjectTotal) +
        (!valuesAccCleanUp.chip
            ? 0
            : valuesAccCleanUp.projectTotal +
              valuesAccCleanUp.totalHours * valuesAccCleanUp.hourRate * (1 - valuesAccCleanUp.discount / 100)) +
        (!valuesAccCatchUp.chip
            ? 0
            : valuesAccCatchUp.projectTotal +
              valuesAccCatchUp.totalHours * valuesAccCatchUp.hourRate * (1 - valuesAccCatchUp.discount / 100)) +
        (!valuesAccRecu.chip || !valuesAccRecu.payAnnually
            ? 0
            : valuesAccRecu.recuProjectTotal +
              valuesAccRecu.recuHours * valuesAccRecu.recuHourRate * (1 - valuesAccRecu.recuDiscount / 100)) +
        (!valuesAccConsul.chip ? 0 : valuesAccConsul.consulProjectTotal);

    const bookkeeperTotal =
        (!valuesBookOnboard.chip ? 0 : valuesBookOnboard.onboardProjectTotal) +
        (!valuesBookCleanUp.chip
            ? 0
            : valuesBookCleanUp.projectTotal +
              valuesBookCleanUp.totalHours * tariffAdditionals * (1 - valuesBookCleanUp.discount / 100)) +
        (!valuesBookCatchUp.chip
            ? 0
            : valuesBookCatchUp.projectTotal +
              valuesBookCatchUp.totalHours * tariffAdditionals * (1 - valuesBookCatchUp.discount / 100)) +
        (!valuesBookConsul.chip ? 0 : valuesBookConsul.consulProjectTotal);

    const controllerTotal =
        (!valuesContOnboard.chip ? 0 : valuesContOnboard.onboardProjectTotal) +
        (!valuesContCatchUp.chip
            ? 0
            : valuesContCatchUp.projectTotal +
              valuesContCatchUp.totalHours * valuesContCatchUp.hourRate * (1 - valuesContCatchUp.discount / 100)) +
        (!valuesContRecu.chip || !valuesContRecu.payAnnually
            ? 0
            : valuesContRecu.recuProjectTotal +
              valuesContRecu.recuHours * valuesContRecu.recuHourRate * (1 - valuesContRecu.recuDiscount / 100)) +
        (!valuesContConsul.chip ? 0 : valuesContConsul.consulProjectTotal);

    const cfoTotal =
        (!valuesCFOOnboard.chip ? 0 : valuesCFOOnboard.onboardProjectTotal) +
        (!valuesCFOCatchUp.chip
            ? 0
            : valuesCFOCatchUp.projectTotal +
              valuesCFOCatchUp.totalHours * valuesCFOCatchUp.hourRate * (1 - valuesCFOCatchUp.discount / 100)) +
        (!valuesCFORecu.chip || !valuesCFORecu.payAnnually
            ? 0
            : valuesCFORecu.recuProjectTotal +
              valuesCFORecu.recuHours * valuesCFORecu.recuHourRate * (1 - valuesCFORecu.recuDiscount / 100)) +
        (!valuesCFOConsul.chip ? 0 : valuesCFOConsul.consulProjectTotal);

    const accountantTotalMonth =
        !valuesAccRecu.chip || valuesAccRecu.payAnnually
            ? 0
            : valuesAccRecu.recuProjectTotal +
              valuesAccRecu.recuHours * valuesAccRecu.recuHourRate * (1 - valuesAccRecu.recuDiscount / 100);

    const bookkeeperTotalMonth = !valuesBookRecu.chip
        ? 0
        : valuesBookRecu.recuProjectTotal +
          valuesBookRecu.recuHours * tariffAdditionals * (1 - valuesBookRecu.recuDiscount / 100);

    const controllerTotalMonth =
        !valuesContRecu.chip || valuesContRecu.payAnnually
            ? 0
            : valuesContRecu.recuProjectTotal +
              valuesContRecu.recuHours * valuesContRecu.recuHourRate * (1 - valuesContRecu.recuDiscount / 100);

    const cfoTotalMonth =
        !valuesCFORecu.chip || valuesCFORecu.payAnnually
            ? 0
            : valuesCFORecu.recuProjectTotal +
              valuesCFORecu.recuHours * valuesCFORecu.recuHourRate * (1 - valuesCFORecu.recuDiscount / 100);

    const setUpTotal =
        (!valuesSetupXero.chip ? 0 : valuesSetupXero.projectTotal) +
        (!valuesSetupBanking.chip ? 0 : valuesSetupBanking.projectTotal) +
        (!valuesSetupTaxes.chip ? 0 : valuesSetupTaxes.projectTotal) +
        (!valuesSetupReport.chip ? 0 : valuesSetupReport.projectTotal) +
        (!valuesSetupOther.chip ? 0 : valuesSetupOther.projectTotal) +
        (!valuesSetupCon.chip ? 0 : valuesSetupCon.consulProjectTotal);

    const subscripTotalMonth =
        (!valuesSubsXero.chip ? 0 : valuesSubsXero.totalFee) +
        (!valuesSubsBanking.chip ? 0 : valuesSubsBanking.projectTotal) +
        (!valuesSubsTaxes.chip ? 0 : valuesSubsTaxes.projectTotal) +
        (!valuesSubsReport.chip ? 0 : valuesSubsReport.projectTotal) +
        (!valuesSubsCustomer.chip ? 0 : valuesSubsCustomer.projectTotal);

    const handleChangeMails = (event) => {
        setEmails(event.target.value);
    };

    /** Component functions */
    useEffect(() => {
        (async () => {
            setBackDrop(true);

            let params = new URL(document.location).searchParams;
            let prospectID = params.get("prospectID");
            let companyID = params.get("companyID");
            let proposalID = params.get("proposalID");

            const resultT = await fetch(
                `/getProspects?prospect_source=&creation_user=&ambassador_name=&init_date=&end_date=&status=1`,
                {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    return data;
                });

            if (proposalID) {
                setIsEdit(true);

                const proposal = await fetch(`/getProposalInfo?proposal_id=${proposalID}`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then((res) => res.json())
                    .then((response) => {
                        return response;
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                const hasToolBox =
                    proposal?.include_toolbox === undefined || proposal?.include_toolbox === null
                        ? true
                        : proposal?.include_toolbox;
                const hasOnboarding =
                    proposal?.include_onboarding === undefined || proposal?.include_onboarding === null
                        ? true
                        : proposal?.include_onboarding;

                setIncludeToolbox(hasToolBox);
                setIncludeOnboarding(hasOnboarding);
                setIncludeAdmModuleSub(
                    proposal?.include_adm_module_sub === undefined || proposal?.include_adm_module_sub === null
                        ? true
                        : proposal?.include_adm_module_sub
                );
                setIncludeAccModuleSub(
                    proposal?.include_acc_module_sub === undefined || proposal?.include_acc_module_sub === null
                        ? true
                        : proposal?.include_acc_module_sub
                );
                setIncludesAdmModuleSet(
                    proposal?.include_adm_module_set === undefined || proposal?.include_adm_module_set === null
                        ? true
                        : proposal?.include_adm_module_set
                );
                setIncludeAccModuleSet(
                    proposal?.include_acc_module_set === undefined || proposal?.include_acc_module_set === null
                        ? true
                        : proposal?.include_acc_module_set
                );

                setCommentsNum(proposal?.comments.length);
                setFinalMails(proposal?.additional_emails);
                setRandomNum(proposal?.front_image);

                let proposals = [];

                let genAdd = { ...genAddDefault };
                let genAddCont = { ...genAddContDefault };
                let genAddCFO = { ...genAddCFODefault };

                let auxProsp = {};

                resultT.forEach((val) => {
                    val.companies.forEach((company) => {
                        setProspectsData((prevArray) => [
                            ...prevArray,
                            {
                                company_id: company._id,
                                company_legal_name: company.company_legal_name,
                                country_name: company.country_name,
                                expense_average: company.expense_average,
                                prospect_id: val._id,
                                prospect_email: val.prospect_email,
                                prospect_name: val.prospect_name,
                                prospect_last_name: val.prospect_last_name,
                                client_type: val.client_type,
                            },
                        ]);

                        if (val._id === proposal.prospect_id && company.company_legal_name === proposal.company_name) {
                            auxProsp = {
                                company_id: company._id,
                                company_legal_name: company.company_legal_name,
                                country_name: company.country_name,
                                prospect_id: val._id,
                                prospect_email: val.prospect_email,
                                prospect_name: val.prospect_name,
                                prospect_last_name: val.prospect_last_name,
                                client_type: val.client_type,
                            };
                            setProspect(auxProsp);
                            getPricingProposalEdit(proposal.expenses, company.country_name, val.client_type);
                        }
                    });
                });

                setMonthlyAverage(proposal.expenses);
                calcAverageExp(proposal.expenses, auxProsp.client_type);

                let serviceName = "";
                const defaultServices = {
                    isActive: false,
                    additionals: [],
                };
                const defaultServicesNoAdd = {
                    isActive: false,
                };

                proposal.services.forEach((item) => {
                    let onboarding = { ...defaultServicesNoAdd };
                    let recurring = { ...defaultServices };
                    let cleanUp = { ...defaultServices };
                    let catchUp = { ...defaultServices };
                    let consulting = { ...defaultServicesNoAdd };
                    let xero = { ...defaultServices };
                    let banking = { ...defaultServicesNoAdd };
                    let taxes = { ...defaultServicesNoAdd };
                    let reporting = { ...defaultServicesNoAdd };
                    let other = { ...defaultServicesNoAdd };
                    let support = { ...defaultServicesNoAdd };
                    let consultingArray = [];
                    let totalConsult = 0;
                    let totalHours = 0;
                    let consultFee = 0;

                    if (item.service_name === "Accountant") {
                        serviceName = "accountant";

                        item.projects.forEach((project) => {
                            let contHours = 0;
                            let additionalsFin = [];
                            let addObj = {
                                checkedAcc: false,
                                tothAcc: 0,
                                startDateAcc: null,
                                finishDateAcc: null,
                                checkedAR: false,
                                tothAR: 0,
                                startDateAR: null,
                                finishDateAR: null,
                                checkedAP: false,
                                tothAP: 0,
                                startDateAP: null,
                                finishDateAP: null,
                                checkedClass: false,
                                tothClass: 0,
                                startDateClass: null,
                                finishDateClass: null,
                                checkedMulti: false,
                                tothMulti: 0,
                                startDateMulti: null,
                                finishDateMulti: null,
                                checkedPer: false,
                                tothPer: 0,
                                startDatePer: null,
                                finishDatePer: null,
                                // USA
                                checkedChecks: false,
                                tothChecks: 0,
                                startDateChecks: null,
                                finishDateChecks: null,
                                checkedSales: false,
                                tothSales: 0,
                                startDateSales: null,
                                finishDateSales: null,
                                // Venezuela
                                checkedMuni: false,
                                tothMuni: 0,
                                startDateMuni: null,
                                finishDateMuni: null,
                                checkedOrdConTax: false,
                                tothOrdConTax: 0,
                                startDateOrdConTax: null,
                                finishDateOrdConTax: null,
                                checkedSpeConTax: false,
                                tothSpeConTax: 0,
                                startDateSpeConTax: null,
                                finishDateSpeConTax: null,
                                // Others
                                checkedOthers: false,
                                nameOthers: "",
                                tothOthers: 0,
                                startDateOthers: null,
                                finishDateOthers: null,
                            };
                            let onlyAcc = false;
                            let months = 1;

                            if (
                                project.project_name === "Accountant cleanup" ||
                                project.project_name === "Accountant catchup"
                            ) {
                                months = Math.ceil(
                                    moment(project.end_date, "YYYY-MM-DD").diff(
                                        moment(project.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }

                            project.additionals.forEach((add) => {
                                if (add.additional_name !== "Accountant") {
                                    contHours +=
                                        project.payment_type === 1
                                            ? add.hours *
                                              (add.end_date
                                                  ? Math.ceil(
                                                        moment(add.end_date).diff(
                                                            moment(add.since_date),
                                                            "months",
                                                            true
                                                        )
                                                    )
                                                  : 12)
                                            : add.hours;
                                }

                                let addObjFinal = {
                                    additional_name: add.additional_name,
                                    hours: add.hours,
                                    since_date: moment(add.since_date, "YYYY-MM-DD").format(),
                                    end_date: add.end_date ? moment(add.end_date, "YYYY-MM-DD").format() : null,
                                };

                                if (add.additional_name === "A/R reconciliation") {
                                    genAdd.ar = true;
                                    addObj.checkedAR = true;
                                    addObj.tothAR = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateAR = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateAR = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "A/P reconciliation") {
                                    genAdd.ap = true;
                                    addObj.checkedAP = true;
                                    addObj.tothAP = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateAP = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateAP = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Classes/Categories accounting") {
                                    genAdd.classes = true;
                                    addObj.checkedClass = true;
                                    addObj.tothClass = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateClass = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateClass = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Multi-entity accounting") {
                                    genAdd.multi = true;
                                    addObj.checkedMulti = true;
                                    addObj.tothMulti = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateMulti = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateMulti = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Personalized reporting") {
                                    genAdd.person = true;
                                    addObj.checkedPer = true;
                                    addObj.tothPer = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDatePer = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDatePer = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Checks") {
                                    genAdd.checks = true;
                                    addObj.checkedChecks = true;
                                    addObj.tothChecks = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateChecks = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateChecks = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Sales taxes") {
                                    genAdd.sales = true;
                                    addObj.checkedSales = true;
                                    addObj.tothSales = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateSales = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateSales = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Ordinario") {
                                    genAdd.ordinary = true;
                                    addObj.checkedOrd = true;
                                    addObj.tothOrd = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateOrd = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateOrd = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Especial") {
                                    genAdd.special = true;
                                    addObj.checkedEsp = true;
                                    addObj.tothSpeConTax = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateEsp = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateEsp = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Municipal") {
                                    genAdd.muni = true;
                                    addObj.checkedMuni = true;
                                    addObj.tothMuni = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateMuni = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateMuni = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "OthersAcc") {
                                    genAdd.other = true;
                                    addObjFinal.other_name = add.other_name;
                                    addObj.checkedOthers = true;
                                    addObj.nameOthers = add.other_name;
                                    addObj.tothOthers = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateOthers = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateOthers = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else {
                                    addObj.checkedAcc = true;
                                    addObj.tothAcc = add.hours;
                                    addObj.startDateAcc = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateAcc = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                }
                                additionalsFin.push(addObjFinal);
                            });

                            if (
                                project.additionals.length === 1 &&
                                project.additionals[0].additional_name === "Accountant"
                            ) {
                                onlyAcc = true;
                            }

                            if (project.project_name === "Accountant onboarding") {
                                onboarding = {
                                    isActive: true,
                                    fee: project.monthly_Fee,
                                    discount: project.discount,
                                    description: project.description,
                                    total: project.monthly_Fee * (1 - project.discount / 100),
                                };
                                setValuesAccOnboard({
                                    chip: true,
                                    onboardOriginalFee: project.monthly_Fee,
                                    onboardDiscount: project.discount,
                                    onboardProjectTotal: project.monthly_Fee * (1 - project.discount / 100),
                                    meetingDate: moment(project.since_date, "YYYY-MM-DD"),
                                    description: project.description,
                                });
                            } else if (project.project_name === "Accountant recurring") {
                                let recuObj = {
                                    chip: true,
                                    recuOriginalFee: project.monthly_Fee,
                                    recuDiscount: project.discount,
                                    recuAdditionalsDiscount: project.discount,
                                    recuServFreq: project.payment_type === 1 ? 2 : 1,
                                    recuProjectTotal:
                                        project.payment_type === 1
                                            ? project.monthly_Fee * 12 * 0.85
                                            : project.additionals_only
                                            ? project.additional_fee * addObj.tothAcc * (1 - project.discount / 100)
                                            : project.monthly_Fee * (1 - project.discount / 100),
                                    payAnnually: project.payment_type === 1,
                                    recuAdditionalsOnly: project.additionals_only,
                                    recuHourRate: project.additional_fee,
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    recuHours: contHours,
                                    recuChecks: project.additionals.length !== 0 && !onlyAcc,
                                };

                                recurring = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only ? project.additional_fee : project.monthly_Fee,
                                    addFee: project.additional_fee,
                                    discount: project.discount,
                                    total:
                                        project.payment_type === 1
                                            ? project.monthly_Fee * 12 * 0.85
                                            : project.additionals_only
                                            ? project.additional_fee * addObj.tothAcc * (1 - project.discount / 100)
                                            : project.monthly_Fee * (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    payAnnually: project.payment_type === 1,
                                    recuHours: contHours,
                                    qty: project.additionals_only ? addObj.tothAcc : 1,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };

                                let merged = { ...recuObj, ...addObj };
                                setValuesAccRecu(merged);
                            } else if (project.project_name === "Accountant cleanup") {
                                let cleanObj = {
                                    chip: true,
                                    cleanUpOriginalFee: project.monthly_Fee,
                                    discount: project.discount,
                                    cleanUpAdditionalsDiscount: project.discount,
                                    projectTotal: project.additionals_only
                                        ? project.fixed_hours
                                            ? project.additional_fee * addObj.tothAcc * (1 - project.discount / 100)
                                            : project.additional_fee *
                                              addObj.tothAcc *
                                              months *
                                              (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    additionalsOnly: project.additionals_only,
                                    hourRate: project.additional_fee,
                                    sinceDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    months: months,
                                    totalHours: contHours,
                                    cleanUpChecks: project.additionals.length !== 0 && !onlyAcc,
                                    fixedHours: project.fixed_hours ?? false,
                                };
                                cleanUp = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only ? project.additional_fee : project.monthly_Fee,
                                    addFee: project.additional_fee,
                                    discount: project.discount,
                                    total: project.additionals_only
                                        ? project.fixed_hours
                                            ? project.additional_fee * addObj.tothAcc * (1 - project.discount / 100)
                                            : project.additional_fee *
                                              addObj.tothAcc *
                                              months *
                                              (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    monthQty: project.additionals_only
                                        ? project.fixed_hours
                                            ? addObj.tothAcc
                                            : addObj.tothAcc * months
                                        : months,
                                    totalHours: contHours,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };
                                let merged = { ...cleanObj, ...addObj };
                                setValuesAccCleanUp(merged);
                            } else if (project.project_name === "Accountant catchup") {
                                let catchObj = {
                                    chip: true,
                                    catchUpOriginalFee: project.monthly_Fee,
                                    discount: project.discount,
                                    catchUpAdditionalsDiscount: project.discount,
                                    projectTotal: project.additionals_only
                                        ? project.fixed_hours
                                            ? project.additional_fee * addObj.tothAcc * (1 - project.discount / 100)
                                            : project.additional_fee *
                                              addObj.tothAcc *
                                              months *
                                              (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    additionalsOnly: project.additionals_only,
                                    hourRate: project.additional_fee,
                                    sinceDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    months: months,
                                    totalHours: contHours,
                                    catchUpChecks: project.additionals.length !== 0 && !onlyAcc,
                                    fixedHours: project.fixed_hours ?? false,
                                };
                                catchUp = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only ? project.additional_fee : project.monthly_Fee,
                                    addFee: project.additional_fee,
                                    discount: project.discount,
                                    total: project.additionals_only
                                        ? project.fixed_hours
                                            ? project.additional_fee * addObj.tothAcc * (1 - project.discount / 100)
                                            : project.additional_fee *
                                              addObj.tothAcc *
                                              months *
                                              (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    monthQty: project.additionals_only
                                        ? project.fixed_hours
                                            ? addObj.tothAcc
                                            : addObj.tothAcc * months
                                        : months,
                                    totalHours: contHours,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };

                                let merged = { ...catchObj, ...addObj };
                                setValuesAccCatchUp(merged);
                            } else {
                                consultingArray.push({
                                    total:
                                        project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100),
                                    hours: project.consulting_hours,
                                    description: project.description,
                                    discount: project.discount,
                                });
                                consultFee = project.monthly_Fee;
                                totalConsult +=
                                    project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100);
                                totalHours += project.consulting_hours;
                            }
                        });

                        if (consultingArray.length !== 0) {
                            consulting = {
                                isActive: true,
                                fee: consultFee,
                                array: consultingArray,
                            };

                            setValuesAccConsul({
                                chip: true,
                                consulOriginalFee: consultFee,
                                consulProjectTotal: totalConsult,
                                consulHours: totalHours,
                                consultingArray: consultingArray,
                            });
                        }

                        proposals.push({
                            name: "Accountant",
                            onboarding: onboarding,
                            cleanUp: cleanUp,
                            catchUp: catchUp,
                            recurring: recurring,
                            consulting: consulting,
                            totalAll:
                                (!cleanUp.isActive
                                    ? 0
                                    : cleanUp.total +
                                      cleanUp.totalHours * cleanUp.addFee * (1 - cleanUp.discount / 100)) +
                                (!catchUp.isActive
                                    ? 0
                                    : catchUp.total +
                                      catchUp.totalHours * catchUp.addFee * (1 - catchUp.discount / 100)) +
                                (!onboarding.isActive ? 0 : onboarding.total) +
                                (!recurring.isActive
                                    ? 0
                                    : recurring.total +
                                      recurring.recuHours * recurring.addFee * (1 - recurring.discount / 100)) +
                                (!consulting.isActive ? 0 : totalConsult),
                            genAdditionals: genAdd,
                            prospect: auxProsp,
                            expenses: proposal.expenses,
                            status: proposal.status,
                        });
                    } else if (item.service_name === "Bookkeeper") {
                        setTariff(item.tariff);
                        setTariffAdditionals(item.tariff_additionals);
                        setPreferentialFee(item.preferential_fee);

                        serviceName = "bookkeeper";

                        item.projects.forEach((project) => {
                            let contHours = 0;
                            let additionalsFin = [];
                            let addObj = {
                                checkedAR: false,
                                tothAR: 0,
                                startDateAR: null,
                                finishDateAR: null,
                                checkedAP: false,
                                tothAP: 0,
                                startDateAP: null,
                                finishDateAP: null,
                                checkedClass: false,
                                tothClass: 0,
                                startDateClass: null,
                                finishDateClass: null,
                                checkedMulti: false,
                                tothMulti: 0,
                                startDateMulti: null,
                                finishDateMulti: null,
                                checkedPer: false,
                                tothPer: 0,
                                startDatePer: null,
                                finishDatePer: null,
                                checkedChecks: false,
                                tothChecks: 0,
                                startDateChecks: null,
                                finishDateChecks: null,
                                checkedAccrual: false,
                                tothAccrual: 0,
                                startDateAccrual: null,
                                finishDateAccrual: null,
                                // Others
                                checkedOthers: false,
                                nameOthers: "",
                                tothOthers: 0,
                                startDateOthers: null,
                                finishDateOthers: null,
                            };
                            let months = 1;
                            let onlyBook = false;

                            if (
                                project.project_name === "Bookkeeper cleanup" ||
                                project.project_name === "Bookkeeper catchup"
                            ) {
                                months = Math.ceil(
                                    moment(project.end_date, "YYYY-MM-DD").diff(
                                        moment(project.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }

                            project.additionals.forEach((add) => {
                                if (add.additional_name !== "Bookkeeper") {
                                    contHours += add.hours;
                                }

                                let addObjFinal = {
                                    additional_name: add.additional_name,
                                    hours: add.hours,
                                    since_date: moment(add.since_date, "YYYY-MM-DD").format(),
                                    end_date: add.end_date ? moment(add.end_date, "YYYY-MM-DD").format() : null,
                                };

                                if (add.additional_name === "A/R") {
                                    genAdd.ar = true;
                                    addObj.checkedAR = true;
                                    addObj.tothAR = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateAR = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateAR = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "A/P") {
                                    genAdd.ap = true;
                                    addObj.checkedAP = true;
                                    addObj.tothAP = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateAP = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateAP = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Classes/Categories accounting") {
                                    genAdd.classes = true;
                                    addObj.checkedClass = true;
                                    addObj.tothClass = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateClass = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateClass = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Multi-entity accounting") {
                                    genAdd.multi = true;
                                    addObj.checkedMulti = true;
                                    addObj.tothMulti = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateMulti = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateMulti = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Personalized reporting") {
                                    genAdd.person = true;
                                    addObj.checkedPer = true;
                                    addObj.tothPer = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDatePer = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDatePer = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Checks") {
                                    genAdd.checks = true;
                                    addObj.checkedChecks = true;
                                    addObj.tothChecks = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateChecks = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateChecks = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Accrual") {
                                    genAdd.accrual = true;
                                    addObj.checkedAccrual = true;
                                    addObj.tothAccrual = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateAccrual = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateAccrual = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Others") {
                                    genAdd.other = true;
                                    addObjFinal.other_name = add.other_name;
                                    addObj.checkedOthers = true;
                                    addObj.nameOthers = add.other_name;
                                    addObj.tothOthers = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateOthers = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateOthers = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else {
                                    addObj.checkedBook = true;
                                    addObj.tothBook = add.hours;
                                    addObj.startDateBook = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateBook = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                }
                                additionalsFin.push(addObjFinal);
                            });

                            if (
                                project.additionals.length === 1 &&
                                project.additionals[0].additional_name === "Bookkeeper"
                            ) {
                                onlyBook = true;
                            }

                            if (project.project_name === "SMBs' onboarding") {
                                onboarding = {
                                    isActive: true,
                                    fee: Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    discount: project.discount,
                                    description: project.description,
                                    total:
                                        Math.ceil(proposal.expenses / 10000) *
                                        item.tariff *
                                        (1 - project.discount / 100),
                                };
                                setValuesBookOnboard({
                                    chip: true,
                                    onboardOriginalFee: Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    onboardDiscount: project.discount,
                                    onboardProjectTotal:
                                        Math.ceil(proposal.expenses / 10000) *
                                        item.tariff *
                                        (1 - project.discount / 100),
                                    meetingDate: moment(project.since_date, "YYYY-MM-DD"),
                                    description: project.description,
                                });
                            } else if (project.project_name === "Bookkeeper recurring") {
                                let recuObj = {
                                    chip: true,
                                    recuOriginalFee: Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    recuDiscount: project.discount,
                                    recuAdditionalsDiscount: project.discount,
                                    recuProjectTotal: project.additionals_only
                                        ? item.tariff_additionals * addObj.tothBook * (1 - project.discount / 100)
                                        : Math.ceil(proposal.expenses / 10000) *
                                          item.tariff *
                                          (1 - project.discount / 100),
                                    payAnnually: project.payment_type === 1,
                                    recuAdditionalsOnly: project.additionals_only,
                                    recuHourRate: item.tariff_additionals,
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    recuHours: contHours,
                                    recuChecks: project.additionals.length !== 0 && !onlyBook,
                                };

                                recurring = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only
                                        ? item.tariff_additionals
                                        : Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    addFee: item.tariff_additionals,
                                    discount: project.discount,
                                    total: project.additionals_only
                                        ? item.tariff_additionals * addObj.tothBook * (1 - project.discount / 100)
                                        : Math.ceil(proposal.expenses / 10000) *
                                          item.tariff *
                                          (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    payAnnually: project.payment_type === 1,
                                    recuHours: contHours,
                                    qty: project.additionals_only ? addObj.tothBook : 1,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                    addOnly: project.additionals_only,
                                };

                                let merged = { ...recuObj, ...addObj };
                                setValuesBookRecu(merged);
                            } else if (project.project_name === "Bookkeeper cleanup") {
                                let cleanObj = {
                                    chip: true,
                                    cleanUpOriginalFee: Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    discount: project.discount,
                                    cleanUpAdditionalsDiscount: project.discount,
                                    projectTotal: project.additionals_only
                                        ? project.fixed_hours
                                            ? item.tariff_additionals * addObj.tothBook * (1 - project.discount / 100)
                                            : item.tariff_additionals *
                                              addObj.tothBook *
                                              months *
                                              (1 - project.discount / 100)
                                        : Math.ceil(proposal.expenses / 10000) *
                                          item.tariff *
                                          months *
                                          (1 - project.discount / 100),
                                    additionalsOnly: project.additionals_only,
                                    hourRate: item.tariff_additionals,
                                    sinceDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    months: months,
                                    totalHours: contHours,
                                    cleanUpChecks: project.additionals.length !== 0 && !onlyBook,
                                    fixedHours: project.fixed_hours ?? false,
                                };
                                cleanUp = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only
                                        ? item.tariff_additionals
                                        : Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    addFee: item.tariff_additionals,
                                    discount: project.discount,
                                    total: project.additionals_only
                                        ? project.fixed_hours
                                            ? item.tariff_additionals * addObj.tothBook
                                            : item.tariff_additionals * addObj.tothBook * months
                                        : Math.ceil(proposal.expenses / 10000) * item.tariff * months,
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    monthQty: project.additionals_only
                                        ? project.fixed_hours
                                            ? addObj.tothBook
                                            : addObj.tothBook * months
                                        : months,
                                    totalHours: contHours,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };
                                let merged = { ...cleanObj, ...addObj };
                                setValuesBookCleanUp(merged);
                            } else if (project.project_name === "Bookkeeper catchup") {
                                let catchObj = {
                                    chip: true,
                                    catchUpOriginalFee: Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    discount: project.discount,
                                    catchUpAdditionalsDiscount: project.discount,
                                    projectTotal: project.additionals_only
                                        ? project.fixed_hours
                                            ? item.tariff_additionals * addObj.tothBook * (1 - project.discount / 100)
                                            : item.tariff_additionals *
                                              months *
                                              addObj.tothBook *
                                              (1 - project.discount / 100)
                                        : Math.ceil(proposal.expenses / 10000) *
                                          item.tariff *
                                          months *
                                          (1 - project.discount / 100),
                                    additionalsOnly: project.additionals_only,
                                    hourRate: item.tariff_additionals,
                                    sinceDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    months: months,
                                    totalHours: contHours,
                                    catchUpChecks: project.additionals.length !== 0 && !onlyBook,
                                    fixedHours: project.fixed_hours ?? false,
                                };
                                catchUp = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only
                                        ? item.tariff_additionals
                                        : Math.ceil(proposal.expenses / 10000) * item.tariff,
                                    addFee: item.tariff_additionals,
                                    discount: project.discount,
                                    total: project.additionals_only
                                        ? project.fixed_hours
                                            ? item.tariff_additionals * addObj.tothBook
                                            : item.tariff_additionals * months * addObj.tothBook
                                        : Math.ceil(proposal.expenses / 10000) * item.tariff * months,
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    monthQty: project.additionals_only
                                        ? project.fixed_hours
                                            ? addObj.tothBook
                                            : addObj.tothBook * months
                                        : months,
                                    totalHours: contHours,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };

                                let merged = { ...catchObj, ...addObj };
                                setValuesBookCatchUp(merged);
                            } else {
                                consultingArray.push({
                                    total:
                                        project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100),
                                    hours: project.consulting_hours,
                                    description: project.description,
                                    discount: project.discount,
                                });
                                consultFee = project.monthly_Fee;
                                totalConsult +=
                                    project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100);
                                totalHours += project.consulting_hours;
                            }
                        });

                        if (consultingArray.length !== 0) {
                            consulting = {
                                isActive: true,
                                fee: consultFee,
                                array: consultingArray,
                            };

                            setValuesBookConsul({
                                chip: true,
                                consulOriginalFee: consultFee,
                                consulProjectTotal: totalConsult,
                                consulHours: totalHours,
                                consultingArray: consultingArray,
                            });
                        }

                        proposals.push({
                            name: "Bookkeeper",
                            onboarding: onboarding,
                            cleanUp: cleanUp,
                            catchUp: catchUp,
                            recurring: recurring,
                            consulting: consulting,
                            totalAll:
                                (!cleanUp.isActive
                                    ? 0
                                    : (cleanUp.total + cleanUp.totalHours * cleanUp.addFee) *
                                      (1 - cleanUp.discount / 100)) +
                                (!catchUp.isActive
                                    ? 0
                                    : (catchUp.total + catchUp.totalHours * catchUp.addFee) *
                                      (1 - catchUp.discount / 100)) +
                                (!onboarding.isActive ? 0 : onboarding.total) +
                                (!recurring.isActive
                                    ? 0
                                    : recurring.total +
                                      recurring.recuHours * recurring.addFee * (1 - recurring.discount / 100)) +
                                (!consulting.isActive ? 0 : totalConsult) +
                                +(hasToolBox ? 99 : 0) +
                                (hasOnboarding ? 499 : 0),
                            genAdditionals: genAdd,
                            prospect: auxProsp,
                            expenses: proposal.expenses,
                            status: proposal.status,
                        });
                    } else if (item.service_name === "Controller") {
                        serviceName = "controller";

                        item.projects.forEach((project) => {
                            let contHours = 0;
                            let additionalsFin = [];
                            let addObj = {
                                checkedCont: false,
                                tothCont: 0,
                                startDateCont: null,
                                finishDateCont: null,
                                checkedExe: false,
                                tothExe: 0,
                                startDateExe: null,
                                finishDateExe: null,
                                checkedCons: false,
                                tothCons: 0,
                                startDateCons: null,
                                finishDateCons: null,
                                checkedRep: false,
                                tothRep: 0,
                                startDateRep: null,
                                finishDateRep: null,
                                // Others
                                checkedOthers: false,
                                nameOthers: "",
                                tothOthers: 0,
                                startDateOthers: null,
                                finishDateOthers: null,
                            };
                            let onlyCont = false;
                            let months = 1;

                            if (project.project_name === "Controller catchup") {
                                months = Math.ceil(
                                    moment(project.end_date, "YYYY-MM-DD").diff(
                                        moment(project.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }

                            project.additionals.forEach((add) => {
                                if (add.additional_name !== "Controller") {
                                    contHours += add.hours;
                                }

                                let addObjFinal = {
                                    additional_name: add.additional_name,
                                    hours: add.hours,
                                    since_date: moment(add.since_date, "YYYY-MM-DD").format(),
                                    end_date: add.end_date ? moment(add.end_date, "YYYY-MM-DD").format() : null,
                                };

                                if (add.additional_name === "Ejecución de planificación fiscal") {
                                    genAddCont.exe = true;
                                    addObj.checkedExe = true;
                                    addObj.tothExe = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateExe = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateExe = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Planificación de administración y contabilidad") {
                                    genAddCont.cons = true;
                                    addObj.checkedCons = true;
                                    addObj.tothCons = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateCons = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateCons = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Reportes para auditores externos") {
                                    genAddCont.rep = true;
                                    addObj.checkedRep = true;
                                    addObj.tothRep = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateRep = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateRep = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Others") {
                                    addObjFinal.other_name = add.other_name;
                                    genAddCont.other = true;
                                    addObj.checkedOthers = true;
                                    addObj.nameOthers = add.other_name;
                                    addObj.tothOthers = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateOthers = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateOthers = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else {
                                    addObj.checkedCont = true;
                                    addObj.tothCont = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.totalHours = add.hours;
                                    addObj.startDateCont = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateCont = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                }
                                additionalsFin.push(addObjFinal);
                            });

                            if (
                                project.additionals.length === 1 &&
                                project.additionals[0].additional_name === "Controller"
                            ) {
                                onlyCont = true;
                            }

                            if (project.project_name === "Controller onboarding") {
                                onboarding = {
                                    isActive: true,
                                    fee: project.monthly_Fee,
                                    discount: project.discount,
                                    total: project.monthly_Fee * (1 - project.discount / 100),
                                };

                                setValuesContOnboard({
                                    chip: true,
                                    onboardOriginalFee: project.monthly_Fee,
                                    onboardDiscount: project.discount,
                                    onboardProjectTotal: project.monthly_Fee * (1 - project.discount / 100),
                                    meetingDate: moment(project.since_date, "YYYY-MM-DD"),
                                    description: project.description,
                                });
                            } else if (project.project_name === "Controller recurring") {
                                let recuObj = {
                                    chip: true,
                                    recuOriginalFee: project.monthly_Fee,
                                    recuDiscount: project.discount,
                                    recuAdditionalsDiscount: project.discount,
                                    recuProjectTotal:
                                        project.payment_type === 1
                                            ? project.monthly_Fee * 12 * 0.85
                                            : project.additionals_only
                                            ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                            : project.monthly_Fee * (1 - project.discount / 100),
                                    payAnnually: project.payment_type === 1,
                                    recuAdditionalsOnly: project.additionals_only,
                                    recuHourRate: project.additional_fee,
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    recuHours: contHours,
                                    recuChecks: project.additionals.length !== 0 && !onlyCont,
                                };

                                recurring = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only ? project.additional_fee : project.monthly_Fee,
                                    addFee: project.additional_fee,
                                    discount: project.discount,
                                    total:
                                        project.payment_type === 1
                                            ? project.monthly_Fee * 12 * 0.85
                                            : project.additionals_only
                                            ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                            : project.monthly_Fee * (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    payAnnually: project.payment_type === 1,
                                    recuHours: contHours,
                                    qty: project.additionals_only ? addObj.totalHours : 1,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };

                                let merged = { ...recuObj, ...addObj };
                                setValuesContRecu(merged);
                            } else if (project.project_name === "Controller catchup") {
                                let catchObj = {
                                    chip: true,
                                    catchUpOriginalFee: project.monthly_Fee,
                                    discount: project.discount,
                                    catchUpAdditionalsDiscount: project.discount,
                                    projectTotal: project.additionals_only
                                        ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    additionalsOnly: project.additionals_only,
                                    hourRate: project.additional_fee,
                                    sinceDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    months: months,
                                    totalHours: contHours,
                                    catchUpChecks: project.additionals.length !== 0 && !onlyCont,
                                    fixedHours: project.fixed_hours ?? false,
                                };
                                catchUp = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only ? project.additional_fee : project.monthly_Fee,
                                    addFee: project.additional_fee,
                                    discount: project.discount,
                                    total: project.additionals_only
                                        ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    monthQty: project.additionals_only ? addObj.totalHours : months,
                                    totalHours: contHours,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };

                                let merged = { ...catchObj, ...addObj };
                                setValuesContCatchUp(merged);
                            } else {
                                consultingArray.push({
                                    total:
                                        project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100),
                                    hours: project.consulting_hours,
                                    description: project.description,
                                    discount: project.discount,
                                });
                                consultFee = project.monthly_Fee;
                                totalConsult +=
                                    project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100);
                                totalHours += project.consulting_hours;
                            }
                        });

                        if (consultingArray.length !== 0) {
                            consulting = {
                                isActive: true,
                                fee: consultFee,
                                array: consultingArray,
                            };

                            setValuesContConsul({
                                chip: true,
                                consulOriginalFee: consultFee,
                                consulProjectTotal: totalConsult,
                                consulHours: totalHours,
                                consultingArray: consultingArray,
                            });
                        }

                        proposals.push({
                            name: "Controller",
                            onboarding: onboarding,
                            cleanUp: cleanUp,
                            catchUp: catchUp,
                            recurring: recurring,
                            consulting: consulting,
                            totalAll:
                                (!catchUp.isActive
                                    ? 0
                                    : catchUp.total +
                                      catchUp.totalHours * catchUp.addFee * (1 - catchUp.discount / 100)) +
                                (!onboarding.isActive ? 0 : onboarding.total) +
                                (!recurring.isActive
                                    ? 0
                                    : recurring.total +
                                      recurring.recuHours * recurring.addFee * (1 - recurring.discount / 100)) +
                                (!consulting.isActive ? 0 : totalConsult),
                            genAdditionals: genAddCont,
                            prospect: auxProsp,
                            expenses: proposal.expenses,
                            status: proposal.status,
                        });
                    } else if (item.service_name === "CFO") {
                        serviceName = "cfo";

                        item.projects.forEach((project) => {
                            let cfoHours = 0;
                            let additionalsFin = [];
                            let addObj = {
                                checkedCFO: false,
                                tothCFO: 0,
                                startDateCFO: null,
                                finishDateCFO: null,
                                checkedGob: false,
                                tothGob: 0,
                                startDateGob: null,
                                finishDateGob: null,
                                checkedPres: false,
                                tothPres: 0,
                                startDatePres: null,
                                finishDatePres: null,
                                checkedRep: false,
                                tothRep: 0,
                                startDateRep: null,
                                finishDateRep: null,
                                // Others
                                checkedOthers: false,
                                nameOthers: "",
                                tothOthers: 0,
                                startDateOthers: null,
                                finishDateOthers: null,
                            };
                            let onlyCFO = false;
                            let months = 1;

                            if (project.project_name === "CFO catchup") {
                                months = Math.ceil(
                                    moment(project.end_date, "YYYY-MM-DD").diff(
                                        moment(project.since_date, "YYYY-MM-DD"),
                                        "months",
                                        true
                                    )
                                );
                            }

                            project.additionals.forEach((add) => {
                                if (add.additional_name !== "CFO") {
                                    cfoHours += add.hours;
                                }

                                let addObjFinal = {
                                    additional_name: add.additional_name,
                                    hours: add.hours,
                                    since_date: moment(add.since_date, "YYYY-MM-DD").format(),
                                    end_date: add.end_date ? moment(add.end_date, "YYYY-MM-DD").format() : null,
                                };

                                if (add.additional_name === "Gobierno corporativo") {
                                    genAddCFO.gob = true;
                                    addObj.checkedGob = true;
                                    addObj.tothGob = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateGob = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateGob = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Presentaciones financieras") {
                                    genAddCFO.pres = true;
                                    addObj.checkedPres = true;
                                    addObj.tothPres = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDatePres = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDatePres = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Reportes para inversionistas") {
                                    genAddCFO.repInv = true;
                                    addObj.checkedRep = true;
                                    addObj.tothRep = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateRep = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateRep = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Others") {
                                    genAddCFO.other = true;
                                    addObjFinal.other_name = add.other_name;
                                    addObj.checkedOthers = true;
                                    addObj.nameOthers = add.other_name;
                                    addObj.tothOthers = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.startDateOthers = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateOthers = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else {
                                    addObj.checkedCFO = true;
                                    addObj.tothCFO = project.fixed_hours ? add.hours : add.hours / months;
                                    addObj.totalHours = add.hours;
                                    addObj.startDateCFO = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateCFO = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                }
                                additionalsFin.push(addObjFinal);
                            });

                            if (project.additionals.length === 1 && project.additionals[0].additional_name === "CFO") {
                                onlyCFO = true;
                            }

                            if (project.project_name === "CFO onboarding") {
                                onboarding = {
                                    isActive: true,
                                    fee: project.monthly_Fee,
                                    discount: project.discount,
                                    total: project.monthly_Fee * (1 - project.discount / 100),
                                };
                                setValuesCFOOnboard({
                                    chip: true,
                                    onboardOriginalFee: project.monthly_Fee,
                                    onboardDiscount: project.discount,
                                    onboardProjectTotal: project.monthly_Fee * (1 - project.discount / 100),
                                    meetingDate: moment(project.since_date, "YYYY-MM-DD"),
                                    description: project.description,
                                });
                            } else if (project.project_name === "CFO recurring") {
                                let recuObj = {
                                    chip: true,
                                    recuOriginalFee: project.monthly_Fee,
                                    recuDiscount: project.discount,
                                    recuAdditionalsDiscount: project.discount,
                                    recuProjectTotal:
                                        project.payment_type === 1
                                            ? project.monthly_Fee * 12 * 0.85
                                            : project.additionals_only
                                            ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                            : project.monthly_Fee * (1 - project.discount / 100),
                                    payAnnually: project.payment_type === 1,
                                    recuAdditionalsOnly: project.additionals_only,
                                    recuHourRate: project.additional_fee,
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    recuHours: cfoHours,
                                    recuChecks: project.additionals.length !== 0 && !onlyCFO,
                                };

                                recurring = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only ? project.additional_fee : project.monthly_Fee,
                                    addFee: project.additional_fee,
                                    discount: project.discount,
                                    total:
                                        project.payment_type === 1
                                            ? project.monthly_Fee * 12 * 0.85
                                            : project.additionals_only
                                            ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                            : project.monthly_Fee * (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    payAnnually: project.payment_type === 1,
                                    recuHours: cfoHours,
                                    qty: project.additionals_only ? addObj.totalHours : 1,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };

                                let merged = { ...recuObj, ...addObj };
                                setValuesCFORecu(merged);
                            } else if (project.project_name === "CFO catchup") {
                                let catchObj = {
                                    chip: true,
                                    catchUpOriginalFee: project.monthly_Fee,
                                    discount: project.discount,
                                    catchUpAdditionalsDiscount: project.discount,
                                    projectTotal: project.additionals_only
                                        ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    additionalsOnly: project.additionals_only,
                                    hourRate: project.additional_fee,
                                    sinceDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    months: months,
                                    totalHours: cfoHours,
                                    catchUpChecks: project.additionals.length !== 0 && !onlyCFO,
                                    fixedHours: project.fixed_hours ?? false,
                                };
                                catchUp = {
                                    isActive: true,
                                    additionals: additionalsFin,
                                    fee: project.additionals_only ? project.additional_fee : project.monthly_Fee,
                                    addFee: project.additional_fee,
                                    discount: project.discount,
                                    total: project.additionals_only
                                        ? project.additional_fee * addObj.totalHours * (1 - project.discount / 100)
                                        : project.monthly_Fee * months * (1 - project.discount / 100),
                                    initDate: moment(project.since_date, "YYYY-MM-DD"),
                                    untilDate: moment(project.end_date, "YYYY-MM-DD"),
                                    monthQty: project.additionals_only ? addObj.totalHours : months,
                                    totalHours: cfoHours,
                                    onlyAdd: additionalsFin.length === 1 && project.additionals_only,
                                };

                                let merged = { ...catchObj, ...addObj };
                                setValuesCFOCatchUp(merged);
                            } else {
                                consultingArray.push({
                                    total:
                                        project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100),
                                    hours: project.consulting_hours,
                                    description: project.description,
                                    discount: project.discount,
                                });
                                consultFee = project.monthly_Fee;
                                totalConsult +=
                                    project.monthly_Fee * project.consulting_hours * (1 - project.discount / 100);
                                totalHours += project.consulting_hours;
                            }
                        });

                        if (consultingArray.length !== 0) {
                            consulting = {
                                isActive: true,
                                fee: consultFee,
                                array: consultingArray,
                            };

                            setValuesCFOConsul({
                                chip: true,
                                consulOriginalFee: consultFee,
                                consulProjectTotal: totalConsult,
                                consulHours: totalHours,
                                consultingArray: consultingArray,
                            });
                        }

                        proposals.push({
                            name: "CFO",
                            onboarding: onboarding,
                            cleanUp: cleanUp,
                            catchUp: catchUp,
                            recurring: recurring,
                            consulting: consulting,
                            totalAll:
                                (!catchUp.isActive
                                    ? 0
                                    : catchUp.total +
                                      catchUp.totalHours * catchUp.addFee * (1 - catchUp.discount / 100)) +
                                (!onboarding.isActive ? 0 : onboarding.total) +
                                (!recurring.isActive
                                    ? 0
                                    : recurring.total +
                                      recurring.recuHours * recurring.addFee * (1 - recurring.discount / 100)) +
                                (!consulting.isActive ? 0 : totalConsult),
                            genAdditionals: genAddCFO,
                            prospect: auxProsp,
                            expenses: proposal.expenses,
                            status: proposal.status,
                        });
                    } else if (item.service_name === "Software setup") {
                        serviceName = "softSetup";

                        let fee = 0;

                        fee =
                            auxProsp.country_name === "Venezuela"
                                ? 36
                                : latam.includes(auxProsp.country_name)
                                ? 42
                                : 60;

                        item.projects.forEach((project) => {
                            switch (project.project_name) {
                                case "Xero setup":
                                    xero = {
                                        isActive: true,
                                        fee: fee,
                                        package: project.package,
                                        discount: project.discount,
                                        hours: project.consulting_hours,
                                        total: fee * project.consulting_hours * (1 - project.discount / 100),
                                        commitmentDateProposal: project.commitment_date_proposal,
                                        additionals: [],
                                    };

                                    setValuesSetupXero({
                                        chip: true,
                                        originalFee: fee,
                                        hours: project.consulting_hours,
                                        discount: project.discount,
                                        package: project.package,
                                        version: project.version,
                                        projectTotal: fee * project.consulting_hours * (1 - project.discount / 100),
                                        isCustom: project.package !== 4,
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    });
                                    break;
                                case "Kiiper Banking setup":
                                    banking = {
                                        isActive: true,
                                        fee: fee,
                                        package: project.package,
                                        discount: project.discount,
                                        hours: project.consulting_hours,
                                        total: fee * project.consulting_hours * (1 - project.discount / 100),
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    };

                                    setValuesSetupBanking({
                                        chip: true,
                                        originalFee: fee,
                                        hours: project.consulting_hours,
                                        discount: project.discount,
                                        package: project.package,
                                        version: project.version,
                                        projectTotal: fee * project.consulting_hours * (1 - project.discount / 100),
                                        isCustom: project.package !== 4,
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    });
                                    break;
                                case "Kiiper Taxes setup":
                                    taxes = {
                                        isActive: true,
                                        fee: fee,
                                        package: project.package,
                                        discount: project.discount,
                                        hours: project.consulting_hours,
                                        total: fee * project.consulting_hours * (1 - project.discount / 100),
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    };

                                    setValuesSetupTaxes({
                                        chip: true,
                                        originalFee: fee,
                                        hours: project.consulting_hours,
                                        discount: project.discount,
                                        package: project.package,
                                        version: project.version,
                                        projectTotal: fee * project.consulting_hours * (1 - project.discount / 100),
                                        isCustom: project.package !== 4,
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    });
                                    break;
                                case "Kiiper Reporting setup":
                                    reporting = {
                                        isActive: true,
                                        fee: fee,
                                        package: project.package,
                                        discount: project.discount,
                                        hours: project.consulting_hours,
                                        total: fee * project.consulting_hours * (1 - project.discount / 100),
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    };

                                    setValuesSetupReport({
                                        chip: true,
                                        originalFee: fee,
                                        hours: project.consulting_hours,
                                        discount: project.discount,
                                        package: project.package,
                                        version: project.version,
                                        projectTotal: fee * project.consulting_hours * (1 - project.discount / 100),
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    });
                                    break;
                                case "Other setup":
                                    other = {
                                        isActive: true,
                                        fee: fee,
                                        discount: project.discount,
                                        hours: project.hours,
                                        total: fee * project.consulting_hours * (1 - project.discount / 100),
                                        software: project.version,
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    };
                                    setValuesSetupOther({
                                        chip: true,
                                        originalFee: fee,
                                        hours: project.consulting_hours,
                                        discount: project.discount,
                                        package: project.package,
                                        software: project.version,
                                        projectTotal: fee * project.consulting_hours * (1 - project.discount / 100),
                                        commitmentDateProposal: project.commitment_date_proposal,
                                    });
                                    break;
                                default:
                                    consultingArray.push({
                                        total: fee * project.consulting_hours * (1 - project.discount / 100),
                                        hours: project.consulting_hours,
                                        description: project.description,
                                        discount: project.discount,
                                    });
                                    consultFee = fee;
                                    totalConsult += fee * project.consulting_hours * (1 - project.discount / 100);
                                    totalHours += project.consulting_hours;
                                    break;
                            }
                        });

                        if (consultingArray.length !== 0) {
                            consulting = {
                                isActive: true,
                                fee: consultFee,
                                array: consultingArray,
                            };

                            setValuesSetupCon({
                                chip: true,
                                consulOriginalFee: consultFee,
                                consulProjectTotal: totalConsult,
                                consulHours: totalHours,
                                consultingArray: consultingArray,
                            });
                        }

                        proposals.push({
                            name: "Software setup",
                            xero: xero,
                            banking: banking,
                            taxes: taxes,
                            reporting: reporting,
                            other: other,
                            consulting: consulting,
                            totalAll:
                                (!xero.isActive ? 0 : xero.total) +
                                (!banking.isActive ? 0 : banking.total) +
                                (!taxes.isActive ? 0 : taxes.total) +
                                (!reporting.isActive ? 0 : reporting.total) +
                                (!other.isActive ? 0 : other.total) +
                                (!consulting.isActive ? 0 : totalConsult),
                            prospect: auxProsp,
                            expenses: proposal.expenses,
                            status: proposal.status,
                        });
                    } else if (item.service_name === "Software subscription") {
                        serviceName = "softSubs";

                        item.projects.forEach((project) => {
                            let totalFee = 0;
                            let users = 0;
                            let additionalsFin = [];
                            let addObj = {
                                checkedExp: false,
                                tothExp: 0,
                                startDateExp: null,
                                finishDateExp: null,
                                checkedPro: false,
                                tothPro: 0,
                                startDatePro: null,
                                finishDatePro: null,
                                checkedPlus: false,
                                startDatePlus: null,
                                finishDatePlus: null,
                            };

                            project.additionals.forEach((add) => {
                                users += add.hours;

                                let addObjFinal = {
                                    additional_name: add.additional_name,
                                    hours: add.hours,
                                    since_date: moment(add.since_date, "YYYY-MM-DD").format(),
                                    end_date: add.end_date ? moment(add.end_date, "YYYY-MM-DD").format() : null,
                                };

                                if (add.additional_name === "Módulo de Expenses") {
                                    totalFee += add.hours * 4;
                                    addObj.checkedExp = true;
                                    addObj.tothExp = add.hours;
                                    addObj.startDateExp = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDateExp = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Módulo de Projects") {
                                    totalFee += (add.hours - 1) * 5 + 7;
                                    addObj.checkedPro = true;
                                    addObj.tothPro = add.hours;
                                    addObj.startDatePro = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDatePro = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                } else if (add.additional_name === "Analytics Plus") {
                                    totalFee += 7;
                                    addObj.checkedPlus = true;
                                    addObj.startDatePlus = moment(add.since_date, "YYYY-MM-DD");
                                    addObj.finishDatePlus = add.end_date ? moment(add.end_date, "YYYY-MM-DD") : null;
                                }
                                additionalsFin.push(addObjFinal);
                            });

                            switch (project.project_name) {
                                case "Xero subscription":
                                    let newFee = 0;
                                    switch (project.version) {
                                        case "Ledger":
                                            newFee = 5;
                                            break;
                                        case "Cashbook":
                                            newFee = 13;
                                            break;
                                        case "Growing US":
                                            newFee = 42;
                                            break;
                                        case "Established US":
                                            newFee = 78;
                                            break;
                                        case "Standard GB":
                                            newFee = 46;
                                            break;
                                        case "Early":
                                            newFee = 15;
                                            break;
                                        default:
                                            newFee = 62;
                                            break;
                                    }

                                    xero = {
                                        isActive: true,
                                        additionals: additionalsFin,
                                        version: project.version,
                                        fee: newFee,
                                        addFee: totalFee,
                                        discount: project.discount,
                                        totalUsers: users,
                                        totalAdds: totalFee * (1 - project.discount / 100),
                                        totalAddNoDiscount: totalFee,
                                        total: (newFee + totalFee) * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    };

                                    let xeroObj = {
                                        chip: true,
                                        originalFee: newFee,
                                        addFee: totalFee,
                                        discount: project.discount,
                                        version: project.version,
                                        projectTotal: newFee * (1 - project.discount / 100),
                                        additionalsTotal: totalFee * (1 - project.discount / 100),
                                        totalFee: (newFee + totalFee) * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    };

                                    let merged = { ...xeroObj, ...addObj };

                                    setValuesSubsXero(merged);
                                    break;
                                case "Kiiper Banking subscription":
                                    let newFeeBanking = 0;

                                    switch (project.version) {
                                        case "Plan 5":
                                            newFeeBanking = 7.75;
                                            break;
                                        case "Plan 15":
                                            newFeeBanking = 19.75;
                                            break;
                                        case "Plan 30":
                                            newFeeBanking = 35.75;
                                            break;
                                        case "Plan 50":
                                            newFeeBanking = 55.75;
                                            break;
                                        case "Plan 200":
                                            newFeeBanking = 189.75;
                                            break;
                                        case "Plan 500":
                                            newFeeBanking = 425.75;
                                            break;
                                        default:
                                            break;
                                    }

                                    banking = {
                                        isActive: true,
                                        fee: newFeeBanking,
                                        version: project.version,
                                        discount: project.discount,
                                        total: newFeeBanking * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    };

                                    setValuesSubsBanking({
                                        chip: true,
                                        originalFee: newFeeBanking,
                                        discount: project.discount,
                                        version: project.version,
                                        projectTotal: newFeeBanking * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    });
                                    break;
                                case "Kiiper Taxes subscription":
                                    let newFeeTax = 0;

                                    switch (project.version) {
                                        case "Basic":
                                            newFeeTax = 20;
                                            break;
                                        default:
                                            newFeeTax = 35;
                                            break;
                                    }

                                    taxes = {
                                        isActive: true,
                                        fee: newFeeTax,
                                        version: project.version,
                                        discount: project.discount,
                                        total: newFeeTax * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    };

                                    setValuesSubsTaxes({
                                        chip: true,
                                        originalFee: newFeeTax,
                                        discount: project.discount,
                                        version: project.version,
                                        projectTotal: newFeeTax * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    });
                                    break;
                                case "Kiiper Reporting subscription":
                                    reporting = {
                                        isActive: true,
                                        fee: 10,
                                        version: project.version,
                                        discount: project.discount,
                                        total: 10 * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    };

                                    setValuesSubsReport({
                                        chip: true,
                                        originalFee: 10,
                                        discount: project.discount,
                                        version: project.version,
                                        projectTotal: 10 * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    });
                                    break;
                                default:
                                    let feeSupport = 0;

                                    feeSupport =
                                        auxProsp.country_name === "Venezuela"
                                            ? 25
                                            : latam.includes(auxProsp.country_name)
                                            ? 30
                                            : 35;

                                    support = {
                                        isActive: true,
                                        fee: feeSupport,
                                        hours: project.consulting_hours,
                                        discount: project.discount,
                                        total: feeSupport * project.consulting_hours * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    };

                                    setValuesSubsCustomer({
                                        chip: true,
                                        originalFee: feeSupport,
                                        hours: project.consulting_hours,
                                        discount: project.discount,
                                        package: project.package,
                                        version: project.version,
                                        projectTotal:
                                            feeSupport * project.consulting_hours * (1 - project.discount / 100),
                                        sinceDateProposal: project.since_date_proposal,
                                    });
                                    break;
                            }
                        });

                        if (consultingArray.length !== 0) {
                            consulting = {
                                isActive: true,
                                fee: consultFee,
                                array: consultingArray,
                            };

                            setValuesSetupCon({
                                chip: true,
                                consulOriginalFee: consultFee,
                                consulProjectTotal: totalConsult,
                                consulHours: totalHours,
                                consultingArray: consultingArray,
                            });
                        }

                        proposals.push({
                            name: "Software subscription",
                            xero: xero,
                            banking: banking,
                            taxes: taxes,
                            reporting: reporting,
                            support: support,
                            totalAll:
                                (!xero.isActive ? 0 : xero.total) +
                                (!banking.isActive ? 0 : banking.total) +
                                (!taxes.isActive ? 0 : taxes.total) +
                                (!reporting.isActive ? 0 : reporting.total) +
                                (!support.isActive ? 0 : support.total),
                            prospect: auxProsp,
                            expenses: proposal.expenses,
                            status: proposal.status,
                        });
                    }
                });

                setProposalStatus(proposal.status);
                setProposalID(proposalID);
                setExpanded(serviceName);
                setProposalDetails(proposals);
                setProposalDetailsOG(proposals);

                setOptionsGroupSend((itemState) => {
                    return itemState.map((item, j) => {
                        return {
                            ...item,
                            data:
                                item.id === 0
                                    ? proposal.status === 2
                                        ? t("miscellaneous.resend")
                                        : t("miscellaneous.send")
                                    : item.id === 1
                                    ? t("miscellaneous.copy2")
                                    : proposal.status === 2
                                    ? t("miscellaneous.reSendCopy")
                                    : t("miscellaneous.sendCopy"),
                        };
                    });
                });
                !previewEdit && proposal.status !== 1 && setIsPreview(true);
            } else {
                setIsEdit(false);
                setOptionsGroupSend((itemState) => {
                    return itemState.map((item) => {
                        if (item.id === 1)
                            return {
                                ...item,
                                disable: true,
                            };
                        return {
                            ...item,
                        };
                    });
                });
                resultT.forEach((val) => {
                    val.companies.forEach((company) => {
                        setProspectsData((prevArray) => [
                            ...prevArray,
                            {
                                company_id: company._id,
                                company_legal_name: company.company_legal_name,
                                country_name: company.country_name,
                                expense_average: company.expense_average,
                                prospect_id: val._id,
                                prospect_email: val.prospect_email,
                                prospect_name: val.prospect_name,
                                prospect_last_name: val.prospect_last_name,
                                client_type: val.client_type,
                            },
                        ]);

                        if (prospectID && companyID && val._id === prospectID && company._id === companyID) {
                            setProspectAndAverage({
                                company_id: company._id,
                                company_legal_name: company.company_legal_name,
                                country_name: company.country_name,
                                prospect_id: val._id,
                                prospect_email: val.prospect_email,
                                prospect_name: val.prospect_name,
                                prospect_last_name: val.prospect_last_name,
                                client_type: val.client_type,
                                expense_average: company.expense_average,
                            });
                        }
                    });
                });

                if (!todos.isTutorial) {
                    showMainTutorial();
                }
            }

            setBackDrop(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const calcAverageExp = (expenses, client_type) => {
        switch (expenses) {
            case 5000:
                setAverage(14);
                break;
            case 10000:
                client_type === 1 ? setAverage(9) : setAverage(28);
                break;
            case 20000:
                setAverage(18);
                break;
            case 30000:
                setAverage(27);
                break;
            case 40000:
                setAverage(36);
                break;
            case 25000:
                setAverage(42);
                break;
            case 50000:
                client_type === 1 ? setAverage(45) : setAverage(56);
                break;
            case 60000:
                setAverage(54);
                break;
            case 70000:
                setAverage(63);
                break;
            case 75000:
                setAverage(70);
                break;
            case 80000:
                setAverage(72);
                break;
            case 90000:
                setAverage(81);
                break;
            default:
                setAverage(100);
                break;
        }
    };

    const showMainTutorial = () => {
        setShowtutorial(true);
        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                nextLabel: t("miscellaneous.next"),
                prevLabel: t("miscellaneous.back"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        element: "#prospects-tutorial",
                        title: t("miscellaneous.step") + " 1:",
                        intro: t("miscellaneous.step1"),
                    },
                    {
                        element: "#slider-tutorial",
                        title: t("miscellaneous.step") + " 2:",
                        intro: t("miscellaneous.step2"),
                        position: "right",
                    },
                ],
            })
            .start();
    };

    const showSecondTutorial = () => {
        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                nextLabel: t("miscellaneous.next"),
                prevLabel: t("miscellaneous.back"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        element: "#services-tutorial",
                        title: t("miscellaneous.step") + " 3:",
                        intro: t("miscellaneous.step3"),
                        position: "right",
                    },
                ],
            })
            .start();
    };

    const showServiceTutorial = (service) => {
        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                nextLabel: t("miscellaneous.next"),
                prevLabel: t("miscellaneous.back"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        element: `#${service}`,
                        title: t("miscellaneous.step") + " 4:",
                        intro: t("miscellaneous.step4"),
                        position: "right",
                    },
                ],
            })
            .start();
    };

    const showFinalTutorial = (ids) => {
        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                nextLabel: t("miscellaneous.next"),
                prevLabel: t("miscellaneous.back"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        element: `#${ids}`,
                        title: t("miscellaneous.step") + " 5:",
                        intro: t("miscellaneous.step5"),
                        position: "top",
                    },
                    {
                        element: "#kart-tutorial",
                        title: t("miscellaneous.step") + " 6:",
                        intro: t("miscellaneous.step6"),
                        position: "left",
                    },
                ],
            })
            .onbeforeexit(async () => {
                if (!todos.isTutorial) {
                    setBackDrop(true);

                    const params = {
                        token_set_id: todos.userInfo._id,
                        language: sessionStorage.getItem("lng"),
                        date_format: todos.dateFormat,
                        amount_format: todos.amountFormat,
                        tutorial: true,
                    };

                    await fetch("/saveUserConfig", {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                        },
                        body: JSON.stringify(params),
                    })
                        .then((res) => {
                            dispatch({
                                type: "addTutorial",
                                item: true,
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    setBackDrop(false);
                }
                setShowtutorial(false);
            })
            .start();
    };

    const handleEdit = () => {
        setIsPreview(false);
        setPreviewEdit(true);
    };

    const loadInitData = async () => {
        const proposal = await fetch(`/getProposalInfo?proposal_id=${proposalID}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(error);
            });

        setCommentsNum(proposal.comments.length);
    };

    const openComments = async () => {
        setBackDrop(true);

        await getComments(proposalID);

        setDialogComment(true);

        setBackDrop(false);
    };

    const closeCommentsDialog = () => {
        setDialogComment(false);
    };

    const getComments = async (proposalID) => {
        await fetch(`/getComments?proposal_id=${proposalID}&comment_type=2`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setComments(data.all);
            });
    };

    const handleChangePreferentialFee = (event) => {
        setPreferentialFee(event.target.checked);
        event.target.checked ? setTariff(10) : setTariff(15);
    };

    const setAllAdditionals = (service) => {
        let additionals = {
            isValid: true,
            isValidHours: true,
            additionalsRecu: [],
            additionalsCleanUp: [],
            additionalsCatchUp: [],
            additionalsXero: [],
            addRecurring: {},
            addCleanUp: {},
            addCatchUp: {},
            addXero: {},
            genAdd: { ...genAddDefault },
            genAddCont: { ...genAddContDefault },
            genAddCFO: { ...genAddCFODefault },
        };

        switch (service) {
            case "accountant":
                if (valuesAccRecu.chip) {
                    if (!valuesAccRecu.initDate) {
                        additionals.isValid = false;
                    }
                    if (valuesAccRecu.checkedAcc) {
                        if (valuesAccRecu.startDateAcc) {
                            if (valuesAccRecu.tothAcc > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Accountant",
                                    hours: valuesAccRecu.tothAcc,
                                    since_date: valuesAccRecu.startDateAcc.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateAcc
                                        ? valuesAccRecu.finishDateAcc.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.addRecurring = {
                                    hours: valuesAccRecu.tothAcc,
                                    fee: valuesAccRecu.recuHourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedChecks) {
                        if (valuesAccRecu.startDateChecks) {
                            if (valuesAccRecu.tothChecks > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Checks",
                                    hours: valuesAccRecu.tothChecks,
                                    since_date: valuesAccRecu.startDateChecks.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateChecks
                                        ? valuesAccRecu.finishDateChecks.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.checks = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedAR) {
                        if (valuesAccRecu.startDateAR) {
                            if (valuesAccRecu.tothAR > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "A/R reconciliation",
                                    hours: valuesAccRecu.tothAR,
                                    since_date: valuesAccRecu.startDateAR.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateAR
                                        ? valuesAccRecu.finishDateAR.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.ar = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedAP) {
                        if (valuesAccRecu.startDateAP) {
                            if (valuesAccRecu.tothAP > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "A/P reconciliation",
                                    hours: valuesAccRecu.tothAP,
                                    since_date: valuesAccRecu.startDateAP.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateAP
                                        ? valuesAccRecu.finishDateAP.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.ap = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedClass) {
                        if (valuesAccRecu.startDateClass) {
                            if (valuesAccRecu.tothClass > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Classes/Categories accounting",
                                    hours: valuesAccRecu.tothClass,
                                    since_date: valuesAccRecu.startDateClass.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateClass
                                        ? valuesAccRecu.finishDateClass.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.classes = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedMulti) {
                        if (valuesAccRecu.startDateMulti) {
                            if (valuesAccRecu.tothMulti > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Multi-entity accounting",
                                    hours: valuesAccRecu.tothMulti,
                                    since_date: valuesAccRecu.startDateMulti.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateMulti
                                        ? valuesAccRecu.finishDateMulti.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.multi = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedPer) {
                        if (valuesAccRecu.startDatePer) {
                            if (valuesAccRecu.tothPer > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Personalized reporting",
                                    hours: valuesAccRecu.tothPer,
                                    since_date: valuesAccRecu.startDatePer.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDatePer
                                        ? valuesAccRecu.finishDatePer.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.person = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }

                    if (valuesAccRecu.checkedSales) {
                        if (valuesAccRecu.startDateSales) {
                            if (valuesAccRecu.tothSales > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Sales taxes",
                                    hours: valuesAccRecu.tothSales,
                                    since_date: valuesAccRecu.startDateSales.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateSales
                                        ? valuesAccRecu.finishDateSales.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.sales = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedOrd) {
                        if (valuesAccRecu.startDateOrd) {
                            if (valuesAccRecu.tothOrdConTax > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Ordinario",
                                    hours: valuesAccRecu.tothOrdConTax,
                                    since_date: valuesAccRecu.startDateOrd.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateOrd
                                        ? valuesAccRecu.finishDateOrd.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.ordinary = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedEsp) {
                        if (valuesAccRecu.startDateEsp) {
                            if (valuesAccRecu.tothSpeConTax > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Especial",
                                    hours: valuesAccRecu.tothSpeConTax,
                                    since_date: valuesAccRecu.startDateEsp.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateEsp
                                        ? valuesAccRecu.finishDateEsp.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.special = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedMuni) {
                        if (valuesAccRecu.startDateMuni) {
                            if (valuesAccRecu.tothMuni > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Municipal",
                                    hours: valuesAccRecu.tothMuni,
                                    since_date: valuesAccRecu.startDateMuni.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateMuni
                                        ? valuesAccRecu.finishDateMuni.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.muni = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccRecu.checkedOthers) {
                        if (valuesAccRecu.startDateOthers && valuesAccRecu.nameOthers) {
                            if (valuesAccRecu.tothOthers > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "OthersAcc",
                                    hours: valuesAccRecu.tothOthers,
                                    since_date: valuesAccRecu.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccRecu.finishDateOthers
                                        ? valuesAccRecu.finishDateOthers.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                    other_name: valuesAccRecu.nameOthers,
                                });
                                additionals.genAdd.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }

                    if (!additionals.isValid) {
                        setValuesAccRecu({ ...valuesAccRecu, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesAccRecu({ ...valuesAccRecu, isError: true });
                        } else {
                            setValuesAccRecu({ ...valuesAccRecu, isError: false });
                        }
                    }
                }

                if (valuesAccCleanUp.chip) {
                    if (!valuesAccCleanUp.sinceDate || !valuesAccCleanUp.untilDate) {
                        additionals.isValid = false;
                    }
                    if (valuesAccCleanUp.checkedAcc) {
                        if (valuesAccCleanUp.startDateAcc && valuesAccCleanUp.finishDateAcc) {
                            if (valuesAccCleanUp.tothAcc > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Accountant",
                                    hours: valuesAccCleanUp.tothAcc,
                                    since_date: valuesAccCleanUp.startDateAcc.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateAcc.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.addCleanUp = {
                                    hours: valuesAccCleanUp.tothAcc,
                                    fee: valuesAccCleanUp.hourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedChecks) {
                        if (valuesAccCleanUp.startDateChecks && valuesAccCleanUp.finishDateChecks) {
                            if (valuesAccCleanUp.tothChecks > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Checks",
                                    hours: valuesAccCleanUp.tothChecks,
                                    since_date: valuesAccCleanUp.startDateChecks.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateChecks.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.checks = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedAR) {
                        if (valuesAccCleanUp.startDateAR && valuesAccCleanUp.finishDateAR) {
                            if (valuesAccCleanUp.tothAR > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "A/R reconciliation",
                                    hours: valuesAccCleanUp.tothAR,
                                    since_date: valuesAccCleanUp.startDateAR.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateAR.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ar = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedAP) {
                        if (valuesAccCleanUp.startDateAP && valuesAccCleanUp.finishDateAP) {
                            if (valuesAccCleanUp.tothAP > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "A/P reconciliation",
                                    hours: valuesAccCleanUp.tothAP,
                                    since_date: valuesAccCleanUp.startDateAP.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateAP.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ap = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedClass) {
                        if (valuesAccCleanUp.startDateClass && valuesAccCleanUp.finishDateClass) {
                            if (valuesAccCleanUp.tothClass > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Classes/Categories accounting",
                                    hours: valuesAccCleanUp.tothClass,
                                    since_date: valuesAccCleanUp.startDateClass.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateClass.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.classes = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedMulti) {
                        if (valuesAccCleanUp.startDateMulti && valuesAccCleanUp.finishDateMulti) {
                            if (valuesAccCleanUp.tothMulti > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Multi-entity accounting",
                                    hours: valuesAccCleanUp.tothMulti,
                                    since_date: valuesAccCleanUp.startDateMulti.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateMulti.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.multi = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedPer) {
                        if (valuesAccCleanUp.startDatePer && valuesAccCleanUp.finishDatePer) {
                            if (valuesAccCleanUp.tothPer > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Personalized reporting",
                                    hours: valuesAccCleanUp.tothPer,
                                    since_date: valuesAccCleanUp.startDatePer.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDatePer.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.person = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }

                    if (valuesAccCleanUp.checkedSales) {
                        if (valuesAccCleanUp.startDateSales && valuesAccCleanUp.finishDateSales) {
                            if (valuesAccCleanUp.tothSales > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Sales taxes",
                                    hours: valuesAccCleanUp.tothSales,
                                    since_date: valuesAccCleanUp.startDateSales.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateSales.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.sales = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedOrdConTax) {
                        if (valuesAccCleanUp.startDateOrdConTax && valuesAccCleanUp.finishDateOrdConTax) {
                            if (valuesAccCleanUp.tothOrdConTax > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Ordinario",
                                    hours: valuesAccCleanUp.tothOrdConTax,
                                    since_date: valuesAccCleanUp.startDateOrdConTax.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateOrdConTax.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ordinary = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedSpeConTax) {
                        if (valuesAccCleanUp.startDateSpeConTax && valuesAccCleanUp.finishDateSpeConTax) {
                            if (valuesAccCleanUp.tothSpeConTax > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Especial",
                                    hours: valuesAccCleanUp.tothSpeConTax,
                                    since_date: valuesAccCleanUp.startDateSpeConTax.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateSpeConTax.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.special = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedMuni) {
                        if (valuesAccCleanUp.startDateMuni && valuesAccCleanUp.finishDateMuni) {
                            if (valuesAccCleanUp.tothMuni > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Municipal",
                                    hours: valuesAccCleanUp.tothMuni,
                                    since_date: valuesAccCleanUp.startDateMuni.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateMuni.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.muni = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCleanUp.checkedOthers) {
                        if (
                            valuesAccCleanUp.startDateOthers &&
                            valuesAccCleanUp.finishDateOthers &&
                            valuesAccCleanUp.nameOthers
                        ) {
                            if (valuesAccCleanUp.tothOthers > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "OthersAcc",
                                    hours: valuesAccCleanUp.tothOthers,
                                    since_date: valuesAccCleanUp.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCleanUp.finishDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    other_name: valuesAccCleanUp.nameOthers,
                                });
                                additionals.genAdd.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesAccCleanUp({ ...valuesAccCleanUp, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesAccCleanUp({ ...valuesAccCleanUp, isError: true });
                        } else {
                            setValuesAccCleanUp({ ...valuesAccCleanUp, isError: false });
                        }
                    }
                }

                if (valuesAccCatchUp.chip) {
                    if (!valuesAccCatchUp.sinceDate || !valuesAccCatchUp.untilDate) {
                        additionals.isValid = false;
                    }
                    if (valuesAccCatchUp.checkedAcc) {
                        if (valuesAccCatchUp.startDateAcc && valuesAccCatchUp.finishDateAcc) {
                            if (valuesAccCatchUp.tothAcc > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Accountant",
                                    hours: valuesAccCatchUp.tothAcc,
                                    since_date: valuesAccCatchUp.startDateAcc.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateAcc.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.addCatchUp = {
                                    hours: valuesAccCatchUp.tothAcc,
                                    fee: valuesAccCatchUp.hourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedChecks) {
                        if (valuesAccCatchUp.startDateChecks && valuesAccCatchUp.finishDateChecks) {
                            if (valuesAccCatchUp.tothChecks > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Checks",
                                    hours: valuesAccCatchUp.tothChecks,
                                    since_date: valuesAccCatchUp.startDateChecks.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateChecks.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.checks = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedAR) {
                        if (valuesAccCatchUp.startDateAR && valuesAccCatchUp.finishDateAR) {
                            if (valuesAccCatchUp.tothAR > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "A/R reconciliation",
                                    hours: valuesAccCatchUp.tothAR,
                                    since_date: valuesAccCatchUp.startDateAR.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateAR.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ar = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedAP) {
                        if (valuesAccCatchUp.startDateAP && valuesAccCatchUp.finishDateAP) {
                            if (valuesAccCatchUp.tothAP > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "A/P reconciliation",
                                    hours: valuesAccCatchUp.tothAP,
                                    since_date: valuesAccCatchUp.startDateAP.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateAP.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ap = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedClass) {
                        if (valuesAccCatchUp.startDateClass && valuesAccCatchUp.finishDateClass) {
                            if (valuesAccCatchUp.tothClass > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Classes/Categories accounting",
                                    hours: valuesAccCatchUp.tothClass,
                                    since_date: valuesAccCatchUp.startDateClass.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateClass.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.classes = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedMulti) {
                        if (valuesAccCatchUp.startDateMulti && valuesAccCatchUp.finishDateMulti) {
                            if (valuesAccCatchUp.tothMulti > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Multi-entity accounting",
                                    hours: valuesAccCatchUp.tothMulti,
                                    since_date: valuesAccCatchUp.startDateMulti.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateMulti.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.multi = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedPer) {
                        if (valuesAccCatchUp.startDatePer && valuesAccCatchUp.finishDatePer) {
                            if (valuesAccCatchUp.tothPer > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Personalized reporting",
                                    hours: valuesAccCatchUp.tothPer,
                                    since_date: valuesAccCatchUp.startDatePer.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDatePer.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.person = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedSales) {
                        if (valuesAccCatchUp.startDateSales && valuesAccCatchUp.finishDateSales) {
                            if (valuesAccCatchUp.tothSales > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Sales taxes",
                                    hours: valuesAccCatchUp.tothSales,
                                    since_date: valuesAccCatchUp.startDateSales.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateSales.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.sales = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedOrdConTax) {
                        if (valuesAccCatchUp.startDateOrdConTax && valuesAccCatchUp.finishDateOrdConTax) {
                            if (valuesAccCatchUp.tothOrdConTax > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Ordinario",
                                    hours: valuesAccCatchUp.tothOrdConTax,
                                    since_date: valuesAccCatchUp.startDateOrdConTax.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateOrdConTax.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ordinary = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedSpeConTax) {
                        if (valuesAccCatchUp.startDateSpeConTax && valuesAccCatchUp.finishDateSpeConTax) {
                            if (valuesAccCatchUp.tothSpeConTax > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Especial",
                                    hours: valuesAccCatchUp.tothSpeConTax,
                                    since_date: valuesAccCatchUp.startDateSpeConTax.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateSpeConTax.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.special = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedMuni) {
                        if (valuesAccCatchUp.startDateMuni && valuesAccCatchUp.finishDateMuni) {
                            if (valuesAccCatchUp.tothMuni > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Municipal",
                                    hours: valuesAccCatchUp.tothMuni,
                                    since_date: valuesAccCatchUp.startDateMuni.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateMuni.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.muni = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesAccCatchUp.checkedOthers) {
                        if (
                            valuesAccCatchUp.startDateOthers &&
                            valuesAccCatchUp.finishDateOthers &&
                            valuesAccCatchUp.nameOthers
                        ) {
                            if (valuesAccCatchUp.tothOthers > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "OthersAcc",
                                    hours: valuesAccCatchUp.tothOthers,
                                    since_date: valuesAccCatchUp.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesAccCatchUp.finishDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    other_name: valuesAccCatchUp.nameOthers,
                                });
                                additionals.genAdd.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesAccCatchUp({ ...valuesAccCatchUp, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesAccCatchUp({ ...valuesAccCatchUp, isError: true });
                        } else {
                            setValuesAccCatchUp({ ...valuesAccCatchUp, isError: false });
                        }
                    }
                }

                if (valuesAccOnboard.chip) {
                    if (!valuesAccOnboard.meetingDate) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesAccOnboard({ ...valuesAccOnboard, isError: true });
                    } else {
                        setValuesAccOnboard({ ...valuesAccOnboard, isError: false });
                    }
                }

                if (valuesAccConsul.chip) {
                    valuesAccConsul.consultingArray.forEach((item) => {
                        if (!item.description || item.hours === 0) {
                            additionals.isValid = false;
                        }
                    });
                    if (!additionals.isValid) {
                        setValuesAccConsul({ ...valuesAccConsul, isError: true });
                    } else {
                        setValuesAccConsul({ ...valuesAccConsul, isError: false });
                    }
                }
                break;
            case "bookkeeper":
                if (valuesBookRecu.chip) {
                    if (!valuesBookRecu.initDate) {
                        additionals.isValid = false;
                    }
                    if (valuesBookRecu.checkedBook) {
                        if (valuesBookRecu.startDateBook) {
                            if (valuesBookRecu.tothBook > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Bookkeeper",
                                    hours: valuesBookRecu.tothBook,
                                    since_date: valuesBookRecu.startDateBook.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateBook
                                        ? valuesBookRecu.finishDateBook.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.addRecurring = {
                                    hours: valuesBookRecu.tothBook,
                                    fee: valuesBookRecu.recuHourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedChecks) {
                        if (valuesBookRecu.startDateChecks) {
                            if (valuesBookRecu.tothChecks > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Checks",
                                    hours: valuesBookRecu.tothChecks,
                                    since_date: valuesBookRecu.startDateChecks.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateChecks
                                        ? valuesBookRecu.finishDateChecks.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.checks = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedAR) {
                        if (valuesBookRecu.startDateAR) {
                            if (valuesBookRecu.tothAR > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "A/R",
                                    hours: valuesBookRecu.tothAR,
                                    since_date: valuesBookRecu.startDateAR.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateAR
                                        ? valuesBookRecu.finishDateAR.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.ar = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedAP) {
                        if (valuesBookRecu.startDateAP) {
                            if (valuesBookRecu.tothAP > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "A/P",
                                    hours: valuesBookRecu.tothAP,
                                    since_date: valuesBookRecu.startDateAP.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateAP
                                        ? valuesBookRecu.finishDateAP.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.ap = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedAccrual) {
                        if (valuesBookRecu.startDateAccrual) {
                            if (valuesBookRecu.tothAccrual > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Accrual",
                                    hours: valuesBookRecu.tothAccrual,
                                    since_date: valuesBookRecu.startDateAccrual.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateAccrual
                                        ? valuesBookRecu.finishDateAccrual.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.accrual = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedClass) {
                        if (valuesBookRecu.startDateClass) {
                            if (valuesBookRecu.tothClass > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Classes/Categories accounting",
                                    hours: valuesBookRecu.tothClass,
                                    since_date: valuesBookRecu.startDateClass.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateClass
                                        ? valuesBookRecu.finishDateClass.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.classes = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedMulti) {
                        if (valuesBookRecu.startDateMulti) {
                            if (valuesBookRecu.tothMulti > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Multi-entity accounting",
                                    hours: valuesBookRecu.tothMulti,
                                    since_date: valuesBookRecu.startDateMulti.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateMulti
                                        ? valuesBookRecu.finishDateMulti.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.multi = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedPer) {
                        if (valuesBookRecu.startDatePer) {
                            if (valuesBookRecu.tothPer > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Personalized reporting",
                                    hours: valuesBookRecu.tothPer,
                                    since_date: valuesBookRecu.startDatePer.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDatePer
                                        ? valuesBookRecu.finishDatePer.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.person = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookRecu.checkedOthers) {
                        if (valuesBookRecu.startDateOthers && valuesBookRecu.nameOthers) {
                            if (valuesBookRecu.tothOthers > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Others",
                                    hours: valuesBookRecu.tothOthers,
                                    since_date: valuesBookRecu.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookRecu.finishDateOthers
                                        ? valuesBookRecu.finishDateOthers.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                    other_name: valuesBookRecu.nameOthers,
                                });
                                additionals.genAdd.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesBookRecu({ ...valuesBookRecu, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesBookRecu({ ...valuesBookRecu, isError: true });
                        } else {
                            setValuesBookRecu({ ...valuesBookRecu, isError: false });
                        }
                    }
                }

                if (valuesBookCleanUp.chip) {
                    if (!valuesBookCleanUp.sinceDate || !valuesBookCleanUp.untilDate) {
                        additionals.isValid = false;
                    }
                    if (valuesBookCleanUp.checkedBook) {
                        if (valuesBookCleanUp.startDateBook && valuesBookCleanUp.finishDateBook) {
                            if (valuesBookCleanUp.tothBook > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Bookkeeper",
                                    hours: valuesBookCleanUp.tothBook,
                                    since_date: valuesBookCleanUp.startDateBook.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateBook.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.addCleanUp = {
                                    hours: valuesBookCleanUp.tothBook,
                                    fee: valuesBookCleanUp.hourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedChecks) {
                        if (valuesBookCleanUp.startDateChecks && valuesBookCleanUp.finishDateChecks) {
                            if (valuesBookCleanUp.tothChecks > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Checks",
                                    hours: valuesBookCleanUp.tothChecks,
                                    since_date: valuesBookCleanUp.startDateChecks.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateChecks.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.checks = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedAR) {
                        if (valuesBookCleanUp.startDateAR && valuesBookCleanUp.finishDateAR) {
                            if (valuesBookCleanUp.tothAR > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "A/R",
                                    hours: valuesBookCleanUp.tothAR,
                                    since_date: valuesBookCleanUp.startDateAR.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateAR.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ar = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedAP) {
                        if (valuesBookCleanUp.startDateAP && valuesBookCleanUp.finishDateAP) {
                            if (valuesBookCleanUp.tothAP > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "A/P",
                                    hours: valuesBookCleanUp.tothAP,
                                    since_date: valuesBookCleanUp.startDateAP.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateAP.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ap = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedAccrual) {
                        if (valuesBookCleanUp.startDateAccrual) {
                            if (valuesBookCleanUp.tothAccrual > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Accrual",
                                    hours: valuesBookCleanUp.tothAccrual,
                                    since_date: valuesBookCleanUp.startDateAccrual.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateAccrual
                                        ? valuesBookCleanUp.finishDateAccrual.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.accrual = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedClass) {
                        if (valuesBookCleanUp.startDateClass && valuesBookCleanUp.finishDateClass) {
                            if (valuesBookCleanUp.tothClass > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Classes/Categories accounting",
                                    hours: valuesBookCleanUp.tothClass,
                                    since_date: valuesBookCleanUp.startDateClass.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateClass.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.classes = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedMulti) {
                        if (valuesBookCleanUp.startDateMulti && valuesBookCleanUp.finishDateMulti) {
                            if (valuesBookCleanUp.tothMulti > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Multi-entity accounting",
                                    hours: valuesBookCleanUp.tothMulti,
                                    since_date: valuesBookCleanUp.startDateMulti.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateMulti.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.multi = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedPer) {
                        if (valuesBookCleanUp.startDatePer && valuesBookCleanUp.finishDatePer) {
                            if (valuesBookCleanUp.tothPer > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Personalized reporting",
                                    hours: valuesBookCleanUp.tothPer,
                                    since_date: valuesBookCleanUp.startDatePer.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDatePer.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.person = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCleanUp.checkedOthers) {
                        if (
                            valuesBookCleanUp.startDateOthers &&
                            valuesBookCleanUp.finishDateOthers &&
                            valuesBookCleanUp.nameOthers
                        ) {
                            if (valuesBookCleanUp.tothOthers > 0) {
                                additionals.additionalsCleanUp.push({
                                    additional_name: "Others",
                                    hours: valuesBookCleanUp.tothOthers,
                                    since_date: valuesBookCleanUp.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCleanUp.finishDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    other_name: valuesBookCleanUp.nameOthers,
                                });
                                additionals.genAdd.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesBookCleanUp({ ...valuesBookCleanUp, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesBookCleanUp({ ...valuesBookCleanUp, isError: true });
                        } else {
                            setValuesBookCleanUp({ ...valuesBookCleanUp, isError: false });
                        }
                    }
                }

                if (valuesBookCatchUp.chip) {
                    if (!valuesBookCatchUp.sinceDate || !valuesBookCatchUp.untilDate) {
                        additionals.isValid = false;
                    }
                    if (valuesBookCatchUp.checkedBook) {
                        if (valuesBookCatchUp.startDateBook && valuesBookCatchUp.finishDateBook) {
                            if (valuesBookCatchUp.tothBook > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Bookkeeper",
                                    hours: valuesBookCatchUp.tothBook,
                                    since_date: valuesBookCatchUp.startDateBook.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateBook.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.addCatchUp = {
                                    hours: valuesBookCatchUp.tothBook,
                                    fee: valuesBookCatchUp.hourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedChecks) {
                        if (valuesBookCatchUp.startDateChecks && valuesBookCatchUp.finishDateChecks) {
                            if (valuesBookCatchUp.tothChecks > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Checks",
                                    hours: valuesBookCatchUp.tothChecks,
                                    since_date: valuesBookCatchUp.startDateChecks.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateChecks.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.checks = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedAR) {
                        if (valuesBookCatchUp.startDateAR && valuesBookCatchUp.finishDateAR) {
                            if (valuesBookCatchUp.tothAR > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "A/R",
                                    hours: valuesBookCatchUp.tothAR,
                                    since_date: valuesBookCatchUp.startDateAR.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateAR.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ar = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedAP) {
                        if (valuesBookCatchUp.startDateAP && valuesBookCatchUp.finishDateAP) {
                            if (valuesBookCatchUp.tothAP > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "A/P",
                                    hours: valuesBookCatchUp.tothAP,
                                    since_date: valuesBookCatchUp.startDateAP.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateAP.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.ap = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedAccrual) {
                        if (valuesBookCatchUp.startDateAccrual && valuesBookCatchUp.finishDateAccrual) {
                            if (valuesBookCatchUp.tothAccrual > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Accrual",
                                    hours: valuesBookCatchUp.tothAccrual,
                                    since_date: valuesBookCatchUp.startDateAccrual.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateAccrual
                                        ? valuesBookCatchUp.finishDateAccrual.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAdd.accrual = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedClass) {
                        if (valuesBookCatchUp.startDateClass && valuesBookCatchUp.finishDateClass) {
                            if (valuesBookCatchUp.tothClass > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Classes/Categories accounting",
                                    hours: valuesBookCatchUp.tothClass,
                                    since_date: valuesBookCatchUp.startDateClass.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateClass.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.classes = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedMulti) {
                        if (valuesBookCatchUp.startDateMulti && valuesBookCatchUp.finishDateMulti) {
                            if (valuesBookCatchUp.tothMulti > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Multi-entity accounting",
                                    hours: valuesBookCatchUp.tothMulti,
                                    since_date: valuesBookCatchUp.startDateMulti.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateMulti.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.multi = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedPer) {
                        if (valuesBookCatchUp.startDatePer && valuesBookCatchUp.finishDatePer) {
                            if (valuesBookCatchUp.tothPer > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Personalized reporting",
                                    hours: valuesBookCatchUp.tothPer,
                                    since_date: valuesBookCatchUp.startDatePer.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDatePer.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAdd.person = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesBookCatchUp.checkedOthers) {
                        if (
                            valuesBookCatchUp.startDateOthers &&
                            valuesBookCatchUp.finishDateOthers &&
                            valuesBookCatchUp.nameOthers
                        ) {
                            if (valuesBookCatchUp.tothOthers > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Others",
                                    hours: valuesBookCatchUp.tothOthers,
                                    since_date: valuesBookCatchUp.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesBookCatchUp.finishDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    other_name: valuesBookCatchUp.nameOthers,
                                });
                                additionals.genAdd.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesBookCatchUp({ ...valuesBookCatchUp, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesBookCatchUp({ ...valuesBookCatchUp, isError: true });
                        } else {
                            setValuesBookCatchUp({ ...valuesBookCatchUp, isError: false });
                        }
                    }
                }

                if (valuesBookOnboard.chip) {
                    if (!valuesBookOnboard.meetingDate) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesBookOnboard({ ...valuesBookOnboard, isError: true });
                    } else {
                        setValuesBookOnboard({ ...valuesBookOnboard, isError: false });
                    }
                }

                if (valuesBookConsul.chip) {
                    valuesBookConsul.consultingArray.forEach((item) => {
                        if (!item.description || item.hours === 0) {
                            additionals.isValid = false;
                        }
                    });
                    if (!additionals.isValid) {
                        setValuesBookConsul({ ...valuesBookConsul, isError: true });
                    } else {
                        setValuesBookConsul({ ...valuesBookConsul, isError: false });
                    }
                }
                break;
            case "controller":
                if (valuesContRecu.chip) {
                    if (!valuesContRecu.initDate) {
                        additionals.isValid = false;
                    }
                    if (valuesContRecu.checkedCont) {
                        if (valuesContRecu.startDateCont) {
                            if (valuesContRecu.tothCont > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Controller",
                                    hours: valuesContRecu.tothCont,
                                    since_date: valuesContRecu.startDateCont.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContRecu.finishDateCont
                                        ? valuesContRecu.finishDateCont.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.addRecurring = {
                                    hours: valuesContRecu.tothCont,
                                    fee: valuesContRecu.recuHourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContRecu.checkedExe) {
                        if (valuesContRecu.startDateExe) {
                            if (valuesContRecu.tothExe > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Ejecución de planificación fiscal",
                                    hours: valuesContRecu.tothExe,
                                    since_date: valuesContRecu.startDateExe.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContRecu.finishDateExe
                                        ? valuesContRecu.finishDateExe.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAddCont.exe = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContRecu.checkedCons) {
                        if (valuesContRecu.startDateCons) {
                            if (valuesContRecu.tothCons > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Planificación de administración y contabilidad",
                                    hours: valuesContRecu.tothCons,
                                    since_date: valuesContRecu.startDateCons.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContRecu.finishDateCons
                                        ? valuesContRecu.finishDateCons.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAddCont.cons = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContRecu.checkedRep) {
                        if (valuesContRecu.startDateRep) {
                            if (valuesContRecu.tothRep > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Reportes para auditores externos",
                                    hours: valuesContRecu.tothRep,
                                    since_date: valuesContRecu.startDateRep.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContRecu.finishDateRep
                                        ? valuesContRecu.finishDateRep.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAddCont.rep = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContRecu.checkedOthers) {
                        if (valuesContRecu.startDateOthers && valuesContRecu.nameOthers) {
                            if (valuesContRecu.tothOthers > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Others",
                                    hours: valuesContRecu.tothOthers,
                                    since_date: valuesContRecu.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContRecu.finishDateOthers
                                        ? valuesContRecu.finishDateOthers.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                    other_name: valuesContRecu.nameOthers,
                                });
                                additionals.genAddCont.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesContRecu({ ...valuesContRecu, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesContRecu({ ...valuesContRecu, isError: true });
                        } else {
                            setValuesContRecu({ ...valuesContRecu, isError: false });
                        }
                    }
                }

                if (valuesContCatchUp.chip) {
                    if (!valuesContCatchUp.sinceDate || !valuesContCatchUp.untilDate) {
                        additionals.isValid = false;
                    }
                    if (valuesContCatchUp.checkedCont) {
                        if (valuesContCatchUp.startDateCont && valuesContCatchUp.finishDateCont) {
                            if (valuesContCatchUp.tothCont > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Controller",
                                    hours: valuesContCatchUp.tothCont,
                                    since_date: valuesContCatchUp.startDateCont.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContCatchUp.finishDateCont.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.addCatchUp = {
                                    hours: valuesContCatchUp.tothCont,
                                    fee: valuesContCatchUp.hourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContCatchUp.checkedExe) {
                        if (valuesContCatchUp.startDateExe && valuesContCatchUp.finishDateExe) {
                            if (valuesContCatchUp.tothExe > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Ejecución de planificación fiscal",
                                    hours: valuesContCatchUp.tothExe,
                                    since_date: valuesContCatchUp.startDateExe.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContCatchUp.finishDateExe.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAddCont.exe = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContCatchUp.checkedCons) {
                        if (valuesContCatchUp.startDateCons && valuesContCatchUp.finishDateCons) {
                            if (valuesContCatchUp.tothCons > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Planificación de administración y contabilidad",
                                    hours: valuesContCatchUp.tothCons,
                                    since_date: valuesContCatchUp.startDateCons.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContCatchUp.finishDateCons.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAddCont.cons = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContCatchUp.checkedRep) {
                        if (valuesContCatchUp.startDateRep && valuesContCatchUp.finishDateRep) {
                            if (valuesContCatchUp.tothRep > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Reportes para auditores externos",
                                    hours: valuesContCatchUp.tothRep,
                                    since_date: valuesContCatchUp.startDateRep.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContCatchUp.finishDateRep.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAddCont.rep = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesContCatchUp.checkedOthers) {
                        if (
                            valuesContCatchUp.startDateOthers &&
                            valuesContCatchUp.finishDateOthers &&
                            valuesContCatchUp.nameOthers
                        ) {
                            if (valuesContCatchUp.tothOthers > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Others",
                                    hours: valuesContCatchUp.tothOthers,
                                    since_date: valuesContCatchUp.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesContCatchUp.finishDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    other_name: valuesContCatchUp.nameOthers,
                                });
                                additionals.genAddCont.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesContCatchUp({ ...valuesContCatchUp, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesContCatchUp({ ...valuesContCatchUp, isError: true });
                        } else {
                            setValuesContCatchUp({ ...valuesContCatchUp, isError: false });
                        }
                    }
                }

                if (valuesContOnboard.chip) {
                    if (!valuesContOnboard.meetingDate) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesContOnboard({ ...valuesContOnboard, isError: true });
                    } else {
                        setValuesContOnboard({ ...valuesContOnboard, isError: false });
                    }
                }

                if (valuesContConsul.chip) {
                    valuesContConsul.consultingArray.forEach((item) => {
                        if (!item.description || item.hours === 0) {
                            additionals.isValid = false;
                        }
                    });
                    if (!additionals.isValid) {
                        setValuesContConsul({ ...valuesContConsul, isError: true });
                    } else {
                        setValuesContConsul({ ...valuesContConsul, isError: false });
                    }
                }
                break;
            case "cfo":
                if (valuesCFORecu.chip) {
                    if (!valuesCFORecu.initDate) {
                        additionals.isValid = false;
                    }
                    if (valuesCFORecu.checkedCFO) {
                        if (valuesCFORecu.startDateCFO) {
                            if (valuesCFORecu.tothCFO > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "CFO",
                                    hours: valuesCFORecu.tothCFO,
                                    since_date: valuesCFORecu.startDateCFO.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFORecu.finishDateCFO
                                        ? valuesCFORecu.finishDateCFO.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.addRecurring = {
                                    hours: valuesCFORecu.tothCFO,
                                    fee: valuesCFORecu.recuHourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFORecu.checkedGob) {
                        if (valuesCFORecu.startDateGob) {
                            if (valuesCFORecu.tothGob > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Gobierno corporativo",
                                    hours: valuesCFORecu.tothGob,
                                    since_date: valuesCFORecu.startDateGob.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFORecu.finishDateGob
                                        ? valuesCFORecu.finishDateGob.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAddCFO.gob = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFORecu.checkedPres) {
                        if (valuesCFORecu.startDatePres) {
                            if (valuesCFORecu.tothPres > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Presentaciones financieras",
                                    hours: valuesCFORecu.tothPres,
                                    since_date: valuesCFORecu.startDatePres.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFORecu.finishDatePres
                                        ? valuesCFORecu.finishDatePres.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAddCFO.pres = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFORecu.checkedRep) {
                        if (valuesCFORecu.startDateRep) {
                            if (valuesCFORecu.tothRep > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Reportes para inversionistas",
                                    hours: valuesCFORecu.tothRep,
                                    since_date: valuesCFORecu.startDateRep.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFORecu.finishDateRep
                                        ? valuesCFORecu.finishDateRep.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAddCFO.repInv = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFORecu.checkedOthers) {
                        if (valuesCFORecu.startDateOthers && valuesCFORecu.nameOthers) {
                            if (valuesCFORecu.tothOthers > 0) {
                                additionals.additionalsRecu.push({
                                    additional_name: "Others",
                                    hours: valuesCFORecu.tothOthers,
                                    since_date: valuesCFORecu.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFORecu.finishDateOthers
                                        ? valuesCFORecu.finishDateOthers.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                    other_name: valuesCFORecu.nameOthers,
                                });
                                additionals.genAddCFO.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesCFORecu({ ...valuesCFORecu, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesCFORecu({ ...valuesCFORecu, isError: true });
                        } else {
                            setValuesCFORecu({ ...valuesCFORecu, isError: false });
                        }
                    }
                }

                if (valuesCFOCatchUp.chip) {
                    if (!valuesCFOCatchUp.sinceDate || !valuesCFOCatchUp.untilDate) {
                        additionals.isValid = false;
                    }
                    if (valuesCFOCatchUp.checkedCFO) {
                        if (valuesCFOCatchUp.startDateCFO && valuesCFOCatchUp.finishDateCFO) {
                            if (valuesCFOCatchUp.tothCFO > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "CFO",
                                    hours: valuesCFOCatchUp.tothCFO,
                                    since_date: valuesCFOCatchUp.startDateCFO.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFOCatchUp.finishDateCFO.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.addCatchUp = {
                                    hours: valuesCFOCatchUp.tothCFO,
                                    fee: valuesCFOCatchUp.hourRate,
                                };
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFOCatchUp.checkedGob) {
                        if (valuesCFOCatchUp.startDateGob && valuesCFOCatchUp.finishDateGob) {
                            if (valuesCFOCatchUp.tothGob > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Gobierno corporativo",
                                    hours: valuesCFOCatchUp.tothGob,
                                    since_date: valuesCFOCatchUp.startDateGob.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFOCatchUp.finishDateGob.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAddCFO.gob = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFOCatchUp.checkedPres) {
                        if (valuesCFOCatchUp.startDatePres && valuesCFOCatchUp.finishDatePres) {
                            if (valuesCFOCatchUp.tothPres > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Presentaciones financieras",
                                    hours: valuesCFOCatchUp.tothPres,
                                    since_date: valuesCFOCatchUp.startDatePres.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFOCatchUp.finishDatePres.startOf("day").format("YYYY-MM-DD"),
                                });
                                additionals.genAddCFO.pres = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFOCatchUp.checkedRep) {
                        if (valuesCFOCatchUp.startDateRep) {
                            if (valuesCFOCatchUp.tothRep > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Reportes para inversionistas",
                                    hours: valuesCFOCatchUp.tothRep,
                                    since_date: valuesCFOCatchUp.startDateRep.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFOCatchUp.finishDateRep
                                        ? valuesCFOCatchUp.finishDateRep.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                                additionals.genAddCFO.repInv = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesCFOCatchUp.checkedOthers) {
                        if (
                            valuesCFOCatchUp.startDateOthers &&
                            valuesCFOCatchUp.finishDateOthers &&
                            valuesCFOCatchUp.nameOthers
                        ) {
                            if (valuesCFOCatchUp.tothOthers > 0) {
                                additionals.additionalsCatchUp.push({
                                    additional_name: "Others",
                                    hours: valuesCFOCatchUp.tothOthers,
                                    since_date: valuesCFOCatchUp.startDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesCFOCatchUp.finishDateOthers.startOf("day").format("YYYY-MM-DD"),
                                    other_name: valuesCFOCatchUp.nameOthers,
                                });
                                additionals.genAddCFO.other = true;
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesCFOCatchUp({ ...valuesCFOCatchUp, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesCFOCatchUp({ ...valuesCFOCatchUp, isError: true });
                        } else {
                            setValuesCFOCatchUp({ ...valuesCFOCatchUp, isError: false });
                        }
                    }
                }

                if (valuesCFOOnboard.chip) {
                    if (!valuesCFOOnboard.meetingDate) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesCFOOnboard({ ...valuesCFOOnboard, isError: true });
                    } else {
                        setValuesCFOOnboard({ ...valuesCFOOnboard, isError: false });
                    }
                }

                if (valuesCFOConsul.chip) {
                    valuesCFOConsul.consultingArray.forEach((item) => {
                        if (!item.description || item.hours === 0) {
                            additionals.isValid = false;
                        }
                    });
                    if (!additionals.isValid) {
                        setValuesCFOConsul({ ...valuesCFOConsul, isError: true });
                    } else {
                        setValuesCFOConsul({ ...valuesCFOConsul, isError: false });
                    }
                }
                break;
            case "softSetup":
                if (valuesSetupXero.chip) {
                    if (
                        !valuesSetupXero.package ||
                        !valuesSetupXero.version ||
                        !valuesSetupXero.commitmentDateProposal
                    ) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSetupXero({ ...valuesSetupXero, isError: true });
                    } else {
                        setValuesSetupXero({ ...valuesSetupXero, isError: false });
                    }
                }

                if (valuesSetupBanking.chip) {
                    if (
                        !valuesSetupBanking.package ||
                        !valuesSetupBanking.version ||
                        !valuesSetupBanking.commitmentDateProposal
                    ) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSetupBanking({ ...valuesSetupBanking, isError: true });
                    } else {
                        setValuesSetupBanking({ ...valuesSetupBanking, isError: false });
                    }
                }

                if (valuesSetupReport.chip) {
                    if (
                        !valuesSetupReport.package ||
                        !valuesSetupReport.version ||
                        !valuesSetupReport.commitmentDateProposal
                    ) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSetupReport({ ...valuesSetupReport, isError: true });
                    } else {
                        setValuesSetupReport({ ...valuesSetupReport, isError: false });
                    }
                }

                if (valuesSetupTaxes.chip) {
                    if (
                        !valuesSetupTaxes.package ||
                        !valuesSetupTaxes.version ||
                        !valuesSetupTaxes.commitmentDateProposal
                    ) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSetupTaxes({ ...valuesSetupTaxes, isError: true });
                    } else {
                        setValuesSetupTaxes({ ...valuesSetupTaxes, isError: false });
                    }
                }

                if (valuesSetupOther.chip) {
                    if (
                        !valuesSetupOther.package ||
                        !valuesSetupOther.software ||
                        !valuesSetupOther.commitmentDateProposal
                    ) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSetupOther({ ...valuesSetupOther, isError: true });
                    } else {
                        setValuesSetupOther({ ...valuesSetupOther, isError: false });
                    }
                }

                if (valuesSetupCon.chip) {
                    valuesSetupCon.consultingArray.forEach((item) => {
                        if (!item.description || item.hours === 0) {
                            additionals.isValid = false;
                        }
                    });
                    if (!additionals.isValid) {
                        setValuesSetupCon({ ...valuesSetupCon, isError: true });
                    } else {
                        setValuesSetupCon({ ...valuesSetupCon, isError: false });
                    }
                }
                break;
            case "softSubs":
                if (valuesSubsXero.chip) {
                    if (!valuesSubsXero.version || !valuesSubsXero.sinceDateProposal) {
                        additionals.isValid = false;
                    }
                    if (valuesSubsXero.checkedExp) {
                        if (valuesSubsXero.startDateExp) {
                            if (valuesSubsXero.tothExp > 0) {
                                additionals.additionalsXero.push({
                                    additional_name: "Módulo de Expenses",
                                    hours: valuesSubsXero.tothExp,
                                    since_date: valuesSubsXero.startDateExp.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesSubsXero.finishDateExp
                                        ? valuesSubsXero.finishDateExp.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesSubsXero.checkedPro) {
                        if (valuesSubsXero.startDatePro) {
                            if (valuesSubsXero.tothPro > 0) {
                                additionals.additionalsXero.push({
                                    additional_name: "Módulo de Projects",
                                    hours: valuesSubsXero.tothPro,
                                    since_date: valuesSubsXero.startDatePro.startOf("day").format("YYYY-MM-DD"),
                                    end_date: valuesSubsXero.finishDatePro
                                        ? valuesSubsXero.finishDatePro.startOf("day").format("YYYY-MM-DD")
                                        : null,
                                });
                            } else {
                                additionals.isValidHours = false;
                            }
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (valuesSubsXero.checkedPlus) {
                        if (valuesSubsXero.startDatePlus) {
                            additionals.additionalsXero.push({
                                additional_name: "Analytics Plus",
                                hours: 7,
                                since_date: valuesSubsXero.startDatePlus.startOf("day").format("YYYY-MM-DD"),
                                end_date: valuesSubsXero.finishDatePlus
                                    ? valuesSubsXero.finishDatePlus.startOf("day").format("YYYY-MM-DD")
                                    : null,
                            });
                        } else {
                            additionals.isValid = false;
                        }
                    }
                    if (!additionals.isValid) {
                        setValuesSubsXero({ ...valuesSubsXero, isError: true });
                    } else {
                        if (!additionals.isValidHours) {
                            setValuesSubsXero({ ...valuesSubsXero, isError: true });
                        } else {
                            setValuesSubsXero({ ...valuesSubsXero, isError: false });
                        }
                    }
                }

                if (valuesSubsBanking.chip) {
                    if (!valuesSubsBanking.version || !valuesSubsBanking.sinceDateProposal) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSubsBanking({ ...valuesSubsBanking, isError: true });
                    } else {
                        setValuesSubsBanking({ ...valuesSubsBanking, isError: false });
                    }
                }

                if (valuesSubsReport.chip) {
                    if (!valuesSubsReport.version || !valuesSubsReport.sinceDateProposal) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSubsReport({ ...valuesSubsReport, isError: true });
                    } else {
                        setValuesSubsReport({ ...valuesSubsReport, isError: false });
                    }
                }

                if (valuesSubsTaxes.chip) {
                    if (!valuesSubsTaxes.version || !valuesSubsTaxes.sinceDateProposal) {
                        additionals.isValid = false;
                    }
                    if (!additionals.isValid) {
                        setValuesSubsTaxes({ ...valuesSubsTaxes, isError: true });
                    } else {
                        setValuesSubsTaxes({ ...valuesSubsTaxes, isError: false });
                    }
                }
                break;
            default:
                break;
        }

        return additionals;
    };

    const duplicate = async (redirect = true, _proposal = {}, defaultMsg = true) => {
        setBackDrop(true);
        await fetch(`/duplicateProposal?proposal_id=${_proposal._id ?? proposalID}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (defaultMsg) {
                    setTexto(t("miscellaneous.successCopyProposal") + " " + data.message);
                    setAutoAlertType("success");
                    setShow(true);
                } else {
                    if (!isEdit) {
                        setTexto(
                            t("miscellaneous.successSendCopyProposal1")
                                .replace("XXX", data.beforeProposalReference)
                                .replace("XXXX", data.message)
                        );
                    } else {
                        setTexto(
                            t("miscellaneous.successSendCopyProposal2")
                                .replace("XXX", data.beforeProposalReference)
                                .replace("XXXX", data.message)
                        );
                    }
                    setAutoAlertType("success");
                    setShow(true);
                }

                if (redirect) {
                    setTimeout(() => {
                        navigate("/proposals/manageProposals");
                    }, 3000);
                }
            });

        setBackDrop(false);
    };

    const sendProposal = async (value, redirect = true, defaultMsg = true, isSend = false) => {
        let servicesInfo = [];
        let isValidGen = true;

        setBackDrop(true);

        services.forEach((item) => {
            if (item.isActive) {
                switch (item.name) {
                    case "accountant":
                        let projectsAcc = [];
                        let additionalsAcc = setAllAdditionals(item.name);

                        if (additionalsAcc.isValid) {
                            if (additionalsAcc.isValidHours) {
                                if (valuesAccOnboard.chip) {
                                    projectsAcc.push({
                                        project_name: "Accountant onboarding",
                                        since_date: moment().startOf("day").format("YYYY-MM-DD"),
                                        meetingDate: valuesAccOnboard.meetingDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesAccOnboard.onboardDiscount,
                                        description: valuesAccOnboard.description,
                                    });
                                }
                                if (valuesAccCleanUp.chip) {
                                    projectsAcc.push({
                                        project_name: "Accountant cleanup",
                                        since_date: valuesAccCleanUp.sinceDate.startOf("day").format("YYYY-MM-DD"),
                                        end_date: valuesAccCleanUp.untilDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesAccCleanUp.discount,
                                        additionals_discount: valuesAccCleanUp.cleanUpAdditionalsDiscount,
                                        additionals_only: valuesAccCleanUp.additionalsOnly,
                                        additionals: additionalsAcc.additionalsCleanUp,
                                        fixed_hours: valuesAccCleanUp.fixedHours,
                                    });
                                }
                                if (valuesAccCatchUp.chip) {
                                    projectsAcc.push({
                                        project_name: "Accountant catchup",
                                        since_date: valuesAccCatchUp.sinceDate.startOf("day").format("YYYY-MM-DD"),
                                        end_date: valuesAccCatchUp.untilDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesAccCatchUp.discount,
                                        additionals_discount: valuesAccCatchUp.catchUpAdditionalsDiscount,
                                        additionals_only: valuesAccCatchUp.additionalsOnly,
                                        additionals: additionalsAcc.additionalsCatchUp,
                                        fixed_hours: valuesAccCatchUp.fixedHours,
                                    });
                                }
                                if (valuesAccRecu.chip) {
                                    projectsAcc.push({
                                        project_name: "Accountant recurring",
                                        since_date: valuesAccRecu.initDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesAccRecu.recuDiscount,
                                        additionals_discount: valuesAccRecu.recuAdditionalsDiscount,
                                        additionals_only: valuesAccRecu.recuAdditionalsOnly,
                                        payment_type: valuesAccRecu.payAnnually,
                                        additionals: additionalsAcc.additionalsRecu,
                                    });
                                }
                                if (valuesAccConsul.chip) {
                                    projectsAcc.push({
                                        project_name: "Accountant consulting",
                                        consulting_array: valuesAccConsul.consultingArray,
                                    });
                                }
                                servicesInfo.push({
                                    service_name: "Accountant",
                                    projects: projectsAcc,
                                });
                            } else {
                                isValidGen = false;
                                setIsError(true);
                                setIsErrorFocus(true);
                                setTexto(t("services.hoursValidationError"));
                                setAutoAlertType("warning");
                                setShow(true);
                            }
                        } else {
                            isValidGen = false;
                            setIsError(true);
                            setIsErrorFocus(true);
                            setTexto(t("miscellaneous.requiredData"));
                            setAutoAlertType("warning");
                            setShow(true);
                        }
                        break;
                    case "bookkeeper":
                        let projectsBook = [];
                        let additionalsBook = setAllAdditionals(item.name);

                        if (additionalsBook.isValid) {
                            if (additionalsBook.isValidHours) {
                                if (valuesBookOnboard.chip) {
                                    projectsBook.push({
                                        project_name: "SMBs' onboarding",
                                        since_date: moment().startOf("day").format("YYYY-MM-DD"),
                                        meetingDate: valuesBookOnboard.meetingDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesBookOnboard.onboardDiscount,
                                        description: valuesBookOnboard.description,
                                    });
                                }
                                if (valuesBookCleanUp.chip) {
                                    projectsBook.push({
                                        project_name: "Bookkeeper cleanup",
                                        since_date: valuesBookCleanUp.sinceDate.startOf("day").format("YYYY-MM-DD"),
                                        end_date: valuesBookCleanUp.untilDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesBookCleanUp.discount,
                                        additionals_discount: valuesBookCleanUp.cleanUpAdditionalsDiscount,
                                        additionals_only: valuesBookCleanUp.additionalsOnly,
                                        additionals: additionalsBook.additionalsCleanUp,
                                        fixed_hours: valuesBookCleanUp.fixedHours,
                                    });
                                }
                                if (valuesBookCatchUp.chip) {
                                    projectsBook.push({
                                        project_name: "Bookkeeper catchup",
                                        since_date: valuesBookCatchUp.sinceDate.startOf("day").format("YYYY-MM-DD"),
                                        end_date: valuesBookCatchUp.untilDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesBookCatchUp.discount,
                                        additionals_discount: valuesBookCatchUp.catchUpAdditionalsDiscount,
                                        additionals_only: valuesBookCatchUp.additionalsOnly,
                                        additionals: additionalsBook.additionalsCatchUp,
                                        fixed_hours: valuesBookCatchUp.fixedHours,
                                    });
                                }
                                if (valuesBookRecu.chip) {
                                    projectsBook.push({
                                        project_name: "Bookkeeper recurring",
                                        since_date: valuesBookRecu.initDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesBookRecu.recuDiscount,
                                        additionals_discount: valuesBookRecu.recuAdditionalsDiscount,
                                        additionals_only: valuesBookRecu.recuAdditionalsOnly,
                                        payment_type: valuesBookRecu.payAnnually,
                                        additionals: additionalsBook.additionalsRecu,
                                    });
                                }
                                if (valuesBookConsul.chip) {
                                    projectsBook.push({
                                        project_name: "Bookkeeper consulting",
                                        consulting_array: valuesBookConsul.consultingArray,
                                    });
                                }
                                servicesInfo.push({
                                    service_name: "Bookkeeper",
                                    projects: projectsBook,
                                });
                            } else {
                                isValidGen = false;
                                setIsError(true);
                                setIsErrorFocus(true);
                                setTexto(t("services.hoursValidationError"));
                                setAutoAlertType("warning");
                                setShow(true);
                            }
                        } else {
                            isValidGen = false;
                            setIsError(true);
                            setIsErrorFocus(true);
                            setTexto(t("miscellaneous.requiredData"));
                            setAutoAlertType("warning");
                            setShow(true);
                        }
                        break;
                    case "controller":
                        let projectsCont = [];
                        let additionalsCont = setAllAdditionals(item.name);

                        if (additionalsCont.isValid) {
                            if (additionalsCont.isValidHours) {
                                if (valuesContOnboard.chip) {
                                    projectsCont.push({
                                        project_name: "Controller onboarding",
                                        since_date: moment().startOf("day").format("YYYY-MM-DD"),
                                        meetingDate: valuesContOnboard.meetingDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesContOnboard.onboardDiscount,
                                        description: valuesContOnboard.description,
                                    });
                                }
                                if (valuesContCatchUp.chip) {
                                    projectsCont.push({
                                        project_name: "Controller catchup",
                                        since_date: valuesContCatchUp.sinceDate.startOf("day").format("YYYY-MM-DD"),
                                        end_date: valuesContCatchUp.untilDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesContCatchUp.discount,
                                        additionals_discount: valuesContCatchUp.catchUpAdditionalsDiscount,
                                        additionals_only: valuesContCatchUp.additionalsOnly,
                                        additionals: additionalsCont.additionalsCatchUp,
                                        fixed_hours: valuesContCatchUp.fixedHours,
                                    });
                                }
                                if (valuesContRecu.chip) {
                                    projectsCont.push({
                                        project_name: "Controller recurring",
                                        since_date: valuesContRecu.initDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesContRecu.recuDiscount,
                                        additionals_discount: valuesContRecu.recuAdditionalsDiscount,
                                        additionals_only: valuesContRecu.recuAdditionalsOnly,
                                        payment_type: valuesContRecu.payAnnually,
                                        additionals: additionalsCont.additionalsRecu,
                                    });
                                }
                                if (valuesContConsul.chip) {
                                    projectsCont.push({
                                        project_name: "Controller consulting",
                                        consulting_array: valuesContConsul.consultingArray,
                                    });
                                }
                                servicesInfo.push({
                                    service_name: "Controller",
                                    projects: projectsCont,
                                });
                            } else {
                                isValidGen = false;
                                setIsError(true);
                                setIsErrorFocus(true);
                                setTexto(t("services.hoursValidationError"));
                                setAutoAlertType("warning");
                                setShow(true);
                            }
                        } else {
                            isValidGen = false;
                            setIsError(true);
                            setIsErrorFocus(true);
                            setTexto(t("miscellaneous.requiredData"));
                            setAutoAlertType("warning");
                            setShow(true);
                        }
                        break;
                    case "cfo":
                        let projectsCFO = [];
                        let additionalsCFO = setAllAdditionals(item.name);

                        if (additionalsCFO.isValid) {
                            if (additionalsCFO.isValidHours) {
                                if (valuesCFOOnboard.chip) {
                                    projectsCFO.push({
                                        project_name: "CFO onboarding",
                                        since_date: moment().startOf("day").format("YYYY-MM-DD"),
                                        meetingDate: valuesCFOOnboard.meetingDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesCFOOnboard.onboardDiscount,
                                        description: valuesCFOOnboard.description,
                                    });
                                }
                                if (valuesCFOCatchUp.chip) {
                                    projectsCFO.push({
                                        project_name: "CFO catchup",
                                        since_date: valuesCFOCatchUp.sinceDate.startOf("day").format("YYYY-MM-DD"),
                                        end_date: valuesCFOCatchUp.untilDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesCFOCatchUp.discount,
                                        additionals_discount: valuesCFOCatchUp.catchUpAdditionalsDiscount,
                                        additionals_only: valuesCFOCatchUp.additionalsOnly,
                                        additionals: additionalsCFO.additionalsCatchUp,
                                        fixed_hours: valuesCFOCatchUp.fixedHours,
                                    });
                                }
                                if (valuesCFORecu.chip) {
                                    projectsCFO.push({
                                        project_name: "CFO recurring",
                                        since_date: valuesCFORecu.initDate.startOf("day").format("YYYY-MM-DD"),
                                        discount: valuesCFORecu.recuDiscount,
                                        additionals_discount: valuesCFORecu.recuAdditionalsDiscount,
                                        additionals_only: valuesCFORecu.recuAdditionalsOnly,
                                        payment_type: valuesCFORecu.payAnnually,
                                        additionals: additionalsCFO.additionalsRecu,
                                    });
                                }
                                if (valuesCFOConsul.chip) {
                                    projectsCFO.push({
                                        project_name: "CFO consulting",
                                        consulting_array: valuesCFOConsul.consultingArray,
                                    });
                                }
                                servicesInfo.push({
                                    service_name: "CFO",
                                    projects: projectsCFO,
                                });
                            } else {
                                isValidGen = false;
                                setIsError(true);
                                setIsErrorFocus(true);
                                setTexto(t("services.hoursValidationError"));
                                setAutoAlertType("warning");
                                setShow(true);
                            }
                        } else {
                            isValidGen = false;
                            setIsError(true);
                            setIsErrorFocus(true);
                            setTexto(t("miscellaneous.requiredData"));
                            setAutoAlertType("warning");
                            setShow(true);
                        }
                        break;
                    case "softSetup":
                        let projectsSetUp = [];
                        let additionalSetup = setAllAdditionals(item.name);

                        if (additionalSetup.isValid) {
                            if (valuesSetupXero.chip) {
                                projectsSetUp.push({
                                    project_name: "Xero setup",
                                    package: valuesSetupXero.package,
                                    version: valuesSetupXero.version,
                                    consulting_hours: valuesSetupXero.hours,
                                    discount: valuesSetupXero.discount,
                                    commitment_date_proposal: valuesSetupXero.commitmentDateProposal,
                                });
                            }
                            if (valuesSetupBanking.chip) {
                                projectsSetUp.push({
                                    project_name: "Kiiper Banking setup",
                                    package: valuesSetupBanking.package,
                                    version: valuesSetupBanking.version,
                                    consulting_hours: valuesSetupBanking.hours,
                                    discount: valuesSetupBanking.discount,
                                    commitment_date_proposal: valuesSetupBanking.commitmentDateProposal,
                                });
                            }
                            if (valuesSetupTaxes.chip) {
                                projectsSetUp.push({
                                    project_name: "Kiiper Taxes setup",
                                    package: valuesSetupTaxes.package,
                                    version: valuesSetupTaxes.version,
                                    consulting_hours: valuesSetupTaxes.hours,
                                    discount: valuesSetupTaxes.discount,
                                    commitment_date_proposal: valuesSetupTaxes.commitmentDateProposal,
                                });
                            }
                            if (valuesSetupReport.chip) {
                                projectsSetUp.push({
                                    project_name: "Kiiper Reporting setup",
                                    package: valuesSetupReport.package,
                                    version: valuesSetupReport.version,
                                    consulting_hours: valuesSetupReport.hours,
                                    discount: valuesSetupReport.discount,
                                    commitment_date_proposal: valuesSetupReport.commitmentDateProposal,
                                });
                            }
                            if (valuesSetupOther.chip) {
                                projectsSetUp.push({
                                    project_name: "Other setup",
                                    package: valuesSetupOther.package,
                                    version: valuesSetupOther.software,
                                    consulting_hours: valuesSetupOther.hours,
                                    discount: valuesSetupOther.discount,
                                    commitment_date_proposal: valuesSetupOther.commitmentDateProposal,
                                });
                            }
                            if (valuesSetupCon.chip) {
                                projectsSetUp.push({
                                    project_name: "Xero consulting",
                                    consulting_array: valuesSetupCon.consultingArray,
                                });
                            }
                            servicesInfo.push({
                                service_name: "Software setup",
                                projects: projectsSetUp,
                            });
                        } else {
                            isValidGen = false;
                            setIsError(true);
                            setIsErrorFocus(true);
                            setTexto(t("miscellaneous.requiredData"));
                            setAutoAlertType("warning");
                            setShow(true);
                        }
                        break;
                    case "softSubs":
                        let projectsSubs = [];
                        let additionalSubs = setAllAdditionals(item.name);

                        if (additionalSubs.isValid) {
                            if (additionalSubs.isValidHours) {
                                if (valuesSubsXero.chip) {
                                    projectsSubs.push({
                                        project_name: "Xero subscription",
                                        version: valuesSubsXero.version,
                                        discount: valuesSubsXero.discount,
                                        additionals: additionalSubs.additionalsXero,
                                        since_date_proposal: valuesSubsXero.sinceDateProposal,
                                    });
                                }
                                if (valuesSubsBanking.chip) {
                                    projectsSubs.push({
                                        project_name: "Kiiper Banking subscription",
                                        version: valuesSubsBanking.version,
                                        discount: valuesSubsBanking.discount,
                                        since_date_proposal: valuesSubsBanking.sinceDateProposal,
                                    });
                                }
                                if (valuesSubsTaxes.chip) {
                                    projectsSubs.push({
                                        project_name: "Kiiper Taxes subscription",
                                        version: valuesSubsTaxes.version,
                                        discount: valuesSubsTaxes.discount,
                                        since_date_proposal: valuesSubsTaxes.sinceDateProposal,
                                    });
                                }
                                if (valuesSubsReport.chip) {
                                    projectsSubs.push({
                                        project_name: "Kiiper Reporting subscription",
                                        version: valuesSubsReport.version,
                                        discount: valuesSubsReport.discount,
                                        since_date_proposal: valuesSubsReport.sinceDateProposal,
                                    });
                                }
                                if (valuesSubsCustomer.chip) {
                                    projectsSubs.push({
                                        project_name: "Customer support",
                                        consulting_hours: valuesSubsCustomer.hours,
                                        discount: valuesSubsCustomer.discount,
                                        since_date_proposal: valuesSubsCustomer.sinceDateProposal,
                                    });
                                }
                                servicesInfo.push({
                                    service_name: "Software subscription",
                                    projects: projectsSubs,
                                });
                            } else {
                                isValidGen = false;
                                setIsError(true);
                                setIsErrorFocus(true);
                                setTexto(t("services.hoursValidationError"));
                                setAutoAlertType("warning");
                                setShow(true);
                            }
                        } else {
                            isValidGen = false;
                            setIsError(true);
                            setIsErrorFocus(true);
                            setTexto(t("miscellaneous.requiredData"));
                            setAutoAlertType("warning");
                            setShow(true);
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        if (!more100 && !monthlyAverage) {
            isValidGen = false;
            setIsError(true);
            setIsErrorFocus(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }

        if (isValidGen) {
            if (isEdit) {
                const params = {
                    company_id: prospect.company_id,
                    proposal_id: proposalID,
                    status: value,
                    services: servicesInfo,
                    expenses: monthlyAverage,
                    tariff: tariff,
                    tariff_additionals: tariffAdditionals,
                    preferential_fee: preferentialFee,
                    additional_emails: finalMails,
                    front_image: randomNum,
                    one_time_total: accountantTotal + bookkeeperTotal + controllerTotal + cfoTotal + setUpTotal,
                    monthly_total:
                        accountantTotalMonth +
                        bookkeeperTotalMonth +
                        controllerTotalMonth +
                        cfoTotalMonth +
                        subscripTotalMonth,
                    isSend,
                    include_onboarding: includeOnboarding,
                    include_toolbox: includeToolbox,
                    include_adm_module_sub: includeAdmModuleSub,
                    include_acc_module_sub: includeAccModuleSub,
                    include_adm_module_set: includeAdmModuleSet,
                    include_acc_module_set: includeAccModuleSet,
                };

                await fetch(`/updateProposal`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((res) => res.json())
                    .then((response) => {
                        if (response.message) {
                            if (defaultMsg) {
                                let text = !isSend
                                    ? t("miscellaneous.successNewProposal")
                                    : t("miscellaneous.successUpdProposal");
                                setTexto(text + " " + response.message);
                                setAutoAlertType("success");
                                setShow(true);
                            }
                            if (redirect)
                                setTimeout(() => {
                                    navigate("/proposals/manageProposals");
                                }, 3000);
                        } else {
                            setTexto(t("miscellaneous.errorUpdProposal"));
                            setAutoAlertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                const params = {
                    company_id: prospect.company_id,
                    prospect_id: prospect.prospect_id,
                    status: value,
                    services: servicesInfo,
                    expenses: monthlyAverage,
                    tariff: tariff,
                    tariff_additionals: tariffAdditionals,
                    preferential_fee: preferentialFee,
                    additional_emails: finalMails,
                    front_image: randomNum,
                    one_time_total: accountantTotal + bookkeeperTotal + controllerTotal + cfoTotal + setUpTotal,
                    monthly_total:
                        accountantTotalMonth +
                        bookkeeperTotalMonth +
                        controllerTotalMonth +
                        cfoTotalMonth +
                        subscripTotalMonth,
                    isSend,
                    include_onboarding: includeOnboarding,
                    include_toolbox: includeToolbox,
                    include_adm_module_sub: includeAdmModuleSub,
                    include_acc_module_sub: includeAccModuleSub,
                    include_adm_module_set: includeAdmModuleSet,
                    include_acc_module_set: includeAccModuleSet,
                };

                const addProposalID = await fetch(`/addProposal`, {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(params),
                })
                    .then((res) => res.json())
                    .then((response) => {
                        if (response.message) {
                            if (defaultMsg) {
                                setTexto(t("miscellaneous.successNewProposal") + " " + response.message);
                                setAutoAlertType("success");
                                setShow(true);
                            }

                            if (redirect) {
                                setTimeout(() => {
                                    navigate("/proposals/manageProposals");
                                }, 3000);
                            }
                            if (!isEdit) {
                                return response.newProposal;
                            }
                        } else {
                            setTexto(t("miscellaneous.errorNewProposal"));
                            setAutoAlertType("error");
                            setShow(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                setBackDrop(false);
                return addProposalID;
            }
        }

        setBackDrop(false);
    };

    const sendDuplicateProposal = async () => {
        const _proposal = await sendProposal(2, false, false);
        await duplicate(true, _proposal, false);
    };

    const showPreview = () => {
        const defaultAdd = {
            isValid: true,
            isValidHours: true,
        };
        let proposals = [];
        let accAdditionals = { ...defaultAdd };
        let bookAdditionals = { ...defaultAdd };
        let contAdditionals = { ...defaultAdd };
        let cfoAdditionals = { ...defaultAdd };
        let setupAdditionals = { ...defaultAdd };
        let subsAdditionals = { ...defaultAdd };

        const defaultServices = {
            prospect: prospect,
            expenses: monthlyAverage,
            status: isEdit ? proposalStatus : 1,
        };

        services.forEach((service) => {
            if (service.name === "accountant" && service.isActive) {
                accAdditionals = setAllAdditionals("accountant");

                let finalObj = {
                    name: "Accountant",
                    onboarding: {
                        isActive: valuesAccOnboard.chip,
                    },
                    cleanUp: {
                        isActive: valuesAccCleanUp.chip,
                        additionals: accAdditionals.additionalsCleanUp,
                    },
                    catchUp: {
                        isActive: valuesAccCatchUp.chip,
                        additionals: accAdditionals.additionalsCatchUp,
                    },
                    recurring: {
                        isActive: valuesAccRecu.chip,
                        additionals: accAdditionals.additionalsRecu,
                    },
                    consulting: {
                        isActive: valuesAccConsul.chip,
                    },
                    totalAll:
                        (!valuesAccCleanUp.chip
                            ? 0
                            : valuesAccCleanUp.projectTotal +
                              valuesAccCleanUp.totalHours *
                                  valuesAccCleanUp.hourRate *
                                  (1 - valuesAccCleanUp.discount / 100)) +
                        (!valuesAccCatchUp.chip
                            ? 0
                            : valuesAccCatchUp.projectTotal +
                              valuesAccCatchUp.totalHours *
                                  valuesAccCatchUp.hourRate *
                                  (1 - valuesAccCatchUp.discount / 100)) +
                        (!valuesAccOnboard.chip ? 0 : valuesAccOnboard.onboardProjectTotal) +
                        (!valuesAccRecu.chip
                            ? 0
                            : valuesAccRecu.recuProjectTotal +
                              valuesAccRecu.recuHours *
                                  valuesAccRecu.recuHourRate *
                                  (1 - valuesAccRecu.recuDiscount / 100)) +
                        (!valuesAccConsul.chip ? 0 : valuesAccConsul.consulProjectTotal),
                    genAdditionals: accAdditionals.genAdd,
                    ...defaultServices,
                };

                if (valuesAccOnboard.chip) {
                    finalObj.onboarding = {
                        ...finalObj.onboarding,
                        fee: valuesAccOnboard.onboardOriginalFee,
                        discount: valuesAccOnboard.onboardDiscount,
                        description: valuesAccOnboard.description,
                        total: valuesAccOnboard.onboardProjectTotal,
                    };
                }
                if (valuesAccCleanUp.chip) {
                    finalObj.cleanUp = {
                        ...finalObj.cleanUp,
                        fee: valuesAccCleanUp.additionalsOnly
                            ? accAdditionals.addCleanUp.fee
                            : valuesAccCleanUp.cleanUpOriginalFee,
                        addFee: valuesAccCleanUp.hourRate,
                        discount: valuesAccCleanUp.discount,
                        total: valuesAccCleanUp.projectTotal,
                        initDate: valuesAccCleanUp.sinceDate,
                        untilDate: valuesAccCleanUp.untilDate,
                        monthQty: valuesAccCleanUp.additionalsOnly
                            ? valuesAccCleanUp.fixedHours
                                ? accAdditionals.addCleanUp.hours
                                : accAdditionals.addCleanUp.hours * valuesAccCleanUp.months
                            : valuesAccCleanUp.months,
                        totalHours: valuesAccCleanUp.totalHours,
                        onlyAdd: accAdditionals.additionalsCleanUp.length === 1 && valuesAccCleanUp.additionalsOnly,
                    };
                }
                if (valuesAccCatchUp.chip) {
                    finalObj.catchUp = {
                        ...finalObj.catchUp,
                        fee: valuesAccCatchUp.additionalsOnly
                            ? accAdditionals.addCatchUp.fee
                            : valuesAccCatchUp.catchUpOriginalFee,
                        addFee: valuesAccCatchUp.hourRate,
                        discount: valuesAccCatchUp.discount,
                        total: valuesAccCatchUp.projectTotal,
                        initDate: valuesAccCatchUp.sinceDate,
                        untilDate: valuesAccCatchUp.untilDate,
                        monthQty: valuesAccCatchUp.additionalsOnly
                            ? valuesAccCatchUp.fixedHours
                                ? accAdditionals.addCatchUp.hours
                                : accAdditionals.addCatchUp.hours * valuesAccCatchUp.months
                            : valuesAccCatchUp.months,
                        totalHours: valuesAccCatchUp.totalHours,
                        onlyAdd: accAdditionals.additionalsCatchUp.length === 1 && valuesAccCatchUp.additionalsOnly,
                    };
                }
                if (valuesAccRecu.chip) {
                    finalObj.recurring = {
                        ...finalObj.recurring,
                        fee: valuesAccRecu.recuAdditionalsOnly
                            ? accAdditionals.addRecurring.fee
                            : valuesAccRecu.recuOriginalFee,
                        addFee: valuesAccRecu.recuHourRate,
                        discount: valuesAccRecu.recuDiscount,
                        total: valuesAccRecu.recuProjectTotal,
                        initDate: valuesAccRecu.initDate,
                        payAnnually: valuesAccRecu.payAnnually,
                        recuHours: valuesAccRecu.recuHours,
                        qty: valuesAccRecu.recuAdditionalsOnly ? accAdditionals.addRecurring.hours : 1,
                        onlyAdd: accAdditionals.additionalsRecu.length === 1 && valuesAccRecu.recuAdditionalsOnly,
                    };
                }
                if (valuesAccConsul.chip) {
                    finalObj.consulting = {
                        ...finalObj.consulting,
                        fee: valuesAccConsul.consulOriginalFee,
                        array: valuesAccConsul.consultingArray,
                    };
                }

                proposals.push(finalObj);
            }
            if (service.name === "bookkeeper" && service.isActive) {
                bookAdditionals = setAllAdditionals("bookkeeper");

                let finalObj = {
                    name: "Bookkeeper",
                    onboarding: {
                        isActive: valuesBookOnboard.chip,
                    },
                    cleanUp: {
                        isActive: valuesBookCleanUp.chip,
                        additionals: bookAdditionals.additionalsCleanUp,
                    },
                    catchUp: {
                        isActive: valuesBookCatchUp.chip,
                        additionals: bookAdditionals.additionalsCatchUp,
                    },
                    recurring: {
                        isActive: valuesBookRecu.chip,
                        additionals: bookAdditionals.additionalsRecu,
                    },
                    consulting: {
                        isActive: valuesBookConsul.chip,
                    },
                    totalAll:
                        (!valuesBookRecu.chip
                            ? 0
                            : (valuesBookRecu.recuProjectTotal +
                                  valuesBookRecu.recuHours * valuesBookRecu.recuHourRate +
                                  (valuesBookRecu.recuAdditionalsOnly
                                      ? bookAdditionals.addRecurring.hours * tariffAdditionals
                                      : 0)) *
                              (1 - valuesBookRecu.recuDiscount / 100)) +
                        (!valuesBookCleanUp.chip
                            ? 0
                            : valuesBookCleanUp.projectTotal +
                              valuesBookCleanUp.totalHours *
                                  valuesBookCleanUp.hourRate *
                                  (1 - valuesBookCleanUp.discount / 100)) +
                        (!valuesBookCatchUp.chip
                            ? 0
                            : valuesBookCatchUp.projectTotal +
                              valuesBookCatchUp.totalHours *
                                  valuesBookCatchUp.hourRate *
                                  (1 - valuesBookCatchUp.discount / 100)) +
                        (!valuesBookOnboard.chip ? 0 : valuesBookOnboard.onboardProjectTotal) +
                        (!valuesBookConsul.chip ? 0 : valuesBookConsul.consulProjectTotal) +
                        (includeToolbox ? 99 : 0) +
                        (includeOnboarding ? 499 : 0),
                    genAdditionals: bookAdditionals.genAdd,
                    ...defaultServices,
                };

                if (valuesBookOnboard.chip) {
                    finalObj.onboarding = {
                        ...finalObj.onboarding,
                        fee: valuesBookOnboard.onboardOriginalFee,
                        discount: valuesBookOnboard.onboardDiscount,
                        description: valuesBookOnboard.description,
                        total: valuesBookOnboard.onboardProjectTotal,
                    };
                }
                if (valuesBookCleanUp.chip) {
                    finalObj.cleanUp = {
                        ...finalObj.cleanUp,
                        fee: valuesBookCleanUp.additionalsOnly
                            ? tariffAdditionals
                            : valuesBookCleanUp.cleanUpOriginalFee,
                        addFee: tariffAdditionals,
                        discount: valuesBookCleanUp.discount,
                        total: valuesBookCleanUp.projectTotal,
                        initDate: valuesBookCleanUp.sinceDate,
                        untilDate: valuesBookCleanUp.untilDate,
                        monthQty: valuesBookCleanUp.additionalsOnly
                            ? valuesBookCleanUp.fixedHours
                                ? bookAdditionals.addCleanUp.hours
                                : bookAdditionals.addCleanUp.hours * valuesBookCleanUp.months
                            : valuesBookCleanUp.months,
                        totalHours: valuesBookCleanUp.totalHours,
                        onlyAdd: bookAdditionals.additionalsCleanUp.length === 1 && valuesBookCleanUp.additionalsOnly,
                    };
                }
                if (valuesBookCatchUp.chip) {
                    finalObj.catchUp = {
                        ...finalObj.catchUp,
                        fee: valuesBookCatchUp.additionalsOnly
                            ? tariffAdditionals
                            : valuesBookCatchUp.catchUpOriginalFee,
                        addFee: tariffAdditionals,
                        discount: valuesBookCatchUp.discount,
                        total: valuesBookCatchUp.projectTotal,
                        initDate: valuesBookCatchUp.sinceDate,
                        untilDate: valuesBookCatchUp.untilDate,
                        monthQty: valuesBookCatchUp.additionalsOnly
                            ? valuesBookCatchUp.fixedHours
                                ? bookAdditionals.addCatchUp.hours
                                : bookAdditionals.addCatchUp.hours * valuesBookCatchUp.months
                            : valuesBookCatchUp.months,
                        totalHours: valuesBookCatchUp.totalHours,
                        onlyAdd: bookAdditionals.additionalsCatchUp.length === 1 && valuesBookCatchUp.additionalsOnly,
                    };
                }
                if (valuesBookRecu.chip) {
                    finalObj.recurring = {
                        ...finalObj.recurring,
                        fee: valuesBookRecu.recuAdditionalsOnly
                            ? bookAdditionals.addRecurring.fee
                            : valuesBookRecu.recuOriginalFee,
                        addFee: tariffAdditionals,
                        discount: valuesBookRecu.recuDiscount,
                        total:
                            valuesBookRecu.recuProjectTotal +
                            (valuesBookRecu.recuAdditionalsOnly
                                ? bookAdditionals.addRecurring.hours * tariffAdditionals
                                : 0),
                        initDate: valuesBookRecu.initDate,
                        payAnnually: valuesBookRecu.payAnnually,
                        recuHours: valuesBookRecu.recuHours,
                        qty: valuesBookRecu.recuAdditionalsOnly ? bookAdditionals.addRecurring.hours : 1,
                        onlyAdd: bookAdditionals.additionalsRecu.length === 1 && valuesBookRecu.recuAdditionalsOnly,
                        addOnly: valuesBookRecu.recuAdditionalsOnly,
                    };
                }
                if (valuesBookConsul.chip) {
                    finalObj.consulting = {
                        ...finalObj.consulting,
                        fee: valuesBookConsul.consulOriginalFee,
                        array: valuesBookConsul.consultingArray,
                    };
                }

                proposals.push(finalObj);
            }
            if (service.name === "controller" && service.isActive) {
                contAdditionals = setAllAdditionals("controller");

                let finalObj = {
                    name: "Controller",
                    onboarding: {
                        isActive: valuesContOnboard.chip,
                    },
                    cleanUp: {
                        isActive: false,
                        additionals: [],
                    },
                    catchUp: {
                        isActive: valuesContCatchUp.chip,
                        additionals: contAdditionals.additionalsCatchUp,
                    },
                    recurring: {
                        isActive: valuesContRecu.chip,
                        additionals: contAdditionals.additionalsRecu,
                    },
                    consulting: {
                        isActive: valuesContConsul.chip,
                    },
                    totalAll:
                        (!valuesContRecu.chip
                            ? 0
                            : valuesContRecu.recuProjectTotal +
                              valuesContRecu.recuHours *
                                  valuesContRecu.recuHourRate *
                                  (1 - valuesContRecu.recuDiscount / 100)) +
                        (!valuesContCatchUp.chip
                            ? 0
                            : valuesContCatchUp.projectTotal +
                              valuesContCatchUp.totalHours *
                                  valuesContCatchUp.hourRate *
                                  (1 - valuesContCatchUp.discount / 100)) +
                        (!valuesContOnboard.chip ? 0 : valuesContOnboard.onboardProjectTotal) +
                        (!valuesContConsul.chip ? 0 : valuesContConsul.consulProjectTotal),
                    genAdditionals: contAdditionals.genAddCont,
                    ...defaultServices,
                };

                if (valuesContOnboard.chip) {
                    finalObj.onboarding = {
                        ...finalObj.onboarding,
                        fee: valuesContOnboard.onboardOriginalFee,
                        discount: valuesContOnboard.onboardDiscount,
                        total: valuesContOnboard.onboardProjectTotal,
                    };
                }
                if (valuesContCatchUp.chip) {
                    finalObj.catchUp = {
                        ...finalObj.catchUp,
                        fee: valuesContCatchUp.additionalsOnly
                            ? contAdditionals.addCatchUp.fee
                            : valuesContCatchUp.catchUpOriginalFee,
                        addFee: valuesContCatchUp.hourRate,
                        discount: valuesContCatchUp.discount,
                        total: valuesContCatchUp.projectTotal,
                        initDate: valuesContCatchUp.sinceDate,
                        untilDate: valuesContCatchUp.untilDate,
                        monthQty: valuesContCatchUp.additionalsOnly
                            ? contAdditionals.addCatchUp.hours
                            : valuesContCatchUp.months,
                        totalHours: valuesContCatchUp.totalHours,
                        onlyAdd: contAdditionals.additionalsCatchUp.length === 1 && valuesContCatchUp.additionalsOnly,
                    };
                }
                if (valuesContRecu.chip) {
                    finalObj.recurring = {
                        ...finalObj.recurring,
                        fee: valuesContRecu.recuAdditionalsOnly
                            ? contAdditionals.addRecurring.fee
                            : valuesContRecu.recuOriginalFee,
                        addFee: valuesContRecu.recuHourRate,
                        discount: valuesContRecu.recuDiscount,
                        total: valuesContRecu.recuProjectTotal,
                        initDate: valuesContRecu.initDate,
                        payAnnually: valuesContRecu.payAnnually,
                        recuHours: valuesContRecu.recuHours,
                        qty: valuesContRecu.recuAdditionalsOnly ? contAdditionals.addRecurring.hours : 1,
                        onlyAdd: contAdditionals.additionalsRecu.length === 1 && valuesContRecu.recuAdditionalsOnly,
                    };
                }
                if (valuesContConsul.chip) {
                    finalObj.consulting = {
                        ...finalObj.consulting,
                        fee: valuesContConsul.consulOriginalFee,
                        array: valuesContConsul.consultingArray,
                    };
                }

                proposals.push(finalObj);
            }
            if (service.name === "cfo" && service.isActive) {
                cfoAdditionals = setAllAdditionals("cfo");

                let finalObj = {
                    name: "CFO",
                    onboarding: {
                        isActive: valuesCFOOnboard.chip,
                    },
                    cleanUp: {
                        isActive: false,
                        additionals: [],
                    },
                    catchUp: {
                        isActive: valuesCFOCatchUp.chip,
                        additionals: cfoAdditionals.additionalsCatchUp,
                    },
                    recurring: {
                        isActive: valuesCFORecu.chip,
                        additionals: cfoAdditionals.additionalsRecu,
                    },
                    consulting: {
                        isActive: valuesCFOConsul.chip,
                    },
                    totalAll:
                        (!valuesCFORecu.chip
                            ? 0
                            : valuesCFORecu.recuProjectTotal +
                              valuesCFORecu.recuHours *
                                  valuesCFORecu.recuHourRate *
                                  (1 - valuesCFORecu.recuDiscount / 100)) +
                        (!valuesCFOCatchUp.chip
                            ? 0
                            : valuesCFOCatchUp.projectTotal +
                              valuesCFOCatchUp.totalHours *
                                  valuesCFOCatchUp.hourRate *
                                  (1 - valuesCFOCatchUp.discount / 100)) +
                        (!valuesCFOOnboard.chip ? 0 : valuesCFOOnboard.onboardProjectTotal) +
                        (!valuesCFOConsul.chip ? 0 : valuesCFOConsul.consulProjectTotal),
                    genAdditionals: cfoAdditionals.genAddCFO,
                    ...defaultServices,
                };

                if (valuesCFOOnboard.chip) {
                    finalObj.onboarding = {
                        ...finalObj.onboarding,
                        fee: valuesCFOOnboard.onboardOriginalFee,
                        discount: valuesCFOOnboard.onboardDiscount,
                        total: valuesCFOOnboard.onboardProjectTotal,
                    };
                }
                if (valuesCFOCatchUp.chip) {
                    finalObj.catchUp = {
                        ...finalObj.catchUp,
                        fee: valuesCFOCatchUp.additionalsOnly
                            ? cfoAdditionals.addCatchUp.fee
                            : valuesCFOCatchUp.catchUpOriginalFee,
                        addFee: valuesCFOCatchUp.hourRate,
                        discount: valuesCFOCatchUp.discount,
                        total: valuesCFOCatchUp.projectTotal,
                        initDate: valuesCFOCatchUp.sinceDate,
                        untilDate: valuesCFOCatchUp.untilDate,
                        monthQty: valuesCFOCatchUp.additionalsOnly
                            ? cfoAdditionals.addCatchUp.hours
                            : valuesCFOCatchUp.months,
                        totalHours: valuesCFOCatchUp.totalHours,
                        onlyAdd: cfoAdditionals.additionalsCatchUp.length === 1 && valuesCFOCatchUp.additionalsOnly,
                    };
                }
                if (valuesCFORecu.chip) {
                    finalObj.recurring = {
                        ...finalObj.recurring,
                        fee: valuesCFORecu.recuAdditionalsOnly
                            ? cfoAdditionals.addRecurring.fee
                            : valuesCFORecu.recuOriginalFee,
                        addFee: valuesCFORecu.recuHourRate,
                        discount: valuesCFORecu.recuDiscount,
                        total: valuesCFORecu.recuProjectTotal,
                        initDate: valuesCFORecu.initDate,
                        payAnnually: valuesCFORecu.payAnnually,
                        recuHours: valuesCFORecu.recuHours,
                        qty: valuesCFORecu.recuAdditionalsOnly ? cfoAdditionals.addRecurring.hours : 1,
                        onlyAdd: cfoAdditionals.additionalsRecu.length === 1 && valuesCFORecu.recuAdditionalsOnly,
                    };
                }
                if (valuesCFOConsul.chip) {
                    finalObj.consulting = {
                        ...finalObj.consulting,
                        fee: valuesCFOConsul.consulOriginalFee,
                        array: valuesCFOConsul.consultingArray,
                    };
                }

                proposals.push(finalObj);
            }
            if (service.name === "softSetup" && service.isActive) {
                setupAdditionals = setAllAdditionals("softSetup");

                let finalObj = {
                    name: "Software setup",
                    xero: {
                        isActive: valuesSetupXero.chip,
                        additionals: [],
                    },
                    banking: {
                        isActive: valuesSetupBanking.chip,
                    },
                    taxes: {
                        isActive: valuesSetupTaxes.chip,
                    },
                    reporting: {
                        isActive: valuesSetupReport.chip,
                    },
                    other: {
                        isActive: valuesSetupOther.chip,
                    },
                    consulting: {
                        isActive: valuesSetupCon.chip,
                    },
                    totalAll:
                        (!valuesSetupXero.chip ? 0 : valuesSetupXero.projectTotal) +
                        (!valuesSetupBanking.chip ? 0 : valuesSetupBanking.projectTotal) +
                        (!valuesSetupReport.chip ? 0 : valuesSetupReport.projectTotal) +
                        (!valuesSetupTaxes.chip ? 0 : valuesSetupTaxes.projectTotal) +
                        (!valuesSetupOther.chip ? 0 : valuesSetupOther.projectTotal) +
                        (!valuesSetupCon.chip ? 0 : valuesSetupCon.consulProjectTotal),
                    ...defaultServices,
                };
                if (valuesSetupXero.chip) {
                    finalObj.xero = {
                        ...finalObj.xero,
                        fee: valuesSetupXero.originalFee,
                        package: valuesSetupXero.package,
                        discount: valuesSetupXero.discount,
                        hours: valuesSetupXero.hours,
                        total: valuesSetupXero.projectTotal,
                    };
                }
                if (valuesSetupBanking.chip) {
                    finalObj.banking = {
                        ...finalObj.banking,
                        fee: valuesSetupBanking.originalFee,
                        package: valuesSetupBanking.package,
                        discount: valuesSetupBanking.discount,
                        hours: valuesSetupBanking.hours,
                        total: valuesSetupBanking.projectTotal,
                    };
                }
                if (valuesSetupReport.chip) {
                    finalObj.reporting = {
                        ...finalObj.reporting,
                        fee: valuesSetupReport.originalFee,
                        package: valuesSetupReport.package,
                        discount: valuesSetupReport.discount,
                        hours: valuesSetupReport.hours,
                        total: valuesSetupReport.projectTotal,
                    };
                }
                if (valuesSetupTaxes.chip) {
                    finalObj.taxes = {
                        ...finalObj.taxes,
                        fee: valuesSetupTaxes.originalFee,
                        package: valuesSetupTaxes.package,
                        discount: valuesSetupTaxes.discount,
                        hours: valuesSetupTaxes.hours,
                        total: valuesSetupTaxes.projectTotal,
                    };
                }
                if (valuesSetupOther.chip) {
                    finalObj.other = {
                        ...finalObj.other,
                        fee: valuesSetupOther.originalFee,
                        discount: valuesSetupOther.discount,
                        hours: valuesSetupOther.hours,
                        total: valuesSetupOther.projectTotal,
                        software: valuesSetupOther.software,
                    };
                }
                if (valuesSetupCon.chip) {
                    finalObj.consulting = {
                        ...finalObj.consulting,
                        fee: valuesSetupCon.consulOriginalFee,
                        array: valuesSetupCon.consultingArray,
                    };
                }

                proposals.push(finalObj);
            }
            if (service.name === "softSubs" && service.isActive) {
                subsAdditionals = setAllAdditionals("softSubs");

                let finalObj = {
                    name: "Software subscription",
                    xero: {
                        isActive: valuesSubsXero.chip,
                        additionals: subsAdditionals.additionalsXero,
                    },
                    banking: {
                        isActive: valuesSubsBanking.chip,
                    },
                    taxes: {
                        isActive: valuesSubsTaxes.chip,
                    },
                    reporting: {
                        isActive: valuesSubsReport.chip,
                    },
                    support: {
                        isActive: valuesSubsCustomer.chip,
                    },
                    totalAll:
                        (!valuesSubsXero.chip ? 0 : valuesSubsXero.totalFee) +
                        (!valuesSubsBanking.chip ? 0 : valuesSubsBanking.projectTotal) +
                        (!valuesSubsReport.chip ? 0 : valuesSubsReport.projectTotal) +
                        (!valuesSubsTaxes.chip ? 0 : valuesSubsTaxes.projectTotal) +
                        (!valuesSubsCustomer.chip ? 0 : valuesSubsCustomer.projectTotal),
                    ...defaultServices,
                };

                if (valuesSubsXero.chip) {
                    finalObj.xero = {
                        ...finalObj.xero,
                        version: valuesSubsXero.version,
                        fee: valuesSubsXero.originalFee,
                        addFee: valuesSubsXero.addFee,
                        discount: valuesSubsXero.discount,
                        totalUsers: valuesSubsXero.totalUsers,
                        totalAdds: valuesSubsXero.additionalsTotal,
                        totalAddNoDiscount:
                            parseFloat(valuesSubsXero.tothExp) * 4 +
                            (parseFloat(valuesSubsXero.tothPro) !== 0
                                ? parseFloat(valuesSubsXero.tothPro - 1) * 5 + 7
                                : 0) +
                            (valuesSubsXero.checkedPlus ? 7 : 0),
                        total: valuesSubsXero.totalFee,
                    };
                }
                if (valuesSubsBanking.chip) {
                    finalObj.banking = {
                        ...finalObj.banking,
                        version: valuesSubsBanking.version,
                        fee: valuesSubsBanking.originalFee,
                        discount: valuesSubsBanking.discount,
                        total: valuesSubsBanking.projectTotal,
                    };
                }
                if (valuesSubsReport.chip) {
                    finalObj.reporting = {
                        ...finalObj.reporting,
                        fee: valuesSubsReport.originalFee,
                        discount: valuesSubsReport.discount,
                        total: valuesSubsReport.projectTotal,
                    };
                }
                if (valuesSubsTaxes.chip) {
                    finalObj.taxes = {
                        ...finalObj.taxes,
                        version: valuesSubsTaxes.version,
                        fee: valuesSubsTaxes.originalFee,
                        discount: valuesSubsTaxes.discount,
                        total: valuesSubsTaxes.projectTotal,
                    };
                }
                if (valuesSubsCustomer.chip) {
                    finalObj.support = {
                        ...finalObj.support,
                        fee: valuesSubsCustomer.originalFee,
                        hours: valuesSubsCustomer.hours,
                        discount: valuesSubsCustomer.discount,
                        total: valuesSubsCustomer.projectTotal,
                    };
                }

                proposals.push(finalObj);
            }
        });

        if (
            accAdditionals.isValid &&
            bookAdditionals.isValid &&
            contAdditionals.isValid &&
            cfoAdditionals.isValid &&
            setupAdditionals.isValid &&
            subsAdditionals.isValid
        ) {
            if (
                accAdditionals.isValidHours &&
                bookAdditionals.isValidHours &&
                contAdditionals.isValidHours &&
                cfoAdditionals.isValidHours &&
                setupAdditionals.isValidHours &&
                subsAdditionals.isValidHours
            ) {
                setIsPreview(true);
                setProposalDetails(proposals);

                if (arraysEqual(proposals, proposalDetailsOG)) {
                    setIsModified(false);
                } else {
                    setIsModified(true);
                }
            } else {
                setIsError(true);
                setIsErrorFocus(true);
                setTexto(t("services.hoursValidationError"));
                setAutoAlertType("warning");
                setShow(true);
            }
        } else {
            setIsError(true);
            setIsErrorFocus(true);
            setTexto(t("miscellaneous.requiredData"));
            setAutoAlertType("warning");
            setShow(true);
        }
    };

    function arraysEqual(a1, a2) {
        return JSON.stringify(a1) === JSON.stringify(a2);
    }

    const handleClose = (event, reason) => {
        setIsErrorFocus(false);
        if (reason === "clickaway") {
            return;
        }
        setShow(false);
    };

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (showtutorial && isExpanded) {
            showServiceTutorial(panel);
        }
    };

    const handleChangeTariffAdd = (event) => {
        setTariffAdditionals(event.target.value);
    };

    const handleChangeProspect = (event, value, reason) => {
        if (reason === "clear") {
            setProspect({});
        } else {
            if (value && value.inputValue) {
                // Create a new prospect from the dialog
                setOpenAdd(true);
            } else {
                if (value.client_type !== prospect.client_type) {
                    startOverProposal();
                }
                setProspectAndAverage(value);
            }
        }
    };

    const setProspectAndAverage = (value) => {
        setProspect(value);

        if (value.expense_average) {
            if (showtutorial) {
                showSecondTutorial();
            }
            setMonthlyAverage(value.expense_average);
            getPricingProposal(value.expense_average, value.country_name, value.client_type);
            calcAverageExp(value.expense_average, value.client_type);
        }
    };

    const getPricingProposalEdit = async (average, country_name, client_type) => {
        setBackDrop(true);
        await fetch(`/getPricingProposal?market=${country_name}&expenses=${average}&client_type=${client_type}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setPricingModel(data);
            });

        setBackDrop(false);
    };

    const getPricingProposal = async (average, country_name, client_type) => {
        setBackDrop(true);
        await fetch(`/getPricingProposal?market=${country_name}&expenses=${average}&client_type=${client_type}`, {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setPricingModel(data);
            });

        setBackDrop(false);
    };

    const startOverNoSoft = () => {
        setValuesAccRecu(defaultRecuVal);
        setValuesAccOnboard(defaultOnboardVal);
        setValuesAccCleanUp(defaultCleanUpVal);
        setValuesAccCatchUp(defaultCatchUpVal);
        setValuesAccConsul(defaultConsultVal);
        setValuesBookRecu(defaultRecuVal);
        setValuesBookOnboard(defaultOnboardVal);
        setValuesBookCleanUp(defaultCleanUpVal);
        setValuesBookCatchUp(defaultCatchUpVal);
        setValuesBookConsul(defaultConsultVal);
        setValuesContOnboard(defaultOnboardVal);
        setValuesContCatchUp(defaultCatchUpVal);
        setValuesContRecu(defaultRecuVal);
        setValuesContConsul(defaultConsultVal);
        setValuesCFOOnboard(defaultOnboardVal);
        setValuesCFOCatchUp(defaultCatchUpVal);
        setValuesCFORecu(defaultRecuVal);
        setValuesCFOConsul(defaultConsultVal);
    };

    const startOverProposal = () => {
        setAverage(0);
        setMonthlyAverage(0);
        setTariff(15);
        setTariffAdditionals(25);
        setPreferentialFee(false);
        setServices(defaultStateServices);
        startOverNoSoft();
        setValuesSetupXero(defaultSetUpVal);
        setValuesSetupBanking(defaultSetUpVal);
        setValuesSetupTaxes(defaultSetUpVal);
        setValuesSetupReport(defaultSetUpVal);
        setValuesSetupOther(defaultSetUpVal);
        setValuesSetupCon(defaultConsultVal);
        // Software subscription
        setValuesSubsXero(defaultSubsVal);
        setValuesSubsBanking(defaultSubsVal);
        setValuesSubsTaxes(defaultSubsVal);
        setValuesSubsReport(defaultSubsVal);
        setValuesSubsCustomer(defaultSubsVal);
        setExpanded(false);
        setIsError(false);
        setIsErrorFocus(false);
    };

    const handleChange = (event, newValue) => {
        let auxAverage = 0;

        switch (newValue) {
            case 0:
                let newDefault = [
                    {
                        name: "accountant",
                        isActive: false,
                        onboarding: false,
                    },
                    {
                        name: "bookkeeper",
                        isActive: false,
                        onboarding: false,
                    },
                    {
                        name: "controller",
                        isActive: false,
                        onboarding: false,
                    },
                    {
                        name: "cfo",
                        isActive: false,
                        onboarding: false,
                    },
                    {
                        name: "softSetup",
                        isActive: services[4].isActive,
                    },
                    {
                        name: "softSubs",
                        isActive: services[5].isActive,
                    },
                ];

                setMonthlyAverage(0);
                setTariff(15);
                setTariffAdditionals(25);
                setPreferentialFee(false);
                setServices(newDefault);
                startOverNoSoft();
                setExpanded(false);
                auxAverage = 0;
                break;
            case 9:
                setMonthlyAverage(10000);
                auxAverage = 10000;
                break;
            case 14:
                setMonthlyAverage(5000);
                auxAverage = 5000;
                break;
            case 18:
                setMonthlyAverage(20000);
                auxAverage = 20000;
                break;
            case 27:
                setMonthlyAverage(30000);
                auxAverage = 30000;
                break;
            case 28:
                setMonthlyAverage(10000);
                auxAverage = 10000;
                break;
            case 36:
                setMonthlyAverage(40000);
                auxAverage = 40000;
                break;
            case 42:
                setMonthlyAverage(25000);
                auxAverage = 25000;
                break;
            case 45:
                setMonthlyAverage(50000);
                auxAverage = 50000;
                break;
            case 54:
                setMonthlyAverage(60000);
                auxAverage = 60000;
                break;
            case 56:
                setMonthlyAverage(50000);
                auxAverage = 50000;
                break;
            case 63:
                setMonthlyAverage(70000);
                auxAverage = 70000;
                break;
            case 70:
                setMonthlyAverage(75000);
                auxAverage = 75000;
                break;
            case 72:
                setMonthlyAverage(80000);
                auxAverage = 80000;
                break;
            case 81:
                setMonthlyAverage(90000);
                auxAverage = 90000;
                break;
            case 84:
            case 90:
                setMonthlyAverage(100000);
                auxAverage = 100000;
                break;
            default:
                setMonthlyAverage(0);
                auxAverage = 0;
                break;
        }

        if (newValue === 100) {
            setMore100(false);
        } else {
            setMore100(true);
            getPricingProposal(auxAverage, prospect.country_name, prospect.client_type);
        }

        setAverage(newValue);

        if (showtutorial) {
            showSecondTutorial();
        }
    };

    const handleChangeMonthlyAverage = (value) => {
        setMonthlyAverage(value);
    };
    //Buttom group
    const [openBtnGroupSend, setOpenBtnGroupSend] = useState(false);
    const anchorRefBtnGroup = useRef(null);
    const [selectedIndexBtnGroup, setSelectedIndexBtnGroup] = useState(0);

    const handleMenuItemClickBtnGroup = (event, index) => {
        setSelectedIndexBtnGroup(index);
        setOpenBtnGroupSend(false);
        switch (index) {
            case 0:
                //Enviar
                sendProposal(2, true, true, proposalStatus === 1 ? false : true);
                break;
            case 1:
                //Duplicar
                duplicate();
                break;
            case 2:
                //Enviar y duplicar
                sendDuplicateProposal();
                break;
            default:
                break;
        }
    };

    const handleToggleBtnGroup = () => {
        setOpenBtnGroupSend((prevOpenBtnGroupSend) => !prevOpenBtnGroupSend);
    };

    const handleCloseBtnGroup = (event) => {
        if (anchorRefBtnGroup.current && anchorRefBtnGroup.current.contains(event.target)) {
            return;
        }

        setOpenBtnGroupSend(false);
        setIsErrorFocus(false);
    };

    const closeAddDialog = () => {
        setOpenAddDialog(false);
        setEmails("");
        setIsErrorMails(false);
    };

    const handleOpenMails = () => {
        setOpenAddDialog(true);

        setEmails(finalMails.toString());
    };

    const checkEmails = () => {
        const emailsNew = emails ? emails.replace(/\s+/g, "").split(",") : [];
        const regexMail = /.+@.+\..+/;
        let isError = false;

        emailsNew.forEach((value) => {
            if (!regexMail.test(value)) {
                isError = true;
            }
        });

        if (isError) {
            setIsErrorMails(true);
            setTexto(t("login.invalidEmailFormat"));
            setAutoAlertType("warning");
            setShow(true);
        } else {
            setFinalMails(emailsNew);
            closeAddDialog();
        }
    };

    const bodyMails = (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontSize: 15 }}>
                    {t("miscellaneous.introduceEmails")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label={t("miscellaneous.emails")}
                    multiline
                    rows={4}
                    value={emails}
                    onChange={handleChangeMails}
                    error={isErrorMails}
                />
            </Grid>
        </Grid>
    );

    const buttonsMails = (
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
                <Button
                    onClick={closeAddDialog}
                    sx={{
                        pl: 3,
                        pr: 3,
                        border: "1px solid #ff5968",
                        borderRadius: 40,
                        "&:hover": {
                            border: "1px solid #2f3190",
                            color: "#2f3190",
                        },
                        "&.Mui-disabled": {
                            color: "#ffcdd2",
                            borderColor: "#ffcdd2",
                            background: "transparent",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="outlined"
                >
                    {t("miscellaneous.cancel")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    onClick={checkEmails}
                    sx={{
                        pl: 3,
                        pr: 3,
                        borderRadius: 40,
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            color: "#fff",
                        },
                        "&.Mui-disabled": {
                            color: "#fff",
                            borderColor: "#ffcdd2",
                            background: "#ffcdd2",
                        },
                    }}
                    color="secondary"
                    disableElevation
                    variant="contained"
                >
                    {t("miscellaneous.accept")}
                </Button>
            </Grid>
        </Grid>
    );

    const handleChangeSwitchToolbox = () => {
        setIncludeToolbox((show) => !show);
    };

    const handleChangeSwitchOnboarding = () => {
        setIncludeOnboarding((show) => !show);
    };

    const handleChangeCheckAdmSub = () => {
        setIncludeAdmModuleSub((checked) => !checked);
    };

    const handleChangeCheckAccSub = () => {
        setIncludeAccModuleSub((checked) => !checked);
    };

    const handleChangeCheckAdmSet = () => {
        setIncludesAdmModuleSet((checked) => !checked);
    };

    const handleChangeCheckAccSet = () => {
        setIncludeAccModuleSet((checked) => !checked);
    };

    return (
        <StyledContainer component="main" sx={{ flexGrow: 1, p: 4, pt: 10, width: "100%" }}>
            <Stack sx={{ pb: 4 }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                    aria-label="breadcrumb"
                >
                    {
                        <Typography
                            key="1"
                            variant="h1"
                            color="primary"
                            onClick={() => navigate("/proposals")}
                            sx={{
                                cursor: "pointer",
                                fontSize: 24,
                                fontWeight: 700,
                                "&:hover": {
                                    color: "#ff5968",
                                },
                            }}
                        >
                            {t("proposals.proposals")}
                        </Typography>
                    }
                    {
                        <Typography key="1" variant="h1" color="secondary" sx={{ fontSize: 24, fontWeight: 700 }}>
                            {t("proposals.createProposal")}
                        </Typography>
                    }
                </Breadcrumbs>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {isPreview ? (
                        <ProposalPreview
                            proposalDetails={proposalDetails}
                            tariff={tariff}
                            tariffAdditionals={tariffAdditionals}
                            preferentialFee={preferentialFee}
                            randomNum={randomNum}
                            setRandomNum={setRandomNum}
                            includeToolbox={includeToolbox}
                            includeOnboarding={includeOnboarding}
                            includeAccModuleSet={includeAccModuleSet}
                            includeAccModuleSub={includeAccModuleSub}
                            includeAdmModuleSet={includeAdmModuleSet}
                            includeAdmModuleSub={includeAdmModuleSub}
                            accountantOnboarding={valuesAccOnboard.chip}
                        />
                    ) : (
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 8,
                                boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                                height: "100% !important",
                            }}
                        >
                            <CardContent classes={{ root: classes.cardContentRoot }}>
                                <Grid id="slider-tutorial" container justifyContent="space-between" spacing={2}>
                                    <Grid item xs={12} lg={11}>
                                        <Typography variant="h5" color="secondary">
                                            {t("proposals.companyAverage")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <LightTooltip title={t("miscellaneous.help")} aria-label="comment-tooltip">
                                            <IconButton aria-label="comment" onClick={showMainTutorial}>
                                                <HelpIcon fontSize="large" sx={{ color: "#2f3190" }} />
                                            </IconButton>
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12} lg={9} sx={{ p: "25px 40px !important" }}>
                                        <PrettoSlider
                                            disabled={Object.keys(prospect).length === 0}
                                            marks={
                                                prospect.client_type && prospect.client_type === 1 ? marksBook : marks
                                            }
                                            onChange={handleChange}
                                            step={null}
                                            value={average}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={3} sx={{ p: "30px !important" }}>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            disabled={more100}
                                            error={!monthlyAverage && !more100}
                                        >
                                            <InputLabel htmlFor="outlined-adornment-monthly-avg">
                                                {t("proposals.monthlyAverage")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-monthly-avg"
                                                name="monthlyAverage"
                                                value={monthlyAverage}
                                                inputComponent={NumberFormatCustom}
                                                onChange={(event) => handleChangeMonthlyAverage(event.target.value)}
                                                inputProps={{
                                                    onBlur: () => {
                                                        handleChangeMonthlyAverage(
                                                            monthlyAverage <= 1000000
                                                                ? prospect.client_type === 1
                                                                    ? Math.ceil(monthlyAverage / 10000) * 10000
                                                                    : Math.ceil(monthlyAverage / 50000) * 50000
                                                                : 1000000
                                                        );
                                                        if (prospect.client_type === 2)
                                                            getPricingProposal(
                                                                monthlyAverage <= 1000000
                                                                    ? Math.ceil(monthlyAverage / 50000) * 50000
                                                                    : 1000000,
                                                                prospect.country_name,
                                                                prospect.client_type
                                                            );
                                                    },
                                                }}
                                                startAdornment={
                                                    <InputAdornment disableTypography position="start">
                                                        $
                                                    </InputAdornment>
                                                }
                                                label={t("proposals.monthlyAverage")}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    {prospect.client_type && prospect.client_type === 1 && (
                                        <Grid container item alignItems="center" spacing={2} sx={{ mt: 0.5 }}>
                                            <Grid item xs={3} lg={2}>
                                                <FormControl fullWidth disabled>
                                                    <InputLabel id="tariff-simple-select-label">
                                                        {t("services.baseRate")}
                                                    </InputLabel>
                                                    <Select
                                                        name="tariff"
                                                        labelId="tariff-simple-select-label"
                                                        label={t("services.baseRate")}
                                                        value={tariff}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={0}>$0</MenuItem>
                                                        <MenuItem value={5}>$5</MenuItem>
                                                        <MenuItem value={10}>$10</MenuItem>
                                                        <MenuItem value={15}>$15</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} lg={2}>
                                                <FormControl
                                                    fullWidth
                                                    disabled={Object.keys(prospect).length === 0 || !monthlyAverage}
                                                >
                                                    <InputLabel id="tariffAdditionals-simple-select-label">
                                                        {t("services.additionalsRate")}
                                                    </InputLabel>
                                                    <Select
                                                        name="tariffAdditionals"
                                                        labelId="tariffAdditionals-simple-select-label"
                                                        label={t("services.additionalsRate")}
                                                        value={tariffAdditionals}
                                                        onChange={handleChangeTariffAdd}
                                                        IconComponent={KeyboardArrowDownIcon}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    mt: 0.7,
                                                                    borderRadius: 2,
                                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={15}>$15</MenuItem>
                                                        <MenuItem value={25}>$25</MenuItem>
                                                        <MenuItem value={30}>$30</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} sm={3} lg={3} sx={{ mr: -4 }}>
                                                <LightTooltip
                                                    title={t("proposals.prefTariffTool")}
                                                    aria-label="directory-tooltip"
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={preferentialFee}
                                                                className={classes.switch}
                                                                color="secondary"
                                                                inputProps={{ "aria-label": "controlled" }}
                                                                name="switchPreferentialFee"
                                                                onChange={handleChangePreferentialFee}
                                                            />
                                                        }
                                                        label={
                                                            <Typography align="center" sx={{ lineHeight: 0 }}>
                                                                {t("proposals.prefTariff")}
                                                            </Typography>
                                                        }
                                                    />
                                                </LightTooltip>
                                            </Grid>
                                            {expanded === "bookkeeper" && (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sm={2.5}
                                                        lg={2.5}
                                                        sx={{ mr: -2, whiteSpace: "nowrap" }}
                                                    >
                                                        <LightTooltip
                                                            title={t("proposals.includeToolbox")}
                                                            aria-label="directory-tooltip"
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={includeToolbox}
                                                                        className={classes.switch}
                                                                        color="secondary"
                                                                        inputProps={{ "aria-label": "controlled" }}
                                                                        name="switchIncludeToolbox"
                                                                        onChange={handleChangeSwitchToolbox}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography align="center" sx={{ lineHeight: 0 }}>
                                                                        {t("proposals.includeToolbox")}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </LightTooltip>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} lg={3}>
                                                        <LightTooltip
                                                            title={t("proposals.includeOnboarding2")}
                                                            aria-label="directory-tooltip"
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={includeOnboarding}
                                                                        className={classes.switch}
                                                                        color="secondary"
                                                                        inputProps={{ "aria-label": "controlled" }}
                                                                        name="switchIncludeOnboarding"
                                                                        onChange={handleChangeSwitchOnboarding}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography align="center" sx={{ lineHeight: 0 }}>
                                                                        {t("proposals.includeOnboarding")}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </LightTooltip>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid id="services-tutorial" item xs={12}>
                                        <Typography variant="h5" color="secondary" sx={{ pt: 3, pb: 2 }}>
                                            {t("proposals.selectServices")}
                                        </Typography>
                                        {services.map((value) => (
                                            <Accordion
                                                expanded={expanded === value.name}
                                                onChange={handleChangeAccordion(value.name)}
                                                sx={{
                                                    borderBottom: value.isActive
                                                        ? "3px solid #ff5968"
                                                        : "3px solid #cccccc",
                                                }}
                                            >
                                                <AccordionSummary
                                                    aria-controls="panel-content"
                                                    disabled={
                                                        Object.keys(prospect).length === 0 ||
                                                        (!monthlyAverage &&
                                                            value.name !== "softSetup" &&
                                                            value.name !== "softSubs") ||
                                                        (value.name === "accountant" && prospect.client_type === 1) ||
                                                        (value.name === "bookkeeper" && prospect.client_type === 2) ||
                                                        (value.name === "controller" && prospect.client_type === 1) ||
                                                        (value.name === "cfo" && prospect.client_type === 1) ||
                                                        (value.name === "taxExpert" && prospect.client_type === 1) ||
                                                        (value.name === "review" && prospect.client_type === 1) ||
                                                        (value.name === "compilation" && prospect.client_type === 1)
                                                    }
                                                    id="panel-header"
                                                >
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                    >
                                                        <Grid item>
                                                            <Typography
                                                                variant="h1"
                                                                align="center"
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    fontSize: 20,
                                                                }}
                                                            >
                                                                {t(`invoicing.${value.name}`)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {value.name === "accountant" && (
                                                        <PropAccountantDetails
                                                            services={services}
                                                            setServices={setServices}
                                                            valuesAccRecu={valuesAccRecu}
                                                            setValuesAccRecu={setValuesAccRecu}
                                                            valuesAccOnboard={valuesAccOnboard}
                                                            setValuesAccOnboard={setValuesAccOnboard}
                                                            valuesAccCleanUp={valuesAccCleanUp}
                                                            setValuesAccCleanUp={setValuesAccCleanUp}
                                                            valuesAccCatchUp={valuesAccCatchUp}
                                                            setValuesAccCatchUp={setValuesAccCatchUp}
                                                            valuesAccConsul={valuesAccConsul}
                                                            setValuesAccConsul={setValuesAccConsul}
                                                            clientMarket={prospect.country_name}
                                                            pricingModel={pricingModel}
                                                            isError={isError}
                                                            isErrorFocus={isErrorFocus}
                                                            setIsErrorFocus={setIsErrorFocus}
                                                            setShow={setShow}
                                                            setTexto={setTexto}
                                                            setAutoAlertType={setAutoAlertType}
                                                            showtutorial={showtutorial}
                                                            showFinalTutorial={showFinalTutorial}
                                                        />
                                                    )}
                                                    {value.name === "bookkeeper" && (
                                                        <PropBookkeeperDetails
                                                            services={services}
                                                            setServices={setServices}
                                                            valuesBookRecu={valuesBookRecu}
                                                            setValuesBookRecu={setValuesBookRecu}
                                                            valuesBookOnboard={valuesBookOnboard}
                                                            setValuesBookOnboard={setValuesBookOnboard}
                                                            valuesBookCleanUp={valuesBookCleanUp}
                                                            setValuesBookCleanUp={setValuesBookCleanUp}
                                                            valuesBookCatchUp={valuesBookCatchUp}
                                                            setValuesBookCatchUp={setValuesBookCatchUp}
                                                            valuesBookConsul={valuesBookConsul}
                                                            setValuesBookConsul={setValuesBookConsul}
                                                            monthlyAverage={monthlyAverage}
                                                            tariff={tariff}
                                                            tariffAdditionals={tariffAdditionals}
                                                            isError={isError}
                                                            isErrorFocus={isErrorFocus}
                                                            setIsErrorFocus={setIsErrorFocus}
                                                            setShow={setShow}
                                                            setTexto={setTexto}
                                                            setAutoAlertType={setAutoAlertType}
                                                            showtutorial={showtutorial}
                                                            showFinalTutorial={showFinalTutorial}
                                                        />
                                                    )}
                                                    {value.name === "controller" && (
                                                        <PropControllerDetails
                                                            services={services}
                                                            setServices={setServices}
                                                            valuesContRecu={valuesContRecu}
                                                            setValuesContRecu={setValuesContRecu}
                                                            valuesContOnboard={valuesContOnboard}
                                                            setValuesContOnboard={setValuesContOnboard}
                                                            valuesContCatchUp={valuesContCatchUp}
                                                            setValuesContCatchUp={setValuesContCatchUp}
                                                            valuesContConsul={valuesContConsul}
                                                            setValuesContConsul={setValuesContConsul}
                                                            monthlyAverage={monthlyAverage}
                                                            isError={isError}
                                                            isErrorFocus={isErrorFocus}
                                                            setIsErrorFocus={setIsErrorFocus}
                                                            setShow={setShow}
                                                            setTexto={setTexto}
                                                            setAutoAlertType={setAutoAlertType}
                                                            pricingModel={pricingModel}
                                                            clientMarket={prospect.country_name}
                                                            showtutorial={showtutorial}
                                                            showFinalTutorial={showFinalTutorial}
                                                        />
                                                    )}
                                                    {value.name === "cfo" && (
                                                        <PropCFODetails
                                                            services={services}
                                                            setServices={setServices}
                                                            valuesCFOOnboard={valuesCFOOnboard}
                                                            setValuesCFOOnboard={setValuesCFOOnboard}
                                                            valuesCFOCatchUp={valuesCFOCatchUp}
                                                            setValuesCFOCatchUp={setValuesCFOCatchUp}
                                                            valuesCFORecu={valuesCFORecu}
                                                            setValuesCFORecu={setValuesCFORecu}
                                                            valuesCFOConsul={valuesCFOConsul}
                                                            setValuesCFOConsul={setValuesCFOConsul}
                                                            monthlyAverage={monthlyAverage}
                                                            isError={isError}
                                                            isErrorFocus={isErrorFocus}
                                                            setIsErrorFocus={setIsErrorFocus}
                                                            setShow={setShow}
                                                            setTexto={setTexto}
                                                            setAutoAlertType={setAutoAlertType}
                                                            pricingModel={pricingModel}
                                                            clientMarket={prospect.country_name}
                                                            showtutorial={showtutorial}
                                                            showFinalTutorial={showFinalTutorial}
                                                        />
                                                    )}
                                                    {value.name === "softSetup" && (
                                                        <PropSetupDetails
                                                            services={services}
                                                            setServices={setServices}
                                                            valuesSetupXero={valuesSetupXero}
                                                            setValuesSetupXero={setValuesSetupXero}
                                                            valuesSetupBanking={valuesSetupBanking}
                                                            setValuesSetupBanking={setValuesSetupBanking}
                                                            valuesSetupTaxes={valuesSetupTaxes}
                                                            setValuesSetupTaxes={setValuesSetupTaxes}
                                                            valuesSetupReport={valuesSetupReport}
                                                            setValuesSetupReport={setValuesSetupReport}
                                                            valuesSetupOther={valuesSetupOther}
                                                            setValuesSetupOther={setValuesSetupOther}
                                                            valuesSetupCon={valuesSetupCon}
                                                            setValuesSetupCon={setValuesSetupCon}
                                                            isError={isError}
                                                            isErrorFocus={isErrorFocus}
                                                            setIsErrorFocus={setIsErrorFocus}
                                                            clientMarket={prospect.country_name}
                                                            showtutorial={showtutorial}
                                                            showFinalTutorial={showFinalTutorial}
                                                            includeAccModuleSet={includeAccModuleSet}
                                                            handleChangeCheckAccSet={handleChangeCheckAccSet}
                                                            includeAdmModuleSet={includeAdmModuleSet}
                                                            handleChangeCheckAdmSet={handleChangeCheckAdmSet}
                                                            accountantOnboarding={valuesAccOnboard.chip}
                                                        />
                                                    )}
                                                    {value.name === "softSubs" && (
                                                        <PropSubsDetails
                                                            services={services}
                                                            setServices={setServices}
                                                            valuesSubsXero={valuesSubsXero}
                                                            setValuesSubsXero={setValuesSubsXero}
                                                            valuesSubsBanking={valuesSubsBanking}
                                                            setValuesSubsBanking={setValuesSubsBanking}
                                                            valuesSubsTaxes={valuesSubsTaxes}
                                                            setValuesSubsTaxes={setValuesSubsTaxes}
                                                            valuesSubsReport={valuesSubsReport}
                                                            setValuesSubsReport={setValuesSubsReport}
                                                            valuesSubsCustomer={valuesSubsCustomer}
                                                            setValuesSubsCustomer={setValuesSubsCustomer}
                                                            isError={isError}
                                                            isErrorFocus={isErrorFocus}
                                                            setIsErrorFocus={setIsErrorFocus}
                                                            setShow={setShow}
                                                            setTexto={setTexto}
                                                            setAutoAlertType={setAutoAlertType}
                                                            clientMarket={prospect.country_name}
                                                            showtutorial={showtutorial}
                                                            showFinalTutorial={showFinalTutorial}
                                                            includeAccModuleSub={includeAccModuleSub}
                                                            handleChangeCheckAccSub={handleChangeCheckAccSub}
                                                            includeAdmModuleSub={includeAdmModuleSub}
                                                            handleChangeCheckAdmSub={handleChangeCheckAdmSub}
                                                            accountantOnboarding={valuesAccOnboard.chip}
                                                        />
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card
                        elevation={0}
                        sx={{
                            backgroundColor: "#F8F9F8",
                            borderRadius: 8,
                            boxShadow: "10px 10px 15px rgba(3,24,81,0.15)",
                            height: "100% !important",
                        }}
                    >
                        <CardHeader
                            title={t("proposals.proposalSummary")}
                            sx={{
                                p: "30px 30px 0px !important",
                                color: "#ff5968",
                            }}
                        />
                        <CardContent classes={{ root: classes.cardContentRoot }}>
                            {isPreview ? (
                                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Grid item xs={12} md={10}>
                                        <Typography>
                                            {prospect.prospect_name +
                                                " " +
                                                prospect.prospect_last_name +
                                                " - " +
                                                prospect.company_legal_name}
                                        </Typography>
                                        <Typography>{prospect.prospect_email}</Typography>
                                    </Grid>
                                    {proposalID && (
                                        <Grid item>
                                            <LightTooltip
                                                title={t("miscellaneous.comments")}
                                                aria-label="comment-tooltip"
                                            >
                                                <IconButton aria-label="comment" onClick={openComments}>
                                                    <Badge badgeContent={commentsNum} color="secondary">
                                                        <CommentIcon sx={{ color: "#2f3190" }} />
                                                    </Badge>
                                                </IconButton>
                                            </LightTooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                <Grid id="prospects-tutorial" container alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={10}>
                                        <Autocomplete
                                            options={prospectsData}
                                            value={Object.keys(prospect).length !== 0 ? prospect : ""}
                                            onChange={(event, value, reason) =>
                                                handleChangeProspect(event, value, reason)
                                            }
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some(
                                                    (option) => inputValue === option.title
                                                );
                                                if (inputValue !== "" && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        company_legal_name: t("proposals.addNewProspect"),
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === "string") {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.inputValue) {
                                                    return option.company_legal_name;
                                                }
                                                // Regular option
                                                return `${option.company_legal_name} - ${option.prospect_email}`;
                                            }}
                                            renderOption={(props, option) => {
                                                if (option.inputValue) {
                                                    return <li {...props}>{option.company_legal_name}</li>;
                                                }

                                                return (
                                                    <li
                                                        {...props}
                                                    >{`${option.company_legal_name} - ${option.prospect_email}`}</li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label={t("proposals.emailOrCompany")} />
                                            )}
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    sx={{
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <LightTooltip
                                            title={t("proposals.viewProspDirectory")}
                                            aria-label="directory-tooltip"
                                        >
                                            <IconButton
                                                aria-controls="prospects-directory"
                                                aria-haspopup="true"
                                                onClick={() => setOpen(true)}
                                                sx={{ display: "block", m: "auto" }}
                                            >
                                                <AssignmentIndIcon
                                                    sx={{
                                                        display: "block",
                                                        height: 40,
                                                        width: 40,
                                                        margin: "auto",
                                                        color: "#2f3190",
                                                    }}
                                                />
                                            </IconButton>
                                        </LightTooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disabled={Object.keys(prospect).length === 0}
                                            disableElevation
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<AddCircleIcon />}
                                            onClick={handleOpenMails}
                                            sx={{
                                                pl: 3,
                                                pr: 3,
                                                border: "none",
                                                borderRadius: 40,
                                                "&:hover": {
                                                    border: "none",
                                                    background: "transparent",
                                                    color: "#2f3190",
                                                },
                                            }}
                                        >
                                            Agregar correos
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={2} sx={{ pt: 4 }}>
                                <Grid id="kart-tutorial" item xs={12}>
                                    <Typography variant="h5" color="secondary">
                                        {t("proposals.selectedServices")}
                                    </Typography>
                                    {(valuesAccRecu.chip ||
                                        valuesAccOnboard.chip ||
                                        valuesAccCatchUp.chip ||
                                        valuesAccCleanUp.chip ||
                                        valuesAccConsul.chip) && (
                                        <AccountantSummary
                                            valuesAccRecu={valuesAccRecu}
                                            valuesAccOnboard={valuesAccOnboard}
                                            valuesAccCleanUp={valuesAccCleanUp}
                                            valuesAccCatchUp={valuesAccCatchUp}
                                            valuesAccConsul={valuesAccConsul}
                                        />
                                    )}
                                    {(valuesBookRecu.chip ||
                                        valuesBookOnboard.chip ||
                                        valuesBookCatchUp.chip ||
                                        valuesBookCleanUp.chip ||
                                        valuesBookConsul.chip) && (
                                        <BookkeeperSummary
                                            tariffAdditionals={tariffAdditionals}
                                            valuesBookRecu={valuesBookRecu}
                                            valuesBookOnboard={valuesBookOnboard}
                                            valuesBookCleanUp={valuesBookCleanUp}
                                            valuesBookCatchUp={valuesBookCatchUp}
                                            valuesBookConsul={valuesBookConsul}
                                        />
                                    )}
                                    {(valuesContRecu.chip ||
                                        valuesContOnboard.chip ||
                                        valuesContCatchUp.chip ||
                                        valuesContConsul.chip) && (
                                        <ControllerSummary
                                            valuesContRecu={valuesContRecu}
                                            valuesContOnboard={valuesContOnboard}
                                            valuesContCatchUp={valuesContCatchUp}
                                            valuesContConsul={valuesContConsul}
                                        />
                                    )}
                                    {(valuesCFORecu.chip ||
                                        valuesCFOOnboard.chip ||
                                        valuesCFOCatchUp.chip ||
                                        valuesCFOConsul.chip) && (
                                        <CFOSummary
                                            valuesCFORecu={valuesCFORecu}
                                            valuesCFOOnboard={valuesCFOOnboard}
                                            valuesCFOCatchUp={valuesCFOCatchUp}
                                            valuesCFOConsul={valuesCFOConsul}
                                        />
                                    )}
                                    {(valuesSetupXero.chip ||
                                        valuesSetupBanking.chip ||
                                        valuesSetupReport.chip ||
                                        valuesSetupTaxes.chip ||
                                        valuesSetupOther.chip ||
                                        valuesSetupCon.chip) && (
                                        <SetupSummary
                                            valuesSetupXero={valuesSetupXero}
                                            valuesSetupBanking={valuesSetupBanking}
                                            valuesSetupReport={valuesSetupReport}
                                            valuesSetupTaxes={valuesSetupTaxes}
                                            valuesSetupOther={valuesSetupOther}
                                            valuesSetupCon={valuesSetupCon}
                                        />
                                    )}
                                    {(valuesSubsXero.chip ||
                                        valuesSubsBanking.chip ||
                                        valuesSubsReport.chip ||
                                        valuesSubsTaxes.chip ||
                                        valuesSubsCustomer.chip) && (
                                        <SubsSummary
                                            valuesSubsXero={valuesSubsXero}
                                            valuesSubsBanking={valuesSubsBanking}
                                            valuesSubsReport={valuesSubsReport}
                                            valuesSubsTaxes={valuesSubsTaxes}
                                            valuesSubsCustomer={valuesSubsCustomer}
                                        />
                                    )}
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            p: 3,
                                            mt: 3,
                                            borderRadius: "10px",
                                            border: "2px solid #031851",
                                        }}
                                    >
                                        <Typography variant="h5">{t("proposals.total")}:</Typography>
                                        <Typography variant="h1" sx={{ fontWeight: 400, pt: 2 }}>
                                            {`$${parseFloat(
                                                accountantTotalMonth +
                                                    bookkeeperTotalMonth +
                                                    controllerTotalMonth +
                                                    cfoTotalMonth +
                                                    subscripTotalMonth
                                            ).toLocaleString(todos.amountFormat, {
                                                maximumFractionDigits: 2,
                                            })}/${t(`proposals.month`)}`}
                                        </Typography>
                                        <Typography variant="h1" sx={{ fontWeight: 400, pt: 2 }}>
                                            {`$${parseFloat(
                                                accountantTotal +
                                                    bookkeeperTotal +
                                                    controllerTotal +
                                                    cfoTotal +
                                                    setUpTotal
                                            ).toLocaleString(todos.amountFormat, {
                                                maximumFractionDigits: 2,
                                            })}/${t(`proposals.oneTime`)}`}
                                        </Typography>
                                    </Paper>
                                    {(valuesAccRecu.recuChecks ||
                                        valuesBookRecu.recuChecks ||
                                        valuesContRecu.recuChecks ||
                                        valuesCFORecu.recuChecks) && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                pt: 2,
                                                pb: 1,
                                                textAlign: "justify",
                                            }}
                                        >
                                            {t("proposals.hoursAssociated")}
                                        </Typography>
                                    )}
                                    {(valuesAccRecu.payAnnually ||
                                        valuesContRecu.payAnnually ||
                                        valuesCFORecu.payAnnually) && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                pt: 1,
                                                pb: 1,
                                                textAlign: "justify",
                                            }}
                                        >
                                            {t("proposals.pricesDisplayed")}
                                        </Typography>
                                    )}
                                    {isPreview ? (
                                        proposalDetails[0].status > 1 && !previewEdit ? (
                                            <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ pt: 6 }}
                                                spacing={2}
                                            >
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        disableElevation
                                                        sx={{
                                                            pl: 3,
                                                            pr: 3,
                                                            borderRadius: 40,
                                                            "&:hover": {
                                                                border: "1px solid #2f3190",
                                                                color: "#2f3190",
                                                            },
                                                        }}
                                                        onClick={() => navigate("/proposals/manageProposals")}
                                                    >
                                                        {t("miscellaneous.goBack")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        disableElevation
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={duplicate}
                                                        sx={{
                                                            pl: 3,
                                                            pr: 3,
                                                            borderRadius: 40,
                                                            "&:hover": {
                                                                backgroundColor: "#2f3190",
                                                                color: "#fff",
                                                            },
                                                            "&.Mui-disabled": {
                                                                color: "#fff",
                                                                borderColor: "#ffcdd2",
                                                                background: "#ffcdd2",
                                                            },
                                                        }}
                                                    >
                                                        {t("miscellaneous.copy")}
                                                    </Button>
                                                </Grid>
                                                {proposalDetails[0].status < 3 && (
                                                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                                                        <Button variant="text" onClick={handleEdit}>
                                                            {t("miscellaneous.edit")}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        ) : (
                                            <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ pt: 6 }}
                                                spacing={2}
                                            >
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        disableElevation
                                                        sx={{
                                                            px: 3,
                                                            borderRadius: 40,
                                                            "&:hover": {
                                                                border: "1px solid #2f3190",
                                                                color: "#2f3190",
                                                            },
                                                        }}
                                                        onClick={handleEdit}
                                                    >
                                                        {t("miscellaneous.edit")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <LightTooltip
                                                        disableHoverListener={isModified && proposalStatus !== 1}
                                                        title={
                                                            proposalStatus !== 1 && isModified
                                                                ? t("miscellaneous.noChanges")
                                                                : t("proposals.sendProposal")
                                                        }
                                                        aria-label="comment-tooltip"
                                                    >
                                                        <ButtonGroup
                                                            disableElevation
                                                            variant="outlined"
                                                            ref={anchorRefBtnGroup}
                                                            aria-label="split button"
                                                            color="inherit"
                                                            disabled={!isModified && proposalStatus !== 1}
                                                        >
                                                            <Button
                                                                disableElevation
                                                                variant="contained"
                                                                color="secondary"
                                                                size="medium"
                                                                sx={{
                                                                    mr: 0.5,
                                                                    borderRadius: 40,
                                                                    px: 4,
                                                                    "&:hover": {
                                                                        backgroundColor: "#2f3190",
                                                                        color: "#fff",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#fff",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "#ffcdd2",
                                                                    },
                                                                }}
                                                                onClick={() =>
                                                                    sendProposal(
                                                                        2,
                                                                        true,
                                                                        true,
                                                                        proposalStatus === 1 ? false : true
                                                                    )
                                                                }
                                                            >
                                                                {isEdit && proposalDetails[0].status === 2
                                                                    ? t("miscellaneous.resend")
                                                                    : t("miscellaneous.send")}
                                                            </Button>
                                                            <Button
                                                                size="small"
                                                                aria-controls={
                                                                    openBtnGroupSend ? "split-button-menu" : undefined
                                                                }
                                                                aria-expanded={openBtnGroupSend ? "true" : undefined}
                                                                aria-label="select merge strategy"
                                                                aria-haspopup="menu"
                                                                disableElevation
                                                                variant="contained"
                                                                color="secondary"
                                                                sx={{
                                                                    borderRadius: 40,
                                                                    "&:hover": {
                                                                        backgroundColor: "#2f3190",
                                                                        color: "#fff",
                                                                    },
                                                                    "&.Mui-disabled": {
                                                                        color: "#fff",
                                                                        borderColor: "#ffcdd2",
                                                                        background: "#ffcdd2",
                                                                    },
                                                                }}
                                                                onClick={handleToggleBtnGroup}
                                                            >
                                                                <ExpandMoreIcon sx={{ color: "#FFFFF" }} />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </LightTooltip>
                                                    <Popper
                                                        sx={{
                                                            zIndex: 1,
                                                        }}
                                                        open={openBtnGroupSend}
                                                        anchorEl={anchorRefBtnGroup.current}
                                                        role={undefined}
                                                        transition
                                                        disablePortal
                                                    >
                                                        {({ TransitionProps, placement }) => (
                                                            <Grow
                                                                {...TransitionProps}
                                                                style={{
                                                                    transformOrigin:
                                                                        placement === "bottom"
                                                                            ? "center top"
                                                                            : "center bottom",
                                                                }}
                                                            >
                                                                <Paper>
                                                                    <ClickAwayListener
                                                                        onClickAway={handleCloseBtnGroup}
                                                                    >
                                                                        <MenuList id="split-button-menu" autoFocusItem>
                                                                            {optionsGroupSend.map(
                                                                                (
                                                                                    { id, data, disable, text },
                                                                                    index
                                                                                ) => (
                                                                                    <>
                                                                                        <LightTooltip
                                                                                            title={text}
                                                                                            aria-label="comment-tooltip"
                                                                                        >
                                                                                            <MenuItem
                                                                                                key={id + data}
                                                                                                selected={
                                                                                                    index ===
                                                                                                    selectedIndexBtnGroup
                                                                                                }
                                                                                                onClick={(event) =>
                                                                                                    handleMenuItemClickBtnGroup(
                                                                                                        event,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                                disabled={disable}
                                                                                            >
                                                                                                {data}
                                                                                            </MenuItem>
                                                                                        </LightTooltip>
                                                                                    </>
                                                                                )
                                                                            )}
                                                                        </MenuList>
                                                                    </ClickAwayListener>
                                                                </Paper>
                                                            </Grow>
                                                        )}
                                                    </Popper>
                                                </Grid>
                                                {proposalStatus === 1 || isModified ? ( //Borrador
                                                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                                                        <Button variant="text" onClick={() => sendProposal(1)}>
                                                            {t("proposals.saveDraft")}
                                                        </Button>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                                                        <Button
                                                            variant="text"
                                                            onClick={() => navigate("/proposals/manageProposals")}
                                                        >
                                                            {t("proposals.unsave")}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )
                                    ) : (
                                        <div>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ pt: 6 }}
                                                spacing={2}
                                            >
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        disableElevation
                                                        sx={{
                                                            pl: 3,
                                                            pr: 3,
                                                            borderRadius: 40,
                                                            "&:hover": {
                                                                border: "1px solid #2f3190",
                                                                color: "#2f3190",
                                                            },
                                                        }}
                                                        onClick={startOverProposal}
                                                    >
                                                        {t("proposals.startOver")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        disabled={
                                                            Object.keys(prospect).length === 0 ||
                                                            (!monthlyAverage && !noProjectsSetup && !noProjectsSubs) ||
                                                            (!noProjectsAcc &&
                                                                !noProjectsBook &&
                                                                !noProjectsCont &&
                                                                !noProjectsCFO &&
                                                                !noProjectsSetup &&
                                                                !noProjectsSubs)
                                                        }
                                                        disableElevation
                                                        variant="contained"
                                                        color="secondary"
                                                        sx={{
                                                            pl: 3,
                                                            pr: 3,
                                                            borderRadius: 40,
                                                            "&:hover": {
                                                                backgroundColor: "#2f3190",
                                                                color: "#fff",
                                                            },
                                                            "&.Mui-disabled": {
                                                                color: "#fff",
                                                                borderColor: "#ffcdd2",
                                                                background: "#ffcdd2",
                                                            },
                                                        }}
                                                        onClick={showPreview}
                                                    >
                                                        {t("proposals.preview")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            {isEdit ? (
                                                proposalStatus === 1 || isModified ? ( //Borrador
                                                    <Grid item xs={12} sx={{ textAlign: "center", pt: 2 }}>
                                                        <Button
                                                            disabled={
                                                                Object.keys(prospect).length === 0 ||
                                                                (!monthlyAverage &&
                                                                    !noProjectsSetup &&
                                                                    !noProjectsSubs) ||
                                                                (!noProjectsAcc &&
                                                                    !noProjectsBook &&
                                                                    !noProjectsCont &&
                                                                    !noProjectsCFO &&
                                                                    !noProjectsSetup &&
                                                                    !noProjectsSubs)
                                                            }
                                                            variant="text"
                                                            onClick={() => sendProposal(1)}
                                                        >
                                                            {t("proposals.saveDraft")}
                                                        </Button>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} sx={{ textAlign: "center", pt: 2 }}>
                                                        <Button
                                                            disabled={
                                                                Object.keys(prospect).length === 0 ||
                                                                (!monthlyAverage &&
                                                                    !noProjectsSetup &&
                                                                    !noProjectsSubs) ||
                                                                (!noProjectsAcc &&
                                                                    !noProjectsBook &&
                                                                    !noProjectsCont &&
                                                                    !noProjectsCFO &&
                                                                    !noProjectsSetup &&
                                                                    !noProjectsSubs)
                                                            }
                                                            variant="text"
                                                            onClick={() => navigate("/proposals/manageProposals")}
                                                        >
                                                            {t("proposals.unsave")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            ) : (
                                                <Grid item xs={12} sx={{ textAlign: "center", pt: 2 }}>
                                                    <Button
                                                        disabled={
                                                            Object.keys(prospect).length === 0 ||
                                                            (!monthlyAverage && !noProjectsSetup && !noProjectsSubs) ||
                                                            (!noProjectsAcc &&
                                                                !noProjectsBook &&
                                                                !noProjectsCont &&
                                                                !noProjectsCFO &&
                                                                !noProjectsSetup &&
                                                                !noProjectsSubs)
                                                        }
                                                        variant="text"
                                                        onClick={() => sendProposal(1)}
                                                    >
                                                        {t("proposals.saveDraft")}
                                                    </Button>
                                                </Grid>
                                            )}
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ModAddProspect
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                setBackDrop={setBackDrop}
                setShow={setShow}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
            />
            <ModProspectDirectory
                onClose={() => setOpen(false)}
                open={open}
                prospectsData={prospectsData}
                startOverProposal={startOverProposal}
                setOpenAdd={setOpenAdd}
                setProspectAndAverage={setProspectAndAverage}
            />
            <CommDialProp
                open={dialogComment}
                onClose={closeCommentsDialog}
                proposalID={proposalID}
                setTexto={setTexto}
                setAutoAlertType={setAutoAlertType}
                setBackDrop={setBackDrop}
                setShow={setShow}
                comments={comments}
                getComments={getComments}
                commentType={2}
                loadInitData={loadInitData}
            />
            <Dialog
                open={openAddDialog}
                onClose={closeAddDialog}
                maxWidth="sm"
                title={t("miscellaneous.destProposal")}
                message={bodyMails}
                button={buttonsMails}
            />
            <Backdrop open={showBackdrop} />
            <SnackBar type={alertType} show={show} message={texto} onClose={handleClose} />
        </StyledContainer>
    );
};

export default CreateProposal;
